import { ROW_TYPE } from "../core/enums";
import { Cell, GameHistory, ScoreSheet } from "../core/types";
import { randomPlayerName } from "./playerUtils";

/**
 * Return a filtered player name that is trimmed or generated if empty, and excluding existing names.
 *
 * @param {string} name - The entry name.
 * @param {string[]} excludedNames - An array of names to exclude.
 * @return {string} - The player name.
 */
export const filterPlayerName = (name: string, excludedNames: string[]): string => {
  const trimmedName = name.trim();
  return trimmedName.length > 0 ? trimmedName : randomPlayerName(excludedNames);
};

/**
 * Calculates the progress of the game based on valued cells in score sheet
 *
 * @param {ScoreSheet[]} scoreSheets - An array of score sheets.
 * @return {number} - The progress of score sheetsbetween 0 and 1.
 */
export const computeGameProgress = (scoreSheets: ScoreSheet[]): number => {
  let numberOfScoreCells = 0;
  let numberOfFilledScoreCells = 0;
  for (const scoreSheet of scoreSheets) {
    for (const cell of scoreSheet.cells) {
      if (cell.type === ROW_TYPE.SCORE) {
        numberOfScoreCells++;
        if (cell.value !== undefined) {
          numberOfFilledScoreCells++;
        }
      }
    }
  }
  return numberOfFilledScoreCells / numberOfScoreCells;
};

/**
 * Updates the game history with a new score entry.
 *
 * @param {GameHistory} history - The current game history.
 * @param {number} nbSheets - The number of score sheets.
 * @param {ScoreSheet} scoreSheet - The score sheet.
 * @param {Cell} cellToScore - The cell to score.
 * @param {number} scoreValue - The value of the score.
 * @return {GameHistory} - The updated game history.
 */
export const updateHistory = (
  history: GameHistory,
  nbSheets: number,
  scoreSheet: ScoreSheet,
  cellToScore: Cell,
  scoreValue: number,
): GameHistory => {
  const playerName = nbSheets > 1 ? scoreSheet.playerName : "Vous";
  const rowName = scoreSheet.rows.find((r) => r.key === cellToScore.rowKey)?.longName;
  const colName = scoreSheet.cols.find((c) => c.key === cellToScore.colKey)?.longName;
  const newScore = [
    `${playerName} ${nbSheets > 1 ? "a" : "avez"} inscrit
          ${scoreValue} ${scoreValue > 0 ? "points" : "point"}`,
    `dans ${rowName}`,
  ];
  if (scoreSheet.cols.length > 1) {
    newScore.push(`en ${colName}`);
  }
  return {
    ...history,
    scores: [newScore, ...history.scores],
  };
};
