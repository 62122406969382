import React from "react";

import { InfoBubble } from "../../ui";

import { ImgInfo } from "../../../common/img/common";

import "./actionRow.css";

interface ActionRowProps {
  className?: string;
  img?: JSX.Element;
  title: string;
  infoLines?: string[];
  onClickAction: () => void;
}
const ActionRow = ({ className, img, title, infoLines, onClickAction }: ActionRowProps): JSX.Element => (
  <div className={"actionRow_container" + (className ? ` ${className}` : "") + (infoLines ? " with_info" : "")}>
    {/* left hidden */}
    {infoLines && <div className="actionRow_left_hidden"></div>}
    {/* img - title */}
    <span className="actionRow_title" onClick={() => onClickAction()}>
      {img}
      {title}
    </span>
    {/* img info with bubble info lines */}
    {infoLines && (
      <InfoBubble textLines={infoLines} position="top left">
        <ImgInfo className="actionRow_img_info" />
      </InfoBubble>
    )}
  </div>
);

export default ActionRow;
