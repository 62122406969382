import React from "react";

import "./loader.css";

interface LoaderProps {
  className?: string;
  size?: string;
  color?: string;
}
const Loader = ({ className, size = "20px", color = "#000" }: LoaderProps): JSX.Element => {
  const sizeInt = parseInt(size);
  const loaderRingStyle = {
    width: size,
    height: size,
  };
  const divStyle = {
    width: `${(sizeInt * 4) / 5}px`,
    height: `${(sizeInt * 4) / 5}px`,
    margin: `${sizeInt / 10}px`,
    borderWidth: `${sizeInt / 10}px`,
    borderColor: `${color} transparent transparent transparent`,
  };
  return (
    <div className={`loader_ring ${className ?? ""}`} style={loaderRingStyle}>
      <div style={divStyle}></div>
      <div style={divStyle}></div>
      <div style={divStyle}></div>
      <div style={divStyle}></div>
    </div>
  );
};

export default Loader;
