import { BONUS_KEY, COL_KEY, INDICATOR_KEY, RESULT_KEY, ROW_KEY, ROW_SECTION, ROW_TYPE } from "../core/enums";
import { Cell, Row, Score } from "../core/types";

export const isNum = (val?: string | number): boolean => {
  return (
    val !== null &&
    val !== undefined &&
    ((typeof val === "string" && !isNaN(parseFloat(val))) ||
      (typeof val === "number" && isFinite(val) && `${val}`.indexOf(".") === -1))
  );
};

export const getCell = (cells: Cell[], rowKey: ROW_KEY, colKey: COL_KEY | "ALL_IN_ONE"): Cell | undefined => {
  return cells.find((cell) => cell.rowKey === rowKey && cell.colKey === colKey);
};

export const getPreviousScoreCell = (cells: Cell[], rows: Row[], cell: Cell): Cell | undefined => {
  let previousScoreRow: Score | undefined;
  let rowIndex = rows.findIndex((row) => row.key === cell.rowKey);
  let previousRowIsOfTypeScore = false;
  while (!previousRowIsOfTypeScore && rowIndex > 0) {
    rowIndex--;
    const prevRow = rows[rowIndex];
    if (prevRow.type === ROW_TYPE.SCORE) {
      previousScoreRow = prevRow as Score;
      previousRowIsOfTypeScore = true;
    }
  }
  if (!previousScoreRow) {
    return undefined;
  }
  return cells.find((c) => c.rowKey === previousScoreRow?.key && c.colKey === cell.colKey);
};
export const getNextScoreCell = (cells: Cell[], rows: Row[], cell: Cell): Cell | undefined => {
  let nextScoreRow: Score | undefined;
  let rowIndex = rows.findIndex((row) => row.key === cell.rowKey);
  let nextRowIsOfTypeScore = false;
  while (!nextRowIsOfTypeScore && rowIndex < rows.length - 1) {
    rowIndex++;
    const nextRow = rows[rowIndex];
    if (nextRow.type === ROW_TYPE.SCORE) {
      nextScoreRow = nextRow as Score;
      nextRowIsOfTypeScore = true;
    }
  }
  if (!nextScoreRow) {
    return undefined;
  }
  return cells.find((c) => c.rowKey === nextScoreRow?.key && c.colKey === cell.colKey);
};
export const getRandomEmptyScoreCell = (cells: Cell[], colKey: COL_KEY): Cell | undefined => {
  const emptyCellsOfCol = cells.filter(
    (cell) => cell.colKey === colKey && cell.type === ROW_TYPE.SCORE && cell.value === undefined,
  );
  const max = emptyCellsOfCol.length;
  if (max > 0) {
    const randomIndex = Math.floor(Math.random() * Math.floor(max));
    return emptyCellsOfCol[randomIndex];
  }
  return undefined;
};

export const computeBonusIndicator = (cells: Cell[], colKey: COL_KEY): number => {
  let bonusIndicator = 0;
  const topScoreCells = cells.filter(
    (c) => c.colKey === colKey && c.section === ROW_SECTION.TOP && c.type === ROW_TYPE.SCORE,
  );
  const minNumberOfDiceForBonus =
    cells.find((c) => c.colKey === colKey && c.rowKey === INDICATOR_KEY.BONUS_INDICATOR)?.minNumberOfDiceForBonus ?? 3;
  topScoreCells.forEach((cell) => {
    const { diceValue, value } = cell;
    if (minNumberOfDiceForBonus && value !== undefined && diceValue !== undefined) {
      bonusIndicator += value - minNumberOfDiceForBonus * diceValue;
    }
  });
  return bonusIndicator;
};
export const computeSubTotal = (cells: Cell[], colKey: COL_KEY): number => {
  return cells
    .filter((c) => c.colKey === colKey && c.section === ROW_SECTION.TOP && c.type === ROW_TYPE.SCORE)
    .reduce((acc, cell) => acc + (cell.value ?? 0), 0);
};
export const computeTotal_2 = (cells: Cell[], colKey: COL_KEY): number => {
  return cells
    .filter(
      (c) =>
        c.colKey === colKey &&
        ((c.section === ROW_SECTION.BOTTOM && c.type === ROW_TYPE.SCORE) || c.rowKey === BONUS_KEY.YAMS_BONUS),
    )
    .reduce((acc, cell) => acc + (cell.value ?? 0), 0);
};
export const computeGrandTotal = (cells: Cell[]): number => {
  return cells
    .filter((c) => c.rowKey === RESULT_KEY.TOTAL_1 || c.rowKey === RESULT_KEY.TOTAL_2)
    .reduce((acc, cell) => acc + (cell.value ?? 0), 0);
};
