import { GameBoard, GameMode, GameRule } from "../../game-box/core/types";

export const cancelLastScoreInfoLines = ({ numberOfCancelableScores: n }: GameMode): string[] => [
  `En cas d'erreur de saisie vous pouvez annuler ${
    n === 1 ? "le dernier score inscrit" : `les ${n} derniers scores inscrits`
  }`,
  "afin d'inscrire un autre score dans la case de votre choix.",
];

export const additionalThrowInfoLines = (rule: GameRule): string[] => {
  const { name, numberOfAdditionalThrows, maxNumberOfThrows } = rule;
  return [
    `La règle ${name} vous permet de bénéficier` + ` ${numberOfAdditionalThrows} fois dans toute la partie`,
    "d'un lancer de dés supplémentaire" + ` (en plus des ${maxNumberOfThrows} lancers maximums)`,
    "afin de tenter de vous débloquer d'une mauvaise situation.",
    `À vous de trouver le bon moment pour ${numberOfAdditionalThrows > 1 ? "les " : "l'"}utiliser ;)`,
  ];
};

export const yamsBonusInfoLines = (rule: GameRule): string[] => [
  `Dans la règle ${rule.name}, lorsque vous faites un Yams`,
  "et que la case Yams a déjà été remplie positivement (avec 50 points),",
  "vous marquez 100 points dans la case bonus de Yams.",
  "Ce bonus est cumulable pour chaque nouveau Yams effectué.",
];

export const gameBoardFirstRoundInfoLines = (gameBoard: GameBoard, mustClickOnDiceToPreserve: boolean): string[] => {
  const { throwIndex, maxNumberOfThrows } = gameBoard;
  if (throwIndex === 0) {
    return ["Cliquez sur LANCER", "afin de démarrer la la partie.", "Bonne chance !"];
  }
  if (throwIndex > 0 && throwIndex < maxNumberOfThrows) {
    return [
      "Cliquez sur les dés",
      "que vous souhaitez " + (mustClickOnDiceToPreserve === true ? "conserver" : "relancer"),
      "puis faites LANCER",
      "OU",
      "Cliquez sur une cellule de",
      "votre feuille de scores",
      "pour y marquer des points",
    ];
  }
  if (throwIndex === maxNumberOfThrows) {
    return ["Cliquez sur une cellule de", "votre feuille de scores", "pour y marquer des points"];
  }
  return [];
};
