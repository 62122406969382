import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../configureStore";
import { SETTING_KEY } from "../../common/enums";

const initialState: Record<SETTING_KEY, boolean> = {
  [SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE]: true,
  [SETTING_KEY.SHOW_INDICATOR_ON_DICE]: true,
  [SETTING_KEY.ENABLE_VIBRATION_ON_DICE]: true,
  [SETTING_KEY.ENABLE_VIBRATION_ON_SCORESHEET]: false,
  [SETTING_KEY.SHOW_TIMER]: true,
  [SETTING_KEY.ENABLE_VIBRATION_ON_DICE_THROW_BUTTON]: false,
  [SETTING_KEY.SHOW_PENDING_SCORES]: true,
  [SETTING_KEY.SHOW_BONUS_INDICATOR]: true,
};

type UpdateSettinglPayload = { key: SETTING_KEY; value: boolean };

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    _updateSetting: (state, action: PayloadAction<UpdateSettinglPayload>) => {
      state[action.payload.key] = action.payload.value;
    },
  },
});

export default settingsSlice.reducer;

const { _updateSetting } = settingsSlice.actions;

export const updateSetting = (key: SETTING_KEY, value: boolean): AppThunk => {
  return (dispatch) => {
    dispatch(_updateSetting({ key, value }));
  };
};
