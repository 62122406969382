import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { checkAccountPromptUrl, loadProfile } from "./redux/features/userSlice";
/* debug Mock */
// import { mockGame } from "./redux/features/game/gameSlice";
// import MOCK_gameAlmostOver from "./redux/_mocks/game-almost_over";
import { removeNotification } from "./redux/__actions/ui";

import { isOnMobile } from "./common/utils/appUtils";

import { Notification } from "./components/ui";

// desktop
import PromptActivation from "./views/desktop/account/AccountPopin/PromptActivation";
import PromptResetPassword from "./views/desktop/account/AccountPopin/PromptResetPassword";
import Header from "./views/desktop/header";
import GameView from "./views/desktop/game";
import Footer from "./views/desktop/footer";
// mobile
import Mobile_Home from "./views/mobile/home";
import Mobile_InstallPrompt from "./views/mobile/installPrompt";
import Mobile_UpdatePrompt from "./views/mobile/updatePrompt";
import Mobile_NetworkPrompt from "./views/mobile/networkPrompt";
import PromptAccountActivation from "./views/mobile/account/promptAccountActivation";
import PromptPasswordReset from "./views/mobile/account/promptPasswordReset";

import "./App.css";

const App = (): JSX.Element => {
  const userPrompt = useAppSelector((state) => state.user.prompt);
  const notification = useAppSelector((state) => state.ui.notification);
  const zoomComputedLevel = useAppSelector((state) => state.ui.zoom.computedLevel);

  const dispatch = useAppDispatch();

  const [onMobile, setOnMobile] = useState<boolean>(isOnMobile());

  useEffect(() => {
    // load Profile
    dispatch(loadProfile());

    // Check if user is prompted to activate account or reset password from url pathname (otherwise it resets url)
    dispatch(checkAccountPromptUrl(window.location.pathname));

    /* debug Mock */
    // dispatch(mockGame(MOCK_gameAlmostOver));

    // debug: window resize listener
    if (process.env.NODE_ENV !== "production") {
      window.addEventListener("resize", () => setOnMobile(isOnMobile()), false);
      return () => window.removeEventListener("resize", () => setOnMobile(isOnMobile()), false);
    }
  }, []);

  // if onMobile -> set css .onMobile class on <html> element
  useEffect(() => {
    document.documentElement.classList.toggle("onMobile", onMobile);
  }, [onMobile]);

  return (
    <div id="App">
      {onMobile ? (
        /*** MOBILE **/
        <>
          {/* Is on user account activation ? */}
          <PromptAccountActivation visible={userPrompt.isPromptedToActivateAccount} />
          {/* Is on user password reset ? */}
          <PromptPasswordReset visible={userPrompt.isPromptedToResetPassword} />
          {/* Mobile install prompt */}
          <Mobile_InstallPrompt />
          {/* Mobile update prompt */}
          <Mobile_UpdatePrompt />
          {/* Mobile network prompt */}
          <Mobile_NetworkPrompt />
          {/* Main view */}
          <Mobile_Home />
        </>
      ) : (
        /*** DESKTOP ***/
        <>
          {/* App overlays */}
          <div className="app_overlays" style={{ zoom: zoomComputedLevel }}>
            {/* Is on user account activation ? */}
            {userPrompt.isPromptedToActivateAccount === true && <PromptActivation />}
            {/* Is on user password reset ? */}
            {userPrompt.isPromptedToResetPassword === true && <PromptResetPassword />}
            {/* Global notification */}
            {notification && notification.text && (
              <Notification
                isGlobal={true}
                type={notification.type}
                text={notification.text}
                timeout={notification.timeout}
                onHide={() => dispatch(removeNotification())}
              />
            )}
          </div>
          {/* App views */}
          <Header />
          <GameView />
          <Footer />
        </>
      )}
    </div>
  );
};

export default App;
