import React, { PropsWithChildren, useEffect, useState } from "react";

import { ModalView } from "../../ui";
import { ImgLogo } from "../appLogo/logo";
import { ImgRemove } from "../../../common/img/common";

import "./modalAccountView.css";
import { MyamsPet } from "../../../common/img/myamsPet/myamsPet";

interface ModalAccountViewProps extends PropsWithChildren {
  visible: boolean;
  isUserLoggedIn?: boolean;
  title?: string;
  onClose?: () => void;
}
const ModalAccountView = ({
  visible,
  isUserLoggedIn,
  title,
  onClose,
  children,
}: ModalAccountViewProps): JSX.Element | null => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return !isVisible ? null : (
    <ModalView modalStyle={{ width: "96%" }} canCloseByClickingOutside={false}>
      {/* header */}
      <div className="modalAccountView_header">
        {isUserLoggedIn ? (
          <MyamsPet color="#fff" width={64} height={64} sayHello={true} />
        ) : (
          <ImgLogo width={64} height={64} />
        )}
        <div className="modalAccountView_title">{title ?? "MON COMPTE"}</div>
        <div
          className="modalAccountView_close_button"
          onClick={() => {
            onClose && onClose();
            setIsVisible(false);
          }}
        >
          <ImgRemove />
        </div>
      </div>
      {/* container */}
      <div className="modalAccountView_container">{children}</div>
    </ModalView>
  );
};

export default ModalAccountView;
