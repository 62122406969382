import sha256 from "crypto-js/sha256";
import utf8 from "crypto-js/enc-utf8";
import Base64 from "crypto-js/enc-base64";

import { Game } from "../core/types";
import { buildGameToSaveDto } from "../core/dto/gameToSaveDto";

interface GameToSecure {
  date: string;
  scoreSheetScores: number[];
}

/**
 * Builds a GameToSecure object based on the provided Game object.
 *
 * @param {Game} game - The Game object to build GameToSecure from.
 * @return {GameToSecure} - The generated GameToSecure object.
 */
const buildGameToSecure = (game: Game): GameToSecure => {
  return {
    date: game.date,
    scoreSheetScores: game.scoreSheets[0].cells.map((cell) => cell.value ?? 0),
  };
};

/**
 * Checks if the game tokenis valid by computing game hash token .
 *
 * @param {Game} game - The game object to check the token for.
 * @return {boolean} - Returns true if the game token is valid, otherwise false.
 */
export const checkGameToken = (game: Game): boolean => {
  const hash = generateGameToken(game);
  return game.token === hash;
};

/**
 * Generates a game token hash based on the given game object.
 *
 * @param {Game} game - The game object to generate the token for.
 * @return {string} - The generated game token.
 */
export const generateGameToken = (game: Game): string => {
  const gameToSecure = buildGameToSecure(game);

  // game to string
  const gameToSaveString = JSON.stringify(gameToSecure);

  // compute salt
  const gameTimestamp = new Date(gameToSecure.date).valueOf().toString();
  const salt = gameToSaveString + gameTimestamp;

  // compute hash token
  const hash = sha256(salt).toString();

  return hash;
};

/**
 * Generates a game token based on the provided game to save.
 *
 * @param {Game} game - The game to save to generate the token from.
 * @return {string} - The generated game token.
 */
export const generateGameToSaveToken = (game: Game): string => {
  const gameToSave = buildGameToSaveDto(game);

  // game to string
  const gameToSaveString = JSON.stringify(gameToSave);

  // compute salt
  const gameTimestamp = new Date(gameToSave.date).valueOf().toString();
  const salt = gameToSaveString + gameTimestamp;

  // compute hash token
  const hash = sha256(salt).toString();

  return hash;
};

/**
 * Generates a Base64 encoded string representation of a game to save object.
 *
 * @param {Game} game - The game to save to be encoded.
 * @return {string} - The Base64 encoded string representation of the game to save.
 */
export const generateGame64 = (game: Game): string => {
  const gameToSave = buildGameToSaveDto(game);
  const gameJson = JSON.stringify(gameToSave);
  const wordArray = utf8.parse(gameJson);
  const game64 = Base64.stringify(wordArray);
  return game64;
};
