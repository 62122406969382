import React, { useState, useEffect, useRef } from "react";

import { isNum } from "../../../common/utils/appUtils";
import { isEventTargetInsideElem } from "../../../common/utils/uiUtils";

import { ImgValidate, ImgError, ImgUndo } from "./images";
import "./scoreInput.css";

const numericPad = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "undo", "0", "validate"];
const _canValidateScoreValue = (value: string, maxLength: number) =>
  value === "" || (isNum(value) && (maxLength < 1 || value.length <= maxLength));
const fromInputValueToScoreNumber = (value?: string): number => (!value || value === "" ? 0 : parseInt(value, 10));

interface ScoreInputProps {
  maxLength?: number;
  suggestedScores?: number[];
  error?: string;
  onBlur: () => void;
  onValidateScore: (value: number) => void;
}

const ScoreInput = ({
  maxLength = 2,
  suggestedScores,
  error,
  onBlur,
  onValidateScore,
}: ScoreInputProps): JSX.Element => {
  const [inputValue, setInputValue] = useState<string>("");

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const onClickSomewhere = (e: Event) => {
      if (!isEventTargetInsideElem(e.target, null, "scoreInput_container")) {
        onBlur();
      }
    };
    window.addEventListener("mousedown", onClickSomewhere);
    return () => {
      window.removeEventListener("mousedown", onClickSomewhere);
    };
  }, [onBlur]);
  return (
    <div
      className="scoreInput_container"
      onKeyDown={(e) => {
        // press Enter
        if (e.key === "Enter") {
          onValidateScore(fromInputValueToScoreNumber(inputValue));
        }
        // press Escape
        if (e.key === "Escape" || e.key === "Esc") {
          onBlur();
        }
      }}
    >
      {/* Error panel */}
      {error && error.length > 0 && (
        <div className="scoreInput_errorPanel">
          <ImgError /> {error}
        </div>
      )}

      {/* Score input */}
      <input
        ref={inputRef}
        className={"scoreInput_field" + (error && error.length > 0 ? " error" : "")}
        type="text"
        autoComplete="off"
        autoFocus={true}
        value={inputValue}
        onChange={(e) => {
          const value = e.target.value;
          if (_canValidateScoreValue(value, maxLength)) {
            setInputValue(value);
          }
        }}
      />

      {/* Pad */}
      {suggestedScores && suggestedScores.length > 0 ? (
        /* Scores Pad */
        <div className={"scoreInput_pad scoresPad" + ` numberOfKeys_${suggestedScores.length}`}>
          {suggestedScores.map((score, index) => (
            <span key={index} className="padKey" tabIndex={index} onMouseDown={() => onValidateScore(score)}>
              {score}
            </span>
          ))}
        </div>
      ) : (
        /* Numeric Pad*/
        <div className="scoreInput_pad numPad">
          {numericPad.map((key, i) => (
            <span
              className={
                `padKey padKey_${key}` +
                ((key === "undo" || key === "validate") && inputValue?.length === 0 ? " disabled" : "")
              }
              key={i}
              tabIndex={i}
              onMouseDown={() => {
                // num
                if (isNum(key)) {
                  let newInputValue;
                  // check input focus/selection
                  const input = inputRef.current;
                  if (input && input === document.activeElement) {
                    const input = inputRef.current;
                    const start = input.selectionStart ?? 0;
                    const end = input.selectionEnd ?? 0;
                    newInputValue =
                      input.value.substring(0, start) + key + input.value.substring(end, input.value.length);
                  } else {
                    newInputValue = inputValue + key;
                  }
                  if (_canValidateScoreValue(newInputValue, maxLength)) {
                    setInputValue(newInputValue);
                  }
                }
                // undo
                else if (key === "undo") {
                  setInputValue(inputValue?.substring(0, inputValue.length - 1));
                }
                // validate
                else if (key === "validate") {
                  onValidateScore(fromInputValueToScoreNumber(inputValue));
                }
              }}
            >
              {/* num */}
              {isNum(key) && key}
              {/* undo */}
              {key === "undo" && <ImgUndo />}
              {/* validate */}
              {key === "validate" && <ImgValidate />}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default ScoreInput;
