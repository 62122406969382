export default `
  <div>
    <div style={{ fontSize: "1.1em" }}>
      <center>
        <strong>Le Yahtzee appartient à la marque Hasbro Inc. depuis 1984.</strong>
      </center>
    </div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>Le déroulement</strong>
    </div>
    <br />
    <div>
      A chaque fois que c'est votre tour, vous pouvez lancer au maximum 3 fois les dés, et conserver ou relancer les dés
      que vous souhaitez après chaque lancer.
    </div>
    <div>Vous pouvez ensuite décider d'inscrire un score dans n'importe quelle case disponible.</div>
    <br />
    <div>
      Le score à inscrire dépend de la combinaison effectuée avec les 5 dés. Si aucune case ne vous permet de marquer
      des points selon la combinaison effectuée, vous devez incrire un 0 dans une case disponible.
    </div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>Le bonus</strong>
    </div>
    <br />
    <div>
      Si le total de la partie haute est supérieure ou égale à 63 points, vous marquez un <strong>bonus</strong> de 35
      points.
    </div>
    <div>
      Cette valeur de 63 points correspond à une somme de 3 dés sur 5 possibles pour chaque combinaison de la partie
      haute :
    </div>
    <div>1x3 + 2x3 + 3x3 + 4x3 + 5x3 + 6x3 = 63</div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>Le bonus de Yams</strong>
    </div>
    <br />
    <div>
      Lorsque vous faites un Yams (combinaison de 5 dés similaires) et que la case Yams a déjà été remplie positivement
      (avec 50 points), vous marquez 100 points dans la case <strong>bonus de Yams</strong>. Ce bonus est cumulable pour
      chaque nouveau Yams effectué.
    </div>
    <div>
      Mais si la case Yams a déjà éjé remplie négativement (avec 0 point) vous ne pouvez pas gagner le bonus de Yams.
    </div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>La règle du Joker</strong>
    </div>
    <br />
    <div>
      Lorsque vous faites un Yams (combinaison de 5 dés similaires) et que la case Yams a déjà été remplie positivement
      ou négativement, alors vous devez appliquer <strong>la règle du Joker</strong>.
    </div>
    <br />
    <div>
      Cette règle stipule que vous devez inscrire votre score dans la case de la partie haute correspondant aux dés de
      votre Yams. Par exemple, si vous faites un yams de QUATRE et que la case YAMS est déjà remplie, vous inscrivez 20
      points dans la case "Les QUATRE".
    </div>
    <div>
      Si cette case a déjà été remplie, vous pouvez choisir de remplir positivement n'importe quelle des cases suivantes
      dans la partie basse :
    </div>
    <div>- Le BRELAN avec pour score la somme des 5 dés</div>
    <div>- Le CARRÉ avec pour score la somme des 5 dés</div>
    <div>- Le FULL avec le score de 25</div>
    <div>- La PETITE SUITE avec le score de 30</div>
    <div>- La GRANDE SUITE avec le score de 40</div>
    <div>- La CHANCE avec pour score la somme des 5 dés</div>
    <div>
      Si aucune de ces cases n'est disponible, vous n'avez alors d'autre choix que d'inscrire 0 point dans une des cases
      de la partie haute.
    </div>
  </div>
`;
