import React from "react";

import { ImgLogo } from "./logo";

import "./appLogo.css";
import { FontedNanumPenScript_Lemyams } from "./font";

interface AppLogoProp {
  className?: string;
}
const AppLogo = ({ className }: AppLogoProp): JSX.Element => (
  <div className={`appLogo_container${className ? ` ${className}` : ""}`}>
    <ImgLogo />
    <div className="logo_title_container">
      <FontedNanumPenScript_Lemyams width="32vw" />
    </div>
  </div>
);

export default AppLogo;
