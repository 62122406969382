import React, { useEffect, useState } from "react";
import md5 from "crypto-js/md5";

import { Loader, Separation } from "../../ui";

import { MyamsPet } from "../../../common/img/myamsPet/myamsPet";
import { ImgBrokenImage, ImgEdit, ImgTrashCan } from "../../../common/img/common";

import "./avatar.css";

import Button from "../button/Button";

const DEFAULT_AVATAR_COLORS = [
  // blue
  "#2D85DF",
  "#2672BF",
  "#205F9F",
  "#1A4C80",
  // green
  "#899F20",
  "#6E801A",
  "#526013",
  "#00592D",
  // golden
  "#EECA00",
  "#957E00",
  // brown
  "#BF4A26",
  "#9F3E20",
  "#80321A",
  // orange
  "#BA8131",
  "#CC8D22",
  // red
  "#9F2020",
  "#801A1A",
  "#631414",
  // pink,
  "#9F208D",
  // purple
  "#431A80",
  // sbleurf
  "#A08D1F",
  "#85761A",
];

function stringToColorAndAngle(input: string): { color: string; angle: number } {
  const hash = md5(input).toString();
  const colorIndex = parseInt(hash.slice(0, 6), 16) % DEFAULT_AVATAR_COLORS.length;
  const color = DEFAULT_AVATAR_COLORS[colorIndex];
  const angle = (parseInt(hash.slice(6, 12), 16) % 311) + 25; // > 25 && < (360-25)
  return { color, angle };
}

interface AvatarProps {
  className?: string;
  width?: number;
  height?: number;
  src: string | null;
  pseudo?: string | null;
  editable?: boolean;
  removable?: boolean;
  isLoading?: boolean;
  onClickEdit?: () => void;
  onClickRemove?: () => void;
}
const Avatar = ({
  className,
  width,
  height,
  src,
  pseudo,
  editable = false,
  removable = false,
  isLoading = false,
  onClickEdit,
  onClickRemove,
}: AvatarProps): JSX.Element => {
  const [defaultAvatarColor, setDefaultAvatarColor] = useState<string>();
  const [defaultAvatarAngle, setDefaultAvatarAngle] = useState<number>();
  const [hasImgError, setImgError] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState<boolean>(false);

  const generateDefaultAvatar = () => {
    const { color, angle } = stringToColorAndAngle(pseudo ?? "myams");
    setDefaultAvatarColor(color);
    setDefaultAvatarAngle(angle);
  };

  useEffect(() => {
    if (src === null) {
      generateDefaultAvatar();
    }
  }, [src]);

  if (src && showDeleteConfirm === true && onClickRemove) {
    return (
      // delete avatar
      <div className="avatar_delete_confirm" style={{ minHeight: height }}>
        <div>Supprimer mon avatar ?</div>
        <div className="avatar_delete_confirm_buttons">
          <Button className="avatar_button_cancel" title="Annuler" onClick={() => setShowDeleteConfirm(false)} />
          <Button
            className="avatar_button_yes"
            title="OUI"
            onClick={() => {
              setShowDeleteConfirm(false);
              onClickRemove();
              setImgError(false);
              generateDefaultAvatar();
            }}
          />
        </div>
      </div>
    );
  }
  return (
    <div className={"avatar_container " + (className ? className : "")} style={{ width, height }}>
      {isLoading ? (
        /* Loader */
        <div className="avatar_img_updating_loader">
          <Loader color="#fff" />
        </div>
      ) : (
        <>
          {/* img container */}
          <div className="avatar_img_container">
            {/* default img */}
            {!src && (
              <div
                className="avatar_default_img"
                style={!src ? { backgroundColor: defaultAvatarColor, rotate: `${defaultAvatarAngle}deg` } : undefined}
              >
                <MyamsPet className="avatar_default_img_myams_pet" strokeWidth={8} />
              </div>
            )}
            {/* img */}
            {src && (
              <>
                {hasImgError === true ? (
                  <ImgBrokenImage
                    width={width}
                    height={height}
                    fill="#666"
                    style={{ display: "flex", transform: "scale(.7)", rotate: "-24deg" }}
                  />
                ) : (
                  <img className="avatar_img" alt="avatar" src={src} onError={() => setImgError(true)} />
                )}
              </>
            )}
            {/* edition */}
            {editable === true && (
              <div className="avatar_img_edit" onClick={onClickEdit ? () => onClickEdit() : undefined}>
                <ImgEdit color="#fff" />
              </div>
            )}
          </div>
          {/* remove button */}
          {removable === true && (
            <div
              className={"avatar_remove_button" + (!src ? " disabled" : "")}
              onClick={src && onClickRemove ? () => setShowDeleteConfirm(true) : undefined}
            >
              <Separation orientation="vertical" size="42%" margin="8px" />
              <ImgTrashCan color="#fff" />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Avatar;
