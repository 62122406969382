import React, { useEffect, useRef, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearAuthError, resetUserPrompt, saveNewPassword } from "../../../../redux/features/userSlice";

import { resetUrl } from "../../../../common/utils/appUtils";

import { ModalAccountView, Loader } from "../../../../components/ui";
import { InputField, Button } from "../../../../components/forms";

import "../accountModals.css";
import "./promptPasswordReset.css";

interface PromptPasswordResetProps {
  visible: boolean;
}

const PromptPasswordReset = ({ visible }: PromptPasswordResetProps): JSX.Element => {
  const userCon = useAppSelector((state) => state.user.con);
  const userPrompt = useAppSelector((state) => state.user.prompt);

  const dispatch = useAppDispatch();

  const pseudoRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  // DISABLED:
  //const passwordConfRef = useRef<HTMLInputElement>(null);

  const [isPasswordReseted, setIsPasswordReseted] = useState<boolean>();

  useEffect(() => {
    dispatch(clearAuthError());
  }, [pseudoRef.current?.value, passwordRef.current?.value /* DISABLED, passwordConfRef.current?.value */]);

  return (
    <ModalAccountView
      visible={visible}
      onClose={() => {
        dispatch(resetUserPrompt());
        resetUrl();
      }}
    >
      {/* reset password button */}
      {isPasswordReseted !== true ? (
        <>
          {/* Infos */}
          <div className="account_modal_info_text">
            <div>Bonjour {userPrompt.passwordResetPseudo},</div>
            <div>choisissez votre nouveau mot de passe :</div>
          </div>

          {/* Reset password form */}
          <div className="account_modal_form">
            {/* Pseudo (hidden) */}
            <InputField
              className="account_modal_form_hidden_username_field"
              inputProps={{ autoComplete: "username" }}
              inputValue={userPrompt.passwordResetPseudo}
              disabled={true}
            />
            {/* New password */}
            <InputField
              placeholder="Mot de passe"
              inputRef={passwordRef}
              inputType="password"
              inputProps={{ autoComplete: "new-password" }}
            />
            {/* DISABLED: New password confirmation */}
            {/* <InputField
              placeholder="Confirmer mot de passe"
              inputRef={passwordConfRef}
              inputType="password"
              inputProps={{ autoComplete: "new-password" }}
            /> */}

            {/* auth error */}
            {userCon.authError && <div className="account_modal_auth_error">{userCon.authError}</div>}

            <Button
              className="save_new_password_button"
              img={userPrompt.isResettingPassword ? <Loader color="#fff" /> : undefined}
              disabled={userPrompt.isResettingPassword === true}
              title="Valider mon mot de passe"
              onClick={() => {
                dispatch(
                  saveNewPassword(
                    passwordRef.current?.value,
                    // DISABLED:
                    //passwordConfRef.current?.value,
                    passwordRef.current?.value,
                    userPrompt.passwordResetId ?? undefined,
                    (isReseted) => setIsPasswordReseted(isReseted),
                  ),
                );
              }}
            />
          </div>
        </>
      ) : (
        <div className="save_new_password_success">Votre mot de passe a été mis à jour.</div>
      )}
    </ModalAccountView>
  );
};

export default PromptPasswordReset;
