import myams from "./rules/myams";
import yahtzee from "./rules/yahtzee";
import rapido from "./rules/rapido";
/*debug*/
// import test from "./rules/test";
import { GameRule } from "../core/types";

export const GAME_RULES: GameRule[] = [
  myams,
  yahtzee,
  rapido,
  /*debug*/
  // test,
];
