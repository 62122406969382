import { AnyAction, ThunkAction, configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "reduxjs-toolkit-persist";
import storage from "reduxjs-toolkit-persist/lib/storage"; // defaults to localStorage for web
import autoMergeLevel2 from "reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel2";

import packageInfo from "../../package.json";

import reducers from "./features";
import { RootState } from "./types";

import { migrateState, parseVersion } from "./customMigration";

/** launch custom migration **/
migrateState();

const persistConfig = {
  key: "state",
  version: parseVersion(packageInfo.version),
  storage,
  rootReducer: reducers,
  blacklist: ["gameModes", "gameRules", "ui", "user"],
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stateReconciler: autoMergeLevel2 as any,
  // debug: true,
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
//export type RootState = ReturnType<typeof store.getState>;
export type { RootState } from "./types"; // but nevertheless hardcoded
export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, AnyAction>;
