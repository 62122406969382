/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import {
  UPDATE_USER,
  UPDATE_USER_CON,
  UPDATE_USER_PROMPT,
  UPDATE_USER_UPDT,
  RESET_USER_PROFILE,
  RESET_USER,
} from "../actionTypes";
import { UserService } from "../../../common/api";
import {
  checkRegistrationFields,
  checkLoginFields,
  checkActivationFields,
  checkAvatarField,
  checkUpdateEmailField,
  checkUpdatePasswordFields,
  checkRequestPasswordResetEmailField,
  checkValidateNewPasswordFields,
} from "../../../common/utils/userUtils";
import {
  USER_REGISTRATION,
  USER_ACTIVATION,
  USER_LOGIN,
  USER_PROFILE,
  USER_REQUEST_RESET_PASSWORD,
  USER_PASSWORD_RESET,
} from "../../../common/constants/uiErrorPaths";
import { handleError } from "../../__actions/errors";
import { addUiError, showNotification } from "../../__actions/ui";
import { resetUrl } from "../../../common/utils/appUtils";

const updateUserCon = (con) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_CON,
      payload: con,
    });
  };
};
const updateUserPrompt = (prompt) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROMPT,
      payload: prompt,
    });
  };
};
const updateUserUpdt = (updt) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_UPDT,
      payload: updt,
    });
  };
};

// Check if user has a session cookie
export const hasSessionCookie = (cb) => {
  return () => {
    UserService.hasSessionCookie((error, data) => {
      cb(!error && data);
    });
  };
};

// Create user account
export const register = (pseudo, email, password, passwordConf, cb) => {
  return (dispatch) => {
    // check fields
    const errors = checkRegistrationFields(pseudo, email, password, passwordConf);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb();
    } else {
      dispatch(updateUserCon({ isRegistering: true }));
      // call api
      UserService.register(pseudo, email, password, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("register", error, USER_REGISTRATION));
          // update user con
          dispatch(updateUserCon({ isRegistering: false }));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          dispatch(updateUserCon({ isRegistering: false, isLogged: true }));
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
        }
        cb();
      });
    }
  };
};

// Send again account activation e-mail
export const sendAgainAccountActivationEmail = (email, cb) => {
  return (dispatch) => {
    // check field
    const errors = checkUpdateEmailField(email);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb();
    } else {
      dispatch(updateUserUpdt({ isSendingActivationEmail: true }));
      // call api
      UserService.sendAgainAccountActivationEmail(email, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("sendAgainAccountActivationEmail", error, USER_PROFILE));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
        }
        // update user updt
        dispatch(updateUserUpdt({ isSendingActivationEmail: false }));
        cb();
      });
    }
  };
};

// Update email
export const updateEmail = (email, cb) => {
  return (dispatch) => {
    // check email
    const errors = checkUpdateEmailField(email);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb();
    } else {
      dispatch(updateUserUpdt({ isUpdatingEmail: true }));
      // call api
      UserService.updateEmail(email, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("updateEmail", error, USER_PROFILE));
          // update user updt
          dispatch(updateUserUpdt({ isUpdatingEmail: false }));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          dispatch(updateUserUpdt({ isUpdatingEmail: false }));
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
        }
        cb();
      });
    }
  };
};

// Indicate that the user is prompted to activate account
export const promptToAccountActivation = (activationId) => {
  return (dispatch) => {
    dispatch(
      updateUserPrompt({
        isPromptedToActivateAccount: true,
        activationId,
      }),
    );
  };
};

// Activate user account
export const activateUserAccount = (pseudo, password, activationId) => {
  return (dispatch) => {
    // check fields
    const errors = checkActivationFields(pseudo, password);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
    } else {
      dispatch(updateUserPrompt({ isActivating: true }));
      // call api
      UserService.activateUserAccount(pseudo, password, activationId, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("activateUserAccount", error, USER_ACTIVATION));
          // update user prompt
          dispatch(updateUserPrompt({ isActivating: false }));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          dispatch(
            updateUserPrompt({
              isPromptedToActivateAccount: false,
              activationId: null,
              isActivating: false,
            }),
          );
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
          // reset url
          resetUrl();
        }
      });
    }
  };
};

// Login user then load profile
export const login = (pseudo, password, cb) => {
  return (dispatch) => {
    // check fields
    const errors = checkLoginFields(pseudo, password);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb();
    } else {
      dispatch(updateUserCon({ isLogging: true }));
      // call api
      UserService.login(pseudo, password, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("login", error, USER_LOGIN));
          // update user con
          dispatch(updateUserCon({ isLogging: false }));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          dispatch(updateUserCon({ isLogging: false, isLogged: true }));
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
        }
        cb();
      });
    }
  };
};

// load user infos
export const loadUserProfile = () => {
  return (dispatch) => {
    // call api
    UserService.loadProfile((error, data) => {
      if (error || !data) {
        // handle error
        dispatch(handleError("loadUserProfile", error, null));
        // reset user profile
        dispatch({
          type: RESET_USER_PROFILE,
        });
      } else {
        // update user
        dispatch({
          type: UPDATE_USER,
          payload: data,
        });
      }
      // update user con
      dispatch(updateUserCon({ isLogged: !error }));
    });
  };
};

// Upload avatar
export const uploadAvatar = (avatar, cb) => {
  return (dispatch) => {
    // check fields
    const errors = checkAvatarField(avatar);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb();
    } else {
      dispatch(updateUserUpdt({ isUpdatingAvatar: true }));
      // call api
      UserService.uploadAvatar(avatar, (error, data) => {
        if (error) {
          // handle error
          dispatch(handleError("uploadAvatar", error, USER_PROFILE));
          // upate user updt
          dispatch(updateUserUpdt({ isUpdatingAvatar: false }));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          dispatch(updateUserUpdt({ isUpdatingAvatar: false }));
        }
        cb();
      });
    }
  };
};

// Remove avatar
export const removeAvatar = (cb) => {
  return (dispatch) => {
    dispatch(updateUserUpdt({ isUpdatingAvatar: true }));
    // call api
    UserService.removeAvatar((error, data) => {
      if (error) {
        // handle error
        dispatch(handleError("removeAvatar", error, USER_PROFILE));
        // upate user updt
        dispatch(updateUserUpdt({ isUpdatingAvatar: false }));
      } else {
        // update user
        dispatch({
          type: UPDATE_USER,
          payload: data,
        });
        dispatch(updateUserUpdt({ isUpdatingAvatar: false }));
      }
      cb();
    });
  };
};

// Update password
export const updatePassword = (password, newPassword, newPasswordConf, cb) => {
  return (dispatch) => {
    // check fields
    const errors = checkUpdatePasswordFields(password, newPassword, newPasswordConf);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb(false);
    } else {
      dispatch(updateUserUpdt({ isUpdatingPassword: true }));
      // call api
      UserService.updatePassword(password, newPassword, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("updatePassword", error, USER_PROFILE));
        } else {
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
        }
        // update user updt
        dispatch(updateUserUpdt({ isUpdatingPassword: false }));
        cb(error === null);
      });
    }
  };
};

// Request reset password
export const requestPasswordReset = (email, cb) => {
  return (dispatch) => {
    // check field
    const errors = checkRequestPasswordResetEmailField(email);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
      cb(false);
    } else {
      dispatch(updateUserUpdt({ isRequestingPasswordReset: true }));
      // call api
      UserService.requestPasswordReset(email, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("requestPasswordReset", error, USER_REQUEST_RESET_PASSWORD));
        } else {
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
        }
        // update user updt
        dispatch(updateUserUpdt({ isRequestingPasswordReset: false }));
        cb(error === null);
      });
    }
  };
};

// Indicate that the user is prompted to reset password
export const promptToResetPassword = (passwordResetId, passwordResetPseudo) => {
  return (dispatch) => {
    dispatch(
      updateUserPrompt({
        isPromptedToResetPassword: true,
        passwordResetId,
        passwordResetPseudo,
      }),
    );
  };
};

// Validate new password after request reset password
export const validateNewPassword = (newPassword, newPasswordConf, passwordResetId) => {
  return (dispatch) => {
    // check fields
    const errors = checkValidateNewPasswordFields(newPassword, newPasswordConf);
    // if errors
    if (errors.length > 0) {
      errors.forEach(({ path, text }) => {
        dispatch(addUiError(path, text));
      });
    } else {
      dispatch(updateUserPrompt({ isResettingPassword: true }));
      // call api
      UserService.validateNewPassword(newPassword, passwordResetId, (error, data, notif) => {
        if (error) {
          // handle error
          dispatch(handleError("validateNewPassword", error, USER_PASSWORD_RESET));
          // update user prompt
          dispatch(updateUserPrompt({ isResettingPassword: false }));
        } else {
          // update user
          dispatch({
            type: UPDATE_USER,
            payload: data,
          });
          dispatch(
            updateUserPrompt({
              isPromptedToResetPassword: false,
              passwordResetId: null,
              isResettingPassword: false,
            }),
          );
          // notif if necessary
          notif && dispatch(showNotification(notif.type, notif.message));
          // reset url
          resetUrl();
        }
      });
    }
  };
};

// Logout user
export const logout = (cb) => {
  return (dispatch) => {
    dispatch(updateUserCon({ isLoggingOut: true }));
    // call api
    UserService.logout((error) => {
      if (error) {
        // handle error
        dispatch(handleError("logout", error, null, true));
      }
      // reset user
      dispatch({
        type: RESET_USER,
      });
      cb();
    });
  };
};

// Remove user account
export const removeAccount = () => {
  return (dispatch) => {
    dispatch(updateUserUpdt({ isRemovingAccount: true }));
    // call api
    UserService.removeAccount((error, data, notif) => {
      if (error) {
        // handle error
        dispatch(handleError("removeAccount", error, USER_PROFILE));
        // update user updt
        dispatch(updateUserUpdt({ isRemovingAccount: false }));
      } else {
        // reset user
        dispatch({
          type: RESET_USER,
        });
        // notif if necessary
        notif && dispatch(showNotification(notif.type, notif.message));
      }
    });
  };
};
