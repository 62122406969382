import React, { useState } from "react";
import { Col } from "../../../../game-box/core/types";
import { COL_KEY } from "../../../../game-box/core/enums";

import { InfoBubble } from "../../../../components/ui";

import { isOnMobile } from "../../../../common/utils/appUtils";
import { ImgInfo } from "../../../../common/img/common";

import "./colTitlesRow.css";

interface ColTitlesRowProps {
  cols: Col[];
  canShowColTitlesInfos?: boolean;
}

const ColTitlesRow = ({ cols = [], canShowColTitlesInfos = false }: ColTitlesRowProps): JSX.Element => {
  const [visibleInfoColKey, setVisibleInfoColKey] = useState<COL_KEY>();
  return (
    <tr>
      {cols.map((col, i) => (
        <th key={i} className="col_titles_row_cell">
          <div
            className="cell col_titles_row_cell"
            onMouseEnter={() => {
              if (canShowColTitlesInfos === true) {
                setVisibleInfoColKey(col.key);
              }
            }}
            onMouseLeave={() => setVisibleInfoColKey(undefined)}
          >
            <span className="col_name">{col.shortName}</span>
            {visibleInfoColKey === col.key && !isOnMobile() && (
              <div className="description_img">
                <InfoBubble text={[col.longName, col.description]}>
                  <ImgInfo />
                </InfoBubble>
              </div>
            )}
          </div>
        </th>
      ))}
    </tr>
  );
};

export default ColTitlesRow;
