import React, { ReactElement } from "react";

import { ACTION_ITEM, TUTORIAL_KEY } from "../../../../../common/enums";

import { ImgCancel, ImgDotsMenu, ImgPlusOne } from "../../../../../common/img/common";

export interface Tutorial {
  settingKey: TUTORIAL_KEY;
  getContent: (onConfirm: (tutotialKey: TUTORIAL_KEY) => void) => ReactElement;
  getAnchorElement?: () => Element | null;
}

export const tutorials: Tutorial[] = [
  {
    settingKey: TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE,
    getContent: () => <TutorialContent tutorialKey={TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE} />,
    getAnchorElement: () => document.querySelector(".gameBoard_throw_button"),
  },
  {
    settingKey: TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE,
    getContent: (onConfirm) => (
      <TutorialContent
        tutorialKey={TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE}
        onConfirm={() => onConfirm(TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE)}
      />
    ),
  },
  {
    settingKey: TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET,
    getContent: (onConfirm) => (
      <TutorialContent
        tutorialKey={TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET}
        onConfirm={() => onConfirm(TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET)}
      />
    ),
  },
  {
    settingKey: TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE,
    getContent: (onConfirm) => (
      <TutorialContent
        tutorialKey={TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE}
        onConfirm={() => onConfirm(TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE)}
      />
    ),
    getAnchorElement: () => document.querySelector(`#${ACTION_ITEM.CANCEL_LAST_SCORE}`),
  },
  {
    settingKey: TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW,
    getContent: (onConfirm) => (
      <TutorialContent
        tutorialKey={TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW}
        onConfirm={() => onConfirm(TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW)}
      />
    ),
    getAnchorElement: () => document.querySelector(`#${ACTION_ITEM.UNLOCK_EXTRA_THROW}`),
  },
  {
    settingKey: TUTORIAL_KEY.SHOW_TUTO_6_MENU,
    getContent: (onConfirm) => (
      <TutorialContent
        tutorialKey={TUTORIAL_KEY.SHOW_TUTO_6_MENU}
        onConfirm={() => onConfirm(TUTORIAL_KEY.SHOW_TUTO_6_MENU)}
      />
    ),
    getAnchorElement: () => document.querySelector(`#${ACTION_ITEM.MENU}`),
  },
];

interface TutoOkButtonProps {
  title: string;
  onClick?: () => void;
}
export const TutoOkButton = ({ title, onClick }: TutoOkButtonProps): JSX.Element => (
  <div className="tuto_ok_Button" onClick={onClick}>
    {title}
  </div>
);

interface TutorialContentProps {
  tutorialKey: TUTORIAL_KEY;
  onConfirm?: (tutorialKey: TUTORIAL_KEY) => void;
}
const TutorialContent = ({ tutorialKey, onConfirm }: TutorialContentProps) => {
  switch (tutorialKey) {
    case TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE: {
      return (
        <>
          <div>
            Cliquez sur <b>LANCER</b> pour faire tourner les dés.
          </div>
          <br />
          <div style={{ textAlign: "center" }}>
            <b>Bonne chance !</b>
          </div>
        </>
      );
    }
    case TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE: {
      return (
        <>
          <div>Cliquez sur les dés que vous souhaitez conserver, puis sur LANCER.</div>
          <br />
          <div>Ou bien si la combinaison de dés vous convient,</div>
          <div>cliquez sur une case libre de votre feuille de scores pour y marquer des points.</div>
          <TutoOkButton title="C'EST COMPRIS" onClick={() => onConfirm?.(TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE)} />
        </>
      );
    }
    case TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET: {
      return (
        <>
          <div>Vous avez atteint la limite des 3 lancers.</div>
          <br />
          <div>Cliquez sur une case libre de votre feuille de scores pour y marquer des points.</div>
          <TutoOkButton title="JE M'EN DOUTAIS" onClick={() => onConfirm?.(TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET)} />
        </>
      );
    }
    case TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE: {
      return (
        <>
          <div>
            Si vous vous êtes trompé de case, vous pouvez annuler votre score en cliquant sur{" "}
            <ImgCancel color="#fff" style={{ margin: "0 0 -6px 2px" }} />
          </div>
          <br />
          <div>Mais attention, une fois les dés relancés, le score ne peut plus être annulé !</div>
          <TutoOkButton title="OK MERCI" onClick={() => onConfirm?.(TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE)} />
        </>
      );
    }
    case TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW: {
      return (
        <>
          <div>En plus des 3 lancers maximums,</div>
          <div>
            vous disposez d&apos;un lancer supplémentaire utilisable{" "}
            <u>
              <b>1 seule fois</b>
            </u>{" "}
            durant la partie.
          </div>
          <br />
          <div>
            Vous pouvez le débloquer en cliquant sur{" "}
            <ImgPlusOne color="#fff" style={{ margin: "0 0 -6px 2px", stroke: "#fff" }} />
          </div>
          <br />
          <div>
            Pratique pour vous sortir d&apos;une impasse. À vous de trouver le bon moment pour l&apos;utiliser
            {" ;-)"}
          </div>
          <TutoOkButton title="OK SYMPA" onClick={() => onConfirm?.(TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW)} />
        </>
      );
    }
    case TUTORIAL_KEY.SHOW_TUTO_6_MENU: {
      return (
        <>
          <div>Ah et une dernière chose,</div>
          <br />
          <div>
            Si vous préférez cliquer sur un dé pour le relancer plutôt que pour le conserver, vous pouvez le régler
            depuis le menu :
          </div>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "8px" }}>
            <ImgDotsMenu color="#fff" />
            {"  >     "}
            <b style={{ marginLeft: "8px" }}>Préférences</b>
          </div>
          <br />
          <div>Vous pouvez aussi accéder aux règles du jeu, ou recommencer la partie.</div>
          <br />
          <div>Voilà vous savez tout !</div>
          <br />
          <div>Bon myams {":-)"}</div>
          <TutoOkButton title="SUPER, MERCI" onClick={() => onConfirm?.(TUTORIAL_KEY.SHOW_TUTO_6_MENU)} />
        </>
      );
    }
    default:
      return null;
  }
};
