import { BONUS, COL, SCORE, INDICATOR, RESULT, SEPERATION } from "../../../core/const";
import { GAME_RULE } from "../../../core/enums";
import { GameRule } from "../../../core/types";
import descriptionHTML from "./descriptionHTML";

export default {
  id: GAME_RULE.MYAMS,
  name: "Le myams",
  descriptionHTML: descriptionHTML,
  summary: "3 colonnes, 1 coup sup'",
  numberOfDice: 5, // nombre de dés à lancer au début du tour pour chaque joueur
  numberOfDiceFaces: 6, // nombre de faces des dés
  maxNumberOfThrows: 3, // nombre de lancers maximums par tour pour chaque joueur
  numberOfAdditionalThrows: 1, // nombre de coup(s) supplémentaire(s) par joueur dans la partie
  withYamsBonus: false, // règle avec bonus de yams
  withYamsJoker: false, // règle du joker de yams
  template: {
    cols: [COL.FROM_TOP_TO_BOTTOM, COL.FROM_BOTTOM_TO_TOP, COL.FREE],
    rows: [
      SCORE.ACES,
      SCORE.TWOS,
      SCORE.THREES,
      SCORE.FOURS,
      SCORE.FIVES,
      SCORE.SIXES,
      SEPERATION.SEPARATION,
      INDICATOR.BONUS_INDICATOR,
      SEPERATION.SEPARATION,
      RESULT.SUBTOTAL,
      SEPERATION.SEPARATION,
      BONUS.BONUS,
      SEPERATION.SEPARATION,
      RESULT.TOTAL_1,
      SEPERATION.SEPARATION,
      SCORE.THREE_OF_A_KIND,
      SCORE.FOUR_OF_A_KIND,
      SCORE.FULL_HOUSE,
      SCORE.SMALL_STRAIGHT,
      SCORE.LARGE_STRAIGHT,
      SCORE.YAMS,
      SCORE.LOW_CHANCE,
      SCORE.HIGH_CHANCE,
      SEPERATION.SEPARATION,
      RESULT.TOTAL_2,
      SEPERATION.SEPARATION,
      RESULT.TOTAL_COL,
      SEPERATION.SEPARATION,
      RESULT.GRAND_TOTAL,
    ],
  },
} as GameRule;
