import React from "react";
import packageInfo from "../../../../../package.json";

import { Button } from "../../../../components/forms";
import { ImgRemove } from "../../../../common/img/common";

import "./informations.css";

interface InformationsProps {
  onClickClose: () => void;
}

const Informations = ({ onClickClose }: InformationsProps): JSX.Element => {
  return (
    <div className="informations_container">
      <Button className="informations_close_button" img={<ImgRemove />} onClick={() => onClickClose()} />

      <div className="info_row">
        <div>
          <div>Le myams est un jeu de yams virtuel</div>
          <div>complexe et stratégique.</div>
        </div>
        <div>
          Il est <b>gratuit</b> et <b>sans publicité</b>.
        </div>
        <div>
          <div>Vous pouvez jouer sans créer de compte.</div>
          <div>Toutefois en créant votre compte vous pourrez concourir pour les Top Scores !</div>
        </div>
      </div>

      <div className="info_separation"></div>

      <div className="info_row">
        <div>
          {/*
            TODO: "Quelle est mon histoire ? "  
              dérouler/enrouler avec scroll
              Le myams blablabla...
          */}
          <div>Si vous avez une question, ou une remarque,</div>
          <div>
            n&apos;hésitez pas à <a href="mailto:contact@myams.me?subject=Message concernant Le myams">me contacter</a>
            {" ;-)"}
          </div>
        </div>
        <div>Bon myams !</div>
      </div>

      <div className="info_footer">
        <div>Version {packageInfo.version}</div>
        <span style={{ color: "#666" }}>|</span>
        <span>©2023 Le myams</span>
      </div>
    </div>
  );
};

export default Informations;
