import axios from "./axiosConfig";
import { UserProfile } from "../common/types";

//temp debug
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugTimeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class UserService {
  /*** Profile ***/
  async loadProfile(): Promise<UserProfile> {
    // await debugTimeout(800);
    const response = await axios.get("/api/user/profile");
    return response.data;
  }
  async updateAvatar(avatar: File): Promise<UserProfile> {
    // debugTimeout(800);
    const formData = new FormData();
    formData.append("avatar", avatar);
    const response = await axios.post("/api/user/avatar", formData);
    return response.data;
  }
  async removeAvatar(): Promise<UserProfile> {
    // debugTimeout(800);
    const response = await axios.delete("/api/user/avatar");
    return response.data;
  }
}

export default new UserService();
