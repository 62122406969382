import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateTutorial } from "../../../../../redux/features/infoSlice";

import { TUTORIAL_KEY } from "../../../../../common/enums";

import { BouncingPopup } from "../../../../../components/ui";
import { Tutorial, tutorials } from "./GameTutoConfig";

import "./gameTuto.css";
import { MyamsPet } from "../../../../../common/img/myamsPet/myamsPet";

const GameTuto = (): JSX.Element => {
  const { gameBoard, progress } = useAppSelector((state) => state.game);
  const { tutorial } = useAppSelector((state) => state.info);
  const { throwIndex, maxNumberOfThrows, isThrowingDice } = gameBoard || { throwIndex: 999, maxNumberOfThrows: 99 };

  const dispatch = useAppDispatch();

  const [show, setShow] = useState<boolean>();
  const [currentTutorial, setCurrentTutorial] = useState<Tutorial>();

  /* Hide Tutorial then update ui global state (300ms for hide animation time) */
  const hideTutorial = (tutorialKey?: TUTORIAL_KEY) => {
    if (show === true && tutorialKey) {
      setShow(false);
      setTimeout(() => dispatch(updateTutorial(tutorialKey, false)), 300);
    }
  };

  useEffect(() => {
    const tutorialKey = currentTutorial?.settingKey;
    if (tutorialKey && tutorial[tutorialKey] === true) {
      setShow(true);
    }
  }, [currentTutorial]);

  useEffect(() => {
    if (isThrowingDice) {
      hideTutorial(currentTutorial?.settingKey);
      return;
    }
    // Check if there is a tutorial to show
    let tutorialKey: TUTORIAL_KEY | undefined;
    if (progress === 0 && throwIndex === 0) {
      tutorialKey = TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE;
    }
    // only show next tutos if first tuto key was previously shown
    if (tutorial.SHOW_TUTO_1_THROW_DICE === false) {
      if (progress === 0 && throwIndex === 1) {
        tutorialKey = TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE;
      } else if (progress === 0 && throwIndex > 1 && throwIndex === maxNumberOfThrows) {
        tutorialKey = TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET;
      } else if (progress > 0 && throwIndex === 0) {
        if (tutorial[TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE] === true) {
          tutorialKey = TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE;
        } else if (
          tutorial[TUTORIAL_KEY.SHOW_TUTO_6_MENU] === true &&
          tutorial[TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW] === false
        ) {
          tutorialKey = TUTORIAL_KEY.SHOW_TUTO_6_MENU;
        }
      } else if (progress > 0 && throwIndex > 1 && throwIndex === maxNumberOfThrows) {
        tutorialKey = TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW;
      }
    }
    const tutoToShow = tutorials.find((tuto) => tuto.settingKey === tutorialKey);

    // if there is a tutorial to show
    if (tutoToShow && tutorialKey && tutorial[tutorialKey] === true) {
      // hide current if there is
      if (currentTutorial && currentTutorial.settingKey !== tutoToShow.settingKey) {
        hideTutorial(currentTutorial.settingKey);
      }
      // set new current Tutorial
      setCurrentTutorial(tutoToShow);

      // if showing SHOW_TUTO_4_CANCEL_SCORE, considere SHOW_TUTO_3_SCORE_SHEET to not be shown later
      if (
        tutoToShow.settingKey === TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE &&
        tutorial[TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET] === true
      ) {
        dispatch(updateTutorial(TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET, false));
      }
    }
  }, [progress, throwIndex, isThrowingDice, maxNumberOfThrows, tutorial]);

  return (
    <BouncingPopup
      show={show}
      content={
        <>
          <div className="tuto_mayms_pet">
            <MyamsPet color="#fff" width={48} height={48} sayHello={true} />
          </div>

          {currentTutorial?.getContent(hideTutorial)}
        </>
      }
      anchorElement={currentTutorial?.getAnchorElement?.()}
    />
  );
};

export default GameTuto;
