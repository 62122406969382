import React, { useState, useEffect } from "react";
import { GAME_STATUS } from "../../../game-box/core/enums";
import { MAX_NUMBER_Of_PLAYERS } from "../../../game-box/core/const";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { addPlayer, newGame } from "../../../redux/features/game/gameSlice";

import ScoreSheetTemplate from "../../scoreSheets/scoreSheetTemplate";
import ScoreSheet from "../../scoreSheets/scoreSheet";
import GameRuleSelection from "./gameRuleSelection/GameRuleSelection";
import GameTable from "./gameTable";
// import Settings from './settings';
import { CardContainer } from "../../../components/ui";

import {
  ImgAddPlayer,
  ImgRules,
  DiceFive,
  // ImgSettings
} from "../../../common/img/common";

import "./gameView.css";

const GameView = (): JSX.Element => {
  const game = useAppSelector((state) => state.game);
  const zoom = useAppSelector((state) => state.ui.zoom.computedLevel);
  const { status, mode, rule, scoreSheets } = game;

  const dispatch = useAppDispatch();

  const [expandedCardKey, setExpandedCardKey] = useState("game_rules");

  useEffect(() => {
    // init a new game is there is no scoreSheets
    if (scoreSheets.length === 0) {
      dispatch(newGame());
      setExpandedCardKey("game_rules");
    }
  }, []);

  return (
    <div id="gameView" style={{ zoom }}>
      {/* Sheets */}
      <div className="grids_container">
        {/* Score sheet template */}
        {rule && <ScoreSheetTemplate />}
        {/* Score sheets */}
        {scoreSheets && scoreSheets.map((scoreSheet, index) => <ScoreSheet key={index} scoreSheet={scoreSheet} />)}
      </div>

      {/* Add new scoreSheet */}
      {MAX_NUMBER_Of_PLAYERS > scoreSheets.length && status === GAME_STATUS.INITIAL && (
        <div className="addScoreSheet_button" onClick={() => dispatch(addPlayer())}>
          <ImgAddPlayer />
        </div>
      )}

      {/* Game panel */}
      <div className="game_panel">
        {/* Rules */}
        <CardContainer
          img={<ImgRules />}
          title="Règle du jeu"
          subtitle={rule.name}
          expanded={status === GAME_STATUS.INITIAL && expandedCardKey === "game_rules"}
          onExpand={() => setExpandedCardKey("game_rules")}
        >
          <GameRuleSelection />
        </CardContainer>

        {/* Game Table (timer, board, flow) */}
        <CardContainer
          img={<DiceFive />}
          title="Partie"
          subtitle={status !== GAME_STATUS.INITIAL ? mode.name : undefined}
          showSubtitleOnlyWhenNotExpanded={false}
          expandable={false}
          hasPadding={status === GAME_STATUS.INITIAL}
        >
          <GameTable />
        </CardContainer>

        {/* Settings - TODO*/}
        {/* <CardContainer
          img={<ImgSettings />}
          title="Préférences"
          expanded={expandedCardKey === 'game_settings'}
          onExpand={() => setExpandedCardKey('game_settings')}
        >
          <Settings />
        </CardContainer> */}
      </div>

      {/* Paused game overlay view */}
      {status === GAME_STATUS.PAUSED && <div className="paused_game_overlay_view">Partie en pause</div>}
    </div>
  );
};

export default GameView;
