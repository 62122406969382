import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { activateUserAccount, resetUserPrompt } from "../../../../redux/features/userSlice";

import { resetUrl } from "../../../../common/utils/appUtils";
import { Loader, ModalAccountView } from "../../../../components/ui";

import "../accountModals.css";
import "./promptAccountActivation.css";

interface PromptAccountActivationProps {
  visible: boolean;
}

const PromptAccountActivation = ({ visible }: PromptAccountActivationProps): JSX.Element | null => {
  const isActivated = useAppSelector((state) => state.user.isActivated);
  const userCon = useAppSelector((state) => state.user.con);
  const userPrompt = useAppSelector((state) => state.user.prompt);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (visible && userPrompt.activationId) {
      dispatch(activateUserAccount(userPrompt.activationId));
    }
  }, [visible, userPrompt.activationId]);

  return (
    <ModalAccountView
      visible={visible}
      title="ACTIVER MON COMPTE"
      onClose={() => {
        dispatch(resetUserPrompt());
        resetUrl();
      }}
    >
      <div className="account_modal_info_text">
        {userPrompt.isActivating && (
          <div className="account_modal_activation_info">
            <Loader color="#fff" /> Activation...
          </div>
        )}
        {!userPrompt.isActivating && isActivated && <>Votre compte est maintenant activé.</>} {/* auth error */}
        {!isActivated && userCon.authError && <div className="account_modal_auth_error">{userCon.authError}</div>}
      </div>
    </ModalAccountView>
  );
};

export default PromptAccountActivation;
