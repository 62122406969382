import { BONUS, COL, SCORE, INDICATOR, RESULT, SEPERATION } from "../../../core/const";
import { GAME_RULE } from "../../../core/enums";
import { GameRule } from "../../../core/types";
import descriptionHTML from "./descriptionHTML";

export default {
  id: GAME_RULE.RAPIDO,
  name: "Rapido",
  descriptionHTML,
  summary: "2 colonnes, 2 coups sups'",
  numberOfDice: 5, // nombre de dés à lancer au début du tour pour chaque joueur
  numberOfDiceFaces: 6, // nombre de faces des dés
  maxNumberOfThrows: 3, // nombre de lancers maximums par tour pour chaque joueur
  numberOfAdditionalThrows: 2, // nombre de coup(s) supplémentaire(s) par joueur dans la partie
  withYamsBonus: false, // règle avec bonus de yams
  withYamsJoker: false, // règle du joker de yams
  template: {
    cols: [COL.RANDOM, COL.FREE],
    rows: [
      SCORE.TWOS,
      SCORE.FOURS,
      SCORE.SIXES,
      SEPERATION.SEPARATION,
      INDICATOR.BONUS_INDICATOR,
      SEPERATION.SEPARATION,
      RESULT.SUBTOTAL,
      SEPERATION.SEPARATION,
      {
        ...BONUS.BONUS,
        description: [
          "Un bonus de 40 points est attribué si le sous-total",
          "de la partie haute est d'au moins 36 points.",
        ],
        info: "40",
        bonusValue: 40,
        minSubtotalRequired: 36,
      },
      SEPERATION.SEPARATION,
      RESULT.TOTAL_1,
      SEPERATION.SEPARATION,
      SCORE.THREE_OF_A_KIND,
      SCORE.UNDER_ELEVEN,
      SCORE.ONLY_PAIRS,
      SCORE.YAMS,
      SCORE.CHANCE,
      SEPERATION.SEPARATION,
      RESULT.TOTAL_2,
      SEPERATION.SEPARATION,
      RESULT.TOTAL_COL,
      SEPERATION.SEPARATION,
      RESULT.GRAND_TOTAL,
    ],
  },
} as GameRule;
