import {
  AVATAR_ALLOWED_FORMATS,
  AVATAR_MAX_SIZE,
  PSEUDO_MIN_CHARS,
  PSEUDO_MAX_CHARS,
  PSEUDO_REGEX,
  EMAIL_REGEX,
  PASSWORD_MIN_CHARS,
  PASSWORD_MAX_CHARS,
} from "../constants/account";
import {
  USER_REGISTRATION_PSEUDO,
  USER_REGISTRATION_EMAIL,
  USER_REGISTRATION_PASSWORD,
  USER_REGISTRATION_PASSWORD_CONF,
  USER_LOGIN_PSEUDO,
  USER_LOGIN_PASSWORD,
  USER_ACTIVATION_PSEUDO,
  USER_ACTIVATION_PASSWORD,
  USER_PROFILE_AVATAR,
  USER_PROFILE_EMAIL,
  USER_PROFILE_PASSWORD,
  USER_PROFILE_NEW_PASSWORD,
  USER_PROFILE_NEW_PASSWORD_CONF,
  USER_REQUEST_RESET_PASSWORD_EMAIL,
  USER_PASSWORD_RESET_PASSWORD,
  USER_PASSWORD_RESET_PASSWORD_CONF,
} from "../constants/uiErrorPaths";
import { ERRORS } from "../messages/userAccount";
import { UiError } from "../types";

/**
 * Checks if the provided email is in the correct format.
 *
 * @param {string} email - The email to be checked.
 * @return {string|null} Returns the error message if the email is not in the correct format, otherwise returns null.
 */
export const checkEmailError = (email?: string): string | null => {
  if (!email || EMAIL_REGEX.test(email.trim().toLowerCase()) === false) {
    return ERRORS.EMAIL_FORMAT;
  }
  return null;
};

/**
 * Validates the given pseudo value based on the provided conditions.
 *
 * @param {string|undefined} pseudo - The pseudo value to be validated.
 * @param {boolean} forLogin - Flag indicating if the validation is for login.
 * @return {string|null} An error message if validation fails, otherwise null.
 */
export const checkPseudoError = (pseudo?: string, forLogin?: boolean): string | null => {
  if (forLogin !== true) {
    if (!pseudo || pseudo.length < PSEUDO_MIN_CHARS || pseudo.length > PSEUDO_MAX_CHARS) {
      return ERRORS.PSEUDO_LENGTH;
    } else if (PSEUDO_REGEX.test(pseudo) === false) {
      return ERRORS.PSEUDO_FORMAT;
    }
  } else {
    if (!pseudo || pseudo.trim().length === 0) {
      return ERRORS.PSEUDO;
    }
  }
  return null;
};

/**
 * Checks the validity of a password based on certain criteria.
 *
 * @param {string|undefined} password - The password to be checked.
 * @param {boolean} [forLogin] - A flag indicating whether the password is for login.
 * @return {string | null} - An error message if the password is invalid, otherwise null.
 */
export const checkPasswordError = (password?: string, forLogin?: boolean): string | null => {
  if (forLogin !== true) {
    if (!password || password.length < PASSWORD_MIN_CHARS || password.length > PASSWORD_MAX_CHARS) {
      return ERRORS.PASSWORD_LENGTH;
    }
  } else {
    if (!password || password.trim().length === 0) {
      return ERRORS.PASSWORD;
    }
  }
  return null;
};

/**
 * Check if the password confirmation matches the password.
 *
 * @param {string} password - The password to be confirmed.
 * @param {string} passwordConf - The password confirmation to be checked.
 * @return {string | null} - Returns an error message if the password confirmation doesn't match the password, otherwise returns null.
 */
export const checkPasswordConfError = (password?: string, passwordConf?: string): string | null => {
  if (!password || !passwordConf || passwordConf !== password) {
    return ERRORS.PASSWORD_CONFIRMATION;
  }
  return null;
};

/**
 * Checks if the avatar file meets the required format and size.
 *
 * @param {File} avatar - The avatar file to be checked.
 * @return {string | null} Returns an error message if the avatar file does not meet the required format or size, otherwise returns null.
 */
export const checkAvatarError = (avatar: File): string | null => {
  if (!AVATAR_ALLOWED_FORMATS.includes(avatar.type)) {
    return ERRORS.AVATAR_FORMAT;
  } else if (avatar.size > AVATAR_MAX_SIZE) {
    return ERRORS.AVATAR_SIZE;
  }
  return null;
};

/**
 * Validates the registration fields
 * and returns an array of UiError which will empty if there are no errors.
 *
 * @param {string} pseudo - The pseudo entered by the user.
 * @param {string} email - The email entered by the user.
 * @param {string} password - The password entered by the user.
 * @param {string} passwordConf - The password confirmation entered by the user.
 * @return {UiError[]} An array of UiError.
 */
export const checkRegistrationFields = (
  pseudo?: string,
  email?: string,
  password?: string,
  passwordConf?: string,
  checkOnlyPseudo?: boolean,
): UiError[] => {
  const errors = [];
  // pseudo
  const pseudoError = checkPseudoError(pseudo);
  if (pseudoError) {
    errors.push({
      path: USER_REGISTRATION_PSEUDO,
      text: pseudoError,
    });
  }
  if (checkOnlyPseudo !== true) {
    // email
    const emailError = checkEmailError(email);
    if (emailError) {
      errors.push({
        path: USER_REGISTRATION_EMAIL,
        text: emailError,
      });
    }
    // password
    const passwordError = checkPasswordError(password);
    if (passwordError) {
      errors.push({
        path: USER_REGISTRATION_PASSWORD,
        text: passwordError,
      });
    }
    // password confirmation
    const passwordConfError = checkPasswordConfError(password, passwordConf);
    if (passwordConfError) {
      errors.push({
        path: USER_REGISTRATION_PASSWORD_CONF,
        text: passwordConfError,
      });
    }
  }
  return errors;
};

/**
 * Check login fields for errors
 * and return an array of UiError which will empty if there are no errors.
 *
 * @param {string|undefined} pseudo - The pseudo to check.
 * @param {string|undefined} password - The password to check.
 * @return {UiError[]} An array of UiError.
 */
export const checkLoginFields = (pseudo?: string, password?: string): UiError[] => {
  const errors = [];
  // pseudo
  const pseudoError = checkPseudoError(pseudo, true);
  if (pseudoError) {
    errors.push({
      path: USER_LOGIN_PSEUDO,
      text: pseudoError,
    });
  }
  // password
  const passwordError = checkPasswordError(password, true);
  if (passwordError) {
    errors.push({
      path: USER_LOGIN_PASSWORD,
      text: passwordError,
    });
  }
  return errors;
};

/**
 * Checks the activation fields for errors
 * and return an array of UiError which will empty if there are no errors.
 *
 * @param {string} pseudo - The pseudo to check.
 * @param {string} password - The password to check.
 * @param {string} activationId - The activationId to check.
 * @return {UiError[]} An array of UiError.
 */
export const checkActivationFields = (pseudo?: string, password?: string, activationId?: string): UiError[] => {
  const errors = [];
  // activationId
  if (!activationId) {
    errors.push({
      path: "",
      text: ERRORS.ACTIVATION_ID,
    });
  }
  // pseudo
  const pseudoError = checkPseudoError(pseudo, true);
  if (pseudoError) {
    errors.push({
      path: USER_ACTIVATION_PSEUDO,
      text: pseudoError,
    });
  }
  // password
  const passwordError = checkPasswordError(password, true);
  if (passwordError) {
    errors.push({
      path: USER_ACTIVATION_PASSWORD,
      text: passwordError,
    });
  }
  return errors;
};

/**
 * Checks the avatar field for errors
 * and returns an array of UiError which will empty if there are no error.
 *
 * @param {File} avatar - The avatar file to be checked.
 * @return {UiError[]} An array of UiError.
 */
export const checkAvatarField = (avatar: File): UiError[] => {
  const errors = [];
  // avatar format and size
  const avatarError = checkAvatarError(avatar);
  if (avatarError) {
    errors.push({
      path: USER_PROFILE_AVATAR,
      text: avatarError,
    });
  }
  return errors;
};

/**
 * Checks the update email field for errors
 * and returns an array of UiError which will empty if there are no errors.
 *
 * @param {string} email - The email to be checked.
 * @return {UiError[]} An array of UiErrord.
 */
export const checkUpdateEmailField = (email: string): UiError[] => {
  const errors = [];
  const emailError = checkEmailError(email);
  if (emailError) {
    errors.push({
      path: USER_PROFILE_EMAIL,
      text: emailError,
    });
  }
  return errors;
};

/**
 * Checks the validity of the password fields when updating a password
 * and returns an array of UiError which will empty if there are no errors.
 *
 * @param {string} password - The current password.
 * @param {string} newPassword - The new password.
 * @param {string} newPasswordConf - The confirmation of the new password.
 * @return {UiError[]} An array of UiError.
 */
export const checkUpdatePasswordFields = (
  password: string,
  newPassword: string,
  newPasswordConf: string,
): UiError[] => {
  const errors = [];
  // password
  const passwordError = checkPasswordError(password);
  if (passwordError) {
    errors.push({
      path: USER_PROFILE_PASSWORD,
      text: passwordError,
    });
  }
  // new password
  const newPasswordError = checkPasswordError(newPassword);
  if (newPasswordError) {
    errors.push({
      path: USER_PROFILE_NEW_PASSWORD,
      text: newPasswordError,
    });
  } else if (password === newPassword) {
    errors.push({
      path: USER_PROFILE_NEW_PASSWORD,
      text: ERRORS.NEW_PASSWORD_SAME_AS_CURRENT,
    });
  }
  // new password confirmation
  const passwordConfError = checkPasswordConfError(newPassword, newPasswordConf);
  if (passwordConfError) {
    errors.push({
      path: USER_PROFILE_NEW_PASSWORD_CONF,
      text: passwordConfError,
    });
  }
  return errors;
};

/**
 * Checks the validity of the email field of the request reset password form
 * and returns an array of UiError which will empty if there are no errors.
 *
 * @param {string} email - The email to be validated.
 * @return {UiError[]} An array of UiError.
 */
export const checkRequestPasswordResetEmailField = (email?: string): UiError[] => {
  const errors = [];
  const emailError = checkEmailError(email);
  if (emailError) {
    errors.push({
      path: USER_REQUEST_RESET_PASSWORD_EMAIL,
      text: emailError,
    });
  }
  return errors;
};

/**
 * Validates the new password fields
 * and returns an array of UiError which will empty if there are no errors.
 *
 * @param {string} newPassword - The new password to be validated.
 * @param {string} newPasswordConf - The confirmation of the new password.
 * @param {string} passwordResetId - The id of the password reset
 * @return {UiError[]} An array of UiError.
 */
export const checkValidateNewPasswordFields = (
  newPassword?: string,
  newPasswordConf?: string,
  passwordResetId?: string,
): UiError[] => {
  const errors = [];
  // password reset id
  if (!passwordResetId) {
    errors.push({
      path: "",
      text: ERRORS.PASSWORD_RESET_ID,
    });
  }
  // new password
  const newPasswordError = checkPasswordError(newPassword);
  if (newPasswordError) {
    errors.push({
      path: USER_PASSWORD_RESET_PASSWORD,
      text: newPasswordError,
    });
  }
  // new password conf
  const newPasswordConfError = checkPasswordConfError(newPassword, newPasswordConf);
  if (newPasswordConfError) {
    errors.push({
      path: USER_PASSWORD_RESET_PASSWORD_CONF,
      text: newPasswordConfError,
    });
  }
  return errors;
};
