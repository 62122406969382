import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ImgProps extends Record<string, any> {
  className?: string;
}

export const ImgArrowUp = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={`img ${props.className}`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
  >
    <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
  </svg>
);

export const ImgArrowDown = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={`img ${props.className}`}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
  >
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
  </svg>
);
