import React from "react";

import { isOnMobile } from "../../../common/utils/appUtils";

interface SeparationProps {
  style?: Record<string, unknown>;
  orientation?: "horizontal" | "vertical";
  color?: string;
  size?: string;
  borderWidth?: string;
  margin?: string;
}
const Separation = ({
  style,
  orientation: o = "horizontal",
  color = isOnMobile() ? "#222" : "#ccc",
  size = "100%",
  borderWidth = "1px",
  margin,
}: SeparationProps): JSX.Element => (
  <div
    style={{
      display: "flex",
      flexDirection: o === "vertical" ? "column" : "row",
      backgroundColor: color,
      margin: margin ? (o === "vertical" ? `0 ${margin}` : `${margin} 0`) : undefined,
      width: o === "vertical" ? borderWidth : size,
      height: o === "vertical" ? size : borderWidth,
      ...style,
    }}
    className="separation"
  ></div>
);

export default Separation;
