import React from "react";

import { useAppSelector } from "../../../../../redux/hooks";

import "./gameAdditionalThrows.css";

const GameAdditionalThrows = (): JSX.Element => {
  const additionalThrows = useAppSelector((state) => state.game.additionalThrows);

  return (
    <div className="gameAdditionalThrows_container">
      {/* title */}
      <div className="gameAdditionalThrows_title">Coups supplémentaires :</div>
      {/* lines */}
      <div className="gameAdditionalThrows_lines">
        {additionalThrows.map(({ id, playerName, used, max }) => (
          <div key={id} className="gameAdditionalThrows_line">
            {/* player name */}
            <div className="gameAdditionalThrows_player">{playerName}</div>
            {/* uses info */}
            <span className={"gameAdditionalThrows_info" + (used > 0 ? " used" : " unused")}>
              {used > 0 ? (
                <>
                  a utilisé {used} / {max}
                </>
              ) : (
                <>
                  n{"'"}a pas utilisé / {max}
                </>
              )}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default GameAdditionalThrows;
