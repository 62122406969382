export const MAX_NUMBER_Of_PLAYERS = 8;

export const ROLLING_DICE_DELAY = 1000;

export * from "./bonuses";
export * from "./columns";
export * from "./indicators";
export * from "./results";
export * from "./scores";
export * from "./separations";
