import React, { RefObject, useEffect, useRef, useState } from "react";

import { InfoBubble } from "../../../components/ui";

import { ImgInfo } from "../../../common/img/common";

import "./inputField.css";

interface InputFieldProps {
  className?: string;
  label?: string;
  labelClassName?: string;
  showOverlapLabel?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
  inputType?: string;
  customInput?: JSX.Element;
  inputClassName?: string;
  placeholder?: string;
  inputValue?: string | null;
  disabled?: boolean;
  fieldInfos?: string[];
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  error?: string;
  onChangeInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const InputField = ({
  className,
  label,
  labelClassName,
  showOverlapLabel = false,
  inputRef,
  inputType = "text",
  customInput,
  inputClassName,
  placeholder,
  inputValue,
  disabled = false,
  fieldInfos,
  inputProps,
  error,
  onChangeInput,
}: InputFieldProps): JSX.Element => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [isOverlapLabelRight, setIsOverlapLabelRight] = useState<boolean>(false);

  const overlapLabelRef = useRef<HTMLLabelElement>(null);
  const inputFieldRef = inputRef ?? useRef<HTMLInputElement>(null);

  const computeOverlapLabelStyle = () => {
    return {
      left: !isOverlapLabelRight ? 0 : "calc(100% - " + overlapLabelRef.current?.clientWidth + "px - 3px)",
      right: isOverlapLabelRight ? "3px" : "calc(100% - " + overlapLabelRef.current?.clientWidth + "px)",
    };
  };
  useEffect(() => {
    if (showOverlapLabel && (isFocused || inputValue || inputFieldRef?.current?.value)) {
      setIsOverlapLabelRight(true);
    } else {
      setIsOverlapLabelRight(false);
    }
  }, [showOverlapLabel, isFocused, inputFieldRef]);

  return (
    <div className={"inputField_container" + (className ? ` ${className}` : "")}>
      {/* Label */}
      {label && (
        <label
          ref={overlapLabelRef}
          className={
            "inputField_label" +
            (labelClassName ? ` ${labelClassName}` : "") +
            (showOverlapLabel ? " overlap" : "") +
            (isOverlapLabelRight ? " right" : "") +
            (error ? " error" : "")
          }
          style={computeOverlapLabelStyle()}
          onClick={() => inputFieldRef?.current?.focus()}
        >
          {label}
        </label>
      )}
      {/* Input, infoImg, error */}
      <div className={"inputField_input_container" + (error ? " error" : "")}>
        <div className="inputField_input_and_infoImg">
          {customInput !== undefined && customInput !== null ? (
            /* custom input */
            React.cloneElement(customInput, {
              className: customInput.props.className + " inputField_input custom" + (error ? " error" : ""),
            })
          ) : (
            /* input */
            <input
              className={"inputField_input" + (inputClassName ? ` ${inputClassName}` : "") + (error ? " error" : "")}
              ref={inputFieldRef}
              type={inputType}
              placeholder={placeholder}
              defaultValue={inputValue ?? undefined}
              disabled={disabled}
              autoCorrect="off"
              autoCapitalize="off"
              spellCheck="false"
              onFocus={() => setIsFocused(true)}
              onBlur={() => setIsFocused(false)}
              onChange={onChangeInput ? (e: React.ChangeEvent<HTMLInputElement>) => onChangeInput(e) : undefined}
              {...inputProps}
            />
          )}
          {/* imgInfo */}
          {fieldInfos && (
            <InfoBubble textLines={fieldInfos} position="top left">
              <ImgInfo className="inputField_infoImg" />
            </InfoBubble>
          )}
        </div>
        {/* error */}
        {error && <span className="inputField_error">{error}</span>}
      </div>
    </div>
  );
};

export default InputField;
