import React from "react";
import "./button.css";

interface ButtonProps {
  className?: string;
  type?: string; // 'default' or 'link'
  orientation?: string; // 'default' or 'vertical'
  img?: JSX.Element;
  title?: string;
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLDivElement>) => void;
}

const Button = ({
  className,
  type = "default",
  orientation = "default",
  img,
  title,
  disabled = false,
  onClick,
}: ButtonProps): JSX.Element => (
  <div
    className={
      `button_container_${type} orientation_${orientation}` +
      (disabled ? " disabled" : "") +
      (className ? ` ${className}` : "")
    }
    onClick={(e: React.MouseEvent<HTMLDivElement>) => onClick(e)}
  >
    {img && <div className="button_img">{img}</div>}
    {title && <div className="button_title">{title}</div>}
  </div>
);

export default Button;
