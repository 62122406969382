import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ImgProps extends Record<string, any> {
  className?: string;
}

export const DiceOne = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24px"}
    height={props.height || "24px"}
    viewBox="0 0 485 485"
    className={props.className}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M242.5,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S223.201,277.5,242.5,277.5z" />
  </svg>
);

export const DiceTwo = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24px"}
    height={props.height || "24px"}
    viewBox="0 0 485 485"
    className={props.className}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z" />
    <path d="M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z" />
  </svg>
);

export const DiceThree = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24px"}
    height={props.height || "24px"}
    viewBox="0 0 485 485"
    className={props.className}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z" />
    <path d="M242.5,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35c-19.299,0-35,15.701-35,35S223.201,277.5,242.5,277.5z" />
    <path d="M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z" />
  </svg>
);

export const DiceFour = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24px"}
    height={props.height || "24px"}
    viewBox="0 0 485 485"
    className={props.className}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z" />
    <path d="M118.75,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,153.75,118.75,153.75z" />
    <path d="M366.25,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,401.25,366.25,401.25z" />
    <path d="M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z" />
  </svg>
);

export const DiceFive = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24px"}
    height={props.height || "24px"}
    viewBox="0 0 485 485"
    className={props.className}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z" />
    <path d="M118.75,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,153.75,118.75,153.75z" />
    <path d="M242.5,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S223.201,277.5,242.5,277.5z" />
    <path d="M366.25,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,401.25,366.25,401.25z" />
    <path d="M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z" />
  </svg>
);

export const DiceSix = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width={props.width || "24px"}
    height={props.height || "24px"}
    viewBox="0 0 485 485"
    className={props.className}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z" />
    <path d="M118.75,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,153.75,118.75,153.75z" />
    <path d="M118.75,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,277.5,118.75,277.5z" />
    <path d="M366.25,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,401.25,366.25,401.25z" />
    <path d="M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z" />
    <path d="M366.25,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,277.5,366.25,277.5z" />
  </svg>
);
