import React, { useEffect, useState } from "react";
import { GAME_STATUS } from "../../../game-box/core/enums";

import { useAppSelector } from "../../../redux/hooks";

import { Button } from "../../../components/forms";
import { ImgLogo } from "../../../components/ui/appLogo/logo";

import "./installPrompt.css";

const InstallPrompt = (): JSX.Element | null => {
  const gameStatus = useAppSelector((state) => state.game.status);

  const [deferredPrompt, setDeferredPrompt] = useState<BeforeInstallPromptEvent | null>(null);
  const [isInstalling, setIsInstalling] = useState(false);

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", (e) => {
      e.preventDefault(); // Empêche l'affichage du prompt d'installation par défaut
      setDeferredPrompt(e as BeforeInstallPromptEvent);
    });
  }, []);

  return deferredPrompt && gameStatus === GAME_STATUS.OVER ? (
    <div className="installPrompt_container">
      <div className="install_label_and_img_container">
        <ImgLogo style={{ minWidth: "48px" }} width={48} height={48} />
        <div className="install_label_container">
          <div className="install_label_top">{"Installer l'application ?"}</div>
          <div className="install_label_bottom">
            {!isInstalling
              ? "permet de lancer Le myams depuis une icone, jouer en plein écran et bénéficier de toutes les fonctionnalités."
              : "Suivez les instructions d'installation de l'application sur votre appareil, puis lancez Le myams depuis son icone d'application ;)"}
          </div>
        </div>
      </div>

      <div className="install_buttons">
        {!isInstalling ? (
          <>
            <Button
              className="install_button_no"
              title="Non merci"
              type="link"
              onClick={() => setDeferredPrompt(null)}
            />
            <Button
              className="install_button_yes"
              title="AVEC PLAISIR"
              onClick={() => {
                deferredPrompt?.prompt();
                setIsInstalling(true);
              }}
            />
          </>
        ) : (
          <Button className="" title="OK" onClick={() => setDeferredPrompt(null)} />
        )}
      </div>
    </div>
  ) : null;
};

export default InstallPrompt;
