import { isOnMobile } from "../common/utils/appUtils";
import { BONUS_KEY, COL_KEY, GAME_MODE, GAME_STATUS, RESULT_KEY, ROW_TYPE } from "./core/enums";
import { AdditionalThrow, Cell, Game, GameMode, GameRule, ScoreSheet } from "./core/types";
import { GAME_MODES } from "./game-modes";
import { GAME_RULES } from "./game-rules";
import { getCell } from "./utils/cellUtils";
import { generateGameBoard } from "./utils/gameBoardUtils";
import { computeGameProgress, updateHistory } from "./utils/gameUtils";
import { getNextScoreSheetsFromNewScore } from "./utils/scoreSheetUtils";
import { hasYams } from "./utils/scoreUtils";

export * from "./utils/cellUtils";
export * from "./utils/gameBoardUtils";
export * from "./utils/gameUtils";
export * from "./utils/scoreSheetUtils";
export * from "./utils/scoreUtils";

/**
 * Retrieves the game rules.
 *
 * @return {GameRule[]} - An array of game rules.
 */
export const getGameRules = (): GameRule[] => {
  return GAME_RULES.map((rule) => ({
    ...rule,
    numberOfScoreCells: rule.template.rows.filter((r) => r.type === ROW_TYPE.SCORE).length * rule.template.cols.length,
  }));
};

/**
 * Retrieves the game modes.
 *
 * @return {GameMode[]} - An array of game modes.
 */
export const getGameModes = (): GameMode[] => {
  const free = GAME_MODES.find((gm) => gm.key === GAME_MODE.FREE);
  const simulation = GAME_MODES.find((gm) => gm.key === GAME_MODE.SIMULATION);

  if (isOnMobile() && simulation) {
    return [simulation];
  } else if (free && simulation) {
    return [free, simulation];
  }
  return [];
};

/**
 * Initializes an array of AdditionalThrow objects based on the given array
 * of ScoreSheet objects and the number of additional throws.
 *
 * @param {ScoreSheet[]} scoreSheets - The array of ScoreSheet objects.
 * @param {number} numberOfAdditionalThrows - The number of additional throws.
 * @return {AdditionalThrow[]} - An array of AdditionalThrow objects.
 */
export const initAdditionalThrows = (
  scoreSheets: ScoreSheet[],
  numberOfAdditionalThrows: number,
): AdditionalThrow[] => {
  return scoreSheets.map((s) => ({
    id: s.id,
    playerName: s.playerName,
    canUse: true,
    used: 0,
    max: numberOfAdditionalThrows,
  }));
};

/**
 * Updates the game fields with a new user validation score.
 *
 * @param {Game} game - The current game.
 * @param {Cell} cellToScore - The cell to write score.
 * @param {number} scoreValue - The score value.
 * @return {Game} - The updated game.
 */
export const updateGameForNewScore = (game: Game, cellToScore: Cell, scoreValue: number): Game => {
  const { rule, mode, status, scoreSheets, pastScoreSheets, gameBoard, pastGameBoards, history } = game;
  const { numberOfCancelableScores } = mode;

  // determine if there is a yams bonus to score
  let withYamsBonus = false;
  if (gameBoard) {
    const canUseYamsBonus = scoreSheets.find((s) => s.id === cellToScore.scoreSheetId)?.canUseYamsBonus ?? false;
    const diceValues = gameBoard.dice.map((d) => d.value).filter((v) => v !== null) as number[];
    withYamsBonus = canUseYamsBonus && hasYams(diceValues);
  }

  // get the next score sheets according to new written score
  const nextScoreSheets = getNextScoreSheetsFromNewScore(scoreSheets, cellToScore, scoreValue, withYamsBonus);

  const updatedScoreSheet = nextScoreSheets.find((s) => s.id === cellToScore.scoreSheetId);
  if (!updatedScoreSheet) {
    console.error("no scoreSheet found for cell", cellToScore.rowKey, cellToScore.colKey);
    return game;
  }

  // add scoreSheets to pastScoreSheets if allowed (in order to cancel last score)
  const nextPastScoreSheets = numberOfCancelableScores > 0 ? [...pastScoreSheets, scoreSheets] : [];
  if (nextPastScoreSheets && nextPastScoreSheets.length > numberOfCancelableScores) {
    nextPastScoreSheets.shift();
  }

  // generate a new gameBoard
  const nextGameBoard = gameBoard ? generateGameBoard(rule) : undefined;

  // save pastGameBoards in order to cancel last score
  const nextPastGameBoards =
    gameBoard && pastGameBoards && numberOfCancelableScores > 0 ? [...pastGameBoards, gameBoard] : undefined;
  if (nextPastGameBoards && nextPastGameBoards.length > numberOfCancelableScores) {
    nextPastGameBoards.shift();
  }

  // update progress
  const nextProgress = computeGameProgress(nextScoreSheets);

  // check status over
  const nextStatus = nextProgress === 1 ? GAME_STATUS.OVER : status;

  // update history
  const nextHistory = updateHistory(history, scoreSheets.length, updatedScoreSheet, cellToScore, scoreValue);

  return {
    ...game,
    status: nextStatus,
    progress: nextProgress,
    scoreSheets: nextScoreSheets,
    pastScoreSheets: nextPastScoreSheets,
    gameBoard: nextGameBoard,
    pastGameBoards: nextPastGameBoards,
    history: nextHistory,
  };
};

/**
 * Updates the game for score cancellation.
 *
 * @param {Game} game - The game object to update.
 * @return {Game} - The updated game object.
 */
export const updateGameForScoreCancellation = (game: Game): Game => {
  if (game.pastScoreSheets.length === 0) {
    return game;
  }
  const { pastScoreSheets, pastGameBoards, history } = game;

  // restore scoreSheets from pastScoreSheets
  const prevPastScoreSheets = [...pastScoreSheets];
  const prevScoreSheets = prevPastScoreSheets.pop() ?? [];

  // compute progress
  const progress = computeGameProgress(prevScoreSheets);

  // restore gameBoard from pastGameBoard
  const prevPastGameBoards = [...(pastGameBoards ?? [])];
  const prevGameBoard = prevPastGameBoards.pop() ?? undefined;

  // restore history
  const prevScores = [...history.scores];
  prevScores.length > 0 && prevScores.shift();
  const prevHistory = {
    ...history,
    scores: prevScores,
  };

  return {
    ...game,
    status: GAME_STATUS.ONGOING,
    progress,
    scoreSheets: prevScoreSheets,
    pastScoreSheets: prevPastScoreSheets,
    gameBoard: prevGameBoard,
    pastGameBoards: prevPastGameBoards,
    history: prevHistory,
  };
};

/**
 * Updates the game with the yams bonus.
 *
 * @param {Game} game - The current game object.
 * @param {ScoreSheet} currentScoreSheet - The current score sheet from which write the yams bonus.
 * @return {Game} - The updated game object.
 */
export const udateGameWithYamsBonus = (game: Game, currentScoreSheet: ScoreSheet): Game => {
  const { mode, scoreSheets, pastScoreSheets, history } = game;
  const { numberOfCancelableScores } = mode;

  // get the yams bonus cell from scoreSheet
  const yamsBonusCell = getCell(currentScoreSheet.cells, BONUS_KEY.YAMS_BONUS, COL_KEY.FREE);
  if (!yamsBonusCell || yamsBonusCell.bonusValue === undefined) {
    console.error("no yams bonus cell found or value/bonusValue is undefined");
    return game;
  }

  // compute new yamsBonusValue
  const newYamsBonusValue = (yamsBonusCell.value ?? 0) + yamsBonusCell.bonusValue;

  // update scoreSheets with new yamsBonus value and updated results
  const nextScoreSheets = game.scoreSheets.map((scoreSheet) => {
    if (scoreSheet.id === currentScoreSheet.id) {
      return {
        ...scoreSheet,
        cells: scoreSheet.cells.map((cell) => {
          // update yams bonus cell
          if (cell.rowKey === yamsBonusCell.rowKey && cell.colKey === yamsBonusCell.colKey) {
            return { ...cell, value: newYamsBonusValue };
          }
          // update total col
          else if (cell.rowKey === RESULT_KEY.TOTAL_COL && cell.colKey === yamsBonusCell.colKey) {
            return {
              ...cell,
              value:
                (getCell(currentScoreSheet.cells, RESULT_KEY.TOTAL_COL, cell.colKey)?.value ?? 0) +
                (yamsBonusCell.bonusValue ?? 0),
            };
          }
          // update grand total
          else if (cell.rowKey === RESULT_KEY.GRAND_TOTAL) {
            return {
              ...cell,
              value:
                (getCell(currentScoreSheet.cells, RESULT_KEY.GRAND_TOTAL, "ALL_IN_ONE")?.value ?? 0) +
                (yamsBonusCell.bonusValue ?? 0),
            };
          }
          return cell;
        }),
      };
    }
    return scoreSheet;
  });

  // add scoreSheets to pastScoreSheets if allowed (in order to cancel last score)
  const nextPastScoreSheets = numberOfCancelableScores > 0 ? [...pastScoreSheets, scoreSheets] : [];
  if (nextPastScoreSheets && nextPastScoreSheets.length > numberOfCancelableScores) {
    nextPastScoreSheets.shift();
  }
  // update history
  const nextHistory = updateHistory(
    history,
    scoreSheets.length,
    currentScoreSheet,
    yamsBonusCell,
    yamsBonusCell.bonusValue,
  );

  return {
    ...game,
    scoreSheets: nextScoreSheets,
    pastScoreSheets: nextPastScoreSheets,
    history: nextHistory,
  };
};
