import React, { useState } from "react";
import { INDICATOR_KEY, ROW_KEY, ROW_TYPE } from "../../../game-box/core/enums";

import { useAppSelector } from "../../../redux/hooks";

import { AppLogo_OLD, InfoBubble } from "../../../components/ui";

import { SETTING_KEY } from "../../../common/enums";
import { isOnMobile } from "../../../common/utils/appUtils";
import { ImgInfo } from "../../../common/img/common";
import { DiceOne, DiceTwo, DiceThree, DiceFour, DiceFive, DiceSix } from "../../../common/img/dice";

import { buildParentCellClassName, buildCellClassName } from "../scoreSheetsHelper";
import "../scoreSheets.css";
import "./scoreSheetTemplate.css";

const getInfoImg = (img: string) => {
  switch (img) {
    case "img:dice_one":
      return <DiceOne className="img_dice" />;
    case "img:dice_two":
      return <DiceTwo className="img_dice" />;
    case "img:dice_three":
      return <DiceThree className="img_dice" />;
    case "img:dice_four":
      return <DiceFour className="img_dice" />;
    case "img:dice_five":
      return <DiceFive className="img_dice" />;
    case "img:dice_six":
      return <DiceSix className="img_dice" />;
    default:
      return null;
  }
};

const ScoreSheetTemplate = (): JSX.Element => {
  const rule = useAppSelector((state) => state.game.rule);
  const settings = useAppSelector((state) => state.settings);

  const rows = rule
    ? settings[SETTING_KEY.SHOW_BONUS_INDICATOR] === true
      ? rule.template.rows
      : rule.template.rows.filter((row) => row.key !== INDICATOR_KEY.BONUS_INDICATOR)
    : [];

  const [isShowingInfoImg, setShowingInfoImg] = useState<ROW_KEY>();

  return (
    <table className="grid template_grid">
      {isOnMobile() ? (
        <thead className="mobile_template_header">
          <tr>
            <th></th>
          </tr>
        </thead>
      ) : (
        <thead>
          <tr>
            <th>
              <AppLogo_OLD />
            </th>
          </tr>
          <tr className="row_separation">
            <td></td>
          </tr>
        </thead>
      )}
      <tbody>
        {rows.map((row, index) => {
          const { type, key, name, calculation, description, img, value, bonusValue } = row;
          return (
            /* separation */
            type === ROW_TYPE.SEPARATION ? (
              <tr key={index} className="row_separation">
                <td></td>
              </tr>
            ) : (
              /* row */
              <tr key={index}>
                <td className={buildParentCellClassName(type, key)}>
                  <div
                    className={`${buildCellClassName(type, key)}`}
                    onMouseEnter={() => setShowingInfoImg(key)}
                    onMouseLeave={() => setShowingInfoImg(undefined)}
                  >
                    {/* description */}
                    {isShowingInfoImg && isShowingInfoImg === key && (
                      <div className="description_img">
                        <InfoBubble
                          textLines={calculation && description ? [...description, calculation] : undefined}
                          text={!calculation ? description : undefined}
                        >
                          <ImgInfo />
                        </InfoBubble>
                      </div>
                    )}
                    {/* name */}
                    <span className="row_name">{name}</span>
                    {/* info img */}
                    {img && getInfoImg(img)}
                    {/* info score value */}
                    {value && <span className="info_score">{value}</span>}
                    {!value && bonusValue && <span className="info_score">{bonusValue}</span>}
                    {type === ROW_TYPE.SCORE && !img && !value && <span className="info_score">Σ</span>}
                  </div>
                </td>
              </tr>
            )
          );
        })}
      </tbody>
    </table>
  );
};

export default ScoreSheetTemplate;
