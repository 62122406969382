import React, { useState } from "react";
import { RESULT_KEY } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { newGame, startGame } from "../../../../../redux/features/game/gameSlice";

import { getFormattedTimer_minSec } from "../../../../../common/utils/dateUtils";
import { ImgChronometer, ImgPlay, ImgShare } from "../../../../../common/img/common";

import { Button } from "../../../../../components/forms";
import { Loader, ModalView } from "../../../../../components/ui";
import { ImgLogo } from "../../../../../components/ui/appLogo/logo";
import ShareGame from "../../../shareGame/ShareGame";

import "./gameOver.css";

const GameOver = (): JSX.Element => {
  const scoreSheets = useAppSelector((state) => state.game.scoreSheets);
  const timer = useAppSelector((state) => state.game.timer);

  const dispatch = useAppDispatch();

  const [showShareGame, setShowShareGame] = useState<boolean>(false);
  const [isLoadingShareGame, setIsLoadingShareGame] = useState<boolean>(false);

  return (
    <div className="mobile_gameOver_container">
      <div className="gameOver_actions_and_infos">
        {/*** Actions ***/}
        <div className="gameOver_actions">
          {/* Back to Home */}
          <Button
            className="gameOver_backToHome_button"
            img={<ImgLogo />}
            title="Accueil"
            onClick={() => dispatch(newGame())}
          />
          <div className="gameOver_actions_separation"></div>
          {/* Share game */}
          <Button
            className="gameOver_shareGame_button"
            img={isLoadingShareGame ? <Loader color="#fff" /> : <ImgShare />}
            title="Partager"
            onClick={() => {
              if (!isLoadingShareGame) {
                setShowShareGame(true);
              }
            }}
          />
        </div>

        {/*** Infos container ***/}
        <div className="gameOver_infos_container">
          {/*** Infos ***/}
          <div className="gameOver_infos">
            {/* game over label */}
            <div className="gameOver_over_label">
              <span>Partie</span>
              <span>terminée</span>
            </div>

            {/* final score */}
            <div className="gameOver_final_score">
              <span className="gameOver_final_score_value">
                {scoreSheets[0].cells.find((cell) => cell.rowKey === RESULT_KEY.GRAND_TOTAL)?.value}
              </span>
              <span className="gameOver_final_score_pts_label">points</span>
            </div>

            {/* final timer */}
            <div className="gameOver_final_timer">
              <ImgChronometer className="gameOver_chronometer_img" />
              <span className="gameOver_final_timer_value">
                {getFormattedTimer_minSec(timer).map((t, i) => (
                  <span key={i}>{t}</span>
                ))}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/*** New game button ***/}
      <Button
        className="gameOver_new-game_button"
        orientation="vertical"
        img={<ImgPlay />}
        title="Rejouer"
        onClick={() => {
          dispatch(newGame());
          dispatch(startGame());
        }}
      />

      {/*** MODAL: Share game ***/}
      {showShareGame && (
        <ModalView
          modalStyle={{ maxWidth: "1200px", width: "100%", maxHeight: "75%" }}
          modalContentStyle={{ padding: "0" }}
          img={<ImgShare />}
          title="PARTAGER MA GRILLE"
          canCloseByClickingOutside={false}
          onClose={() => setShowShareGame(false)}
        >
          <ShareGame
            onLoading={(isLoading) => setIsLoadingShareGame(isLoading)}
            onClose={() => setShowShareGame(false)}
          />
        </ModalView>
      )}
    </div>
  );
};

export default GameOver;
