/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  /* errors */
  ADD_UI_ERROR,
  REMOVE_UI_ERROR,
  REMOVE_UI_ERRORS,
  /* notif */
  SHOW_NOTIFICATION,
  REMOVE_NOTIFICATION,
} from "../actionTypes";
import { NOTIF_TYPE } from "../../../common/enums";

/* Errors */
export const addUiError = (path: string, error: string) => {
  return (dispatch: any) => {
    dispatch({
      type: ADD_UI_ERROR,
      payload: { [path]: error },
    });
  };
};
export const removeUiError = (path: any) => {
  return (dispatch: any, getState: any) => {
    const { errors } = getState().ui;
    if (errors[path]) {
      dispatch({
        type: REMOVE_UI_ERROR,
        payload: path,
      });
    }
  };
};
export const removeUiErrors = (startsWithPath: any) => {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_UI_ERRORS,
      payload: startsWithPath,
    });
  };
};

/* Notif */
export const showNotification = (type = NOTIF_TYPE.INFO, text: any, timeout = 12000) => {
  return (dispatch: any) => {
    const textOnOneLine = Array.isArray(text) ? text.join(" ") : text;
    dispatch({
      type: SHOW_NOTIFICATION,
      payload: {
        type,
        text: textOnOneLine,
        timeout,
      },
    });
  };
};
export const removeNotification = () => {
  return (dispatch: any) => {
    dispatch({
      type: REMOVE_NOTIFICATION,
    });
  };
};
