import { COL_KEY, ROW_TYPE, SCORE_KEY } from "../../../game-box/core/enums";
import { Cell, ScoreSheet } from "../../../game-box/core/types";
import { buildParentCellClassName, buildCellClassName } from "../scoreSheetsHelper";

const _buildCellClassName = (cell: Cell): string => {
  const { rowKey, type, value, pendingValue, allowedScores, isLocked, bonusValue } = cell;
  let className = "";
  // filled
  if (value !== undefined) {
    // score
    if (type === ROW_TYPE.SCORE) {
      className += " filled";
      // only 2 allowedScores - ok/ko ?
      if (allowedScores && allowedScores.length === 2) {
        // yams
        if (rowKey === SCORE_KEY.YAMS) {
          className += value === 0 ? " ko_1" : " ok_1";
        }
        // others
        else {
          className += value === 0 ? " ko_2" : " ok_2";
        }
      }
    }
    // indicator
    else if (type === ROW_TYPE.INDICATOR) {
      className += value < 0 ? " ko_2" : " ok_2";
    }
    // bonus
    else if (type === ROW_TYPE.BONUS) {
      className += value >= (bonusValue ?? 0) ? " ok_1" : " ko_1";
    }
  }
  // empty
  else {
    // pending
    if (pendingValue !== undefined) {
      className += " pending";
    }
    // score
    if (type === ROW_TYPE.SCORE) {
      className += isLocked === true ? " locked" : " available";
    }
  }
  return className;
};

export const buildParentScoreCellClassName = (cell: Cell): string => {
  const { rowKey, type, colKey } = cell;
  return `
    ${buildParentCellClassName(type, rowKey, colKey as COL_KEY)}
    ${_buildCellClassName(cell)}
  `;
};

export const buildScoreCellClassName = (cell: Cell): string => {
  const { rowKey, type, colKey } = cell;
  return `
    ${buildCellClassName(type, rowKey, colKey as COL_KEY)}
    ${_buildCellClassName(cell)}
  `;
};

export const canScoreOnCell = (scoreSheet: ScoreSheet, cell: Cell, isAllowedToScore: boolean): boolean => {
  return (
    scoreSheet.isCurrent &&
    isAllowedToScore === true &&
    cell.rowKey &&
    cell.colKey &&
    cell.isLocked === false &&
    cell.value === undefined
  );
};
