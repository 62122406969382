import React, { useState, useEffect } from "react";
import { GAME_STATUS } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateStatus, updateTimer } from "../../../../../redux/features/game/gameSlice";

import { getFormattedTimer } from "../../../../../common/utils/dateUtils";
import { ImgPlay, ImgPause, ImgDone } from "../../../../../common/img/common";

import "./gameTimer.css";

let _timerInterval: number;

const GameTimer = (): JSX.Element => {
  const { status, progress, timer: initialTmer, mode } = useAppSelector((state) => state.game);

  const dispatch = useAppDispatch();

  const [timer, setTimer] = useState(initialTmer || 0);

  useEffect(() => {
    window.clearInterval(_timerInterval);
    if (status === GAME_STATUS.ONGOING) {
      _timerInterval = window.setInterval(() => {
        setTimer((timer) => timer + 1);
      }, 1000);
    } else if (status === GAME_STATUS.PAUSED || status === GAME_STATUS.OVER) {
      dispatch(updateTimer(timer));
    }
  }, [status, timer]);

  useEffect(() => {
    dispatch(updateTimer(timer));
  }, [progress]);

  useEffect(() => {
    return () => {
      window.clearInterval(_timerInterval);
    };
  }, []);

  return (
    <div
      style={{ position: "relative" }}
      className={`gameTimer_container ${status} ${mode.key}`}
      onClick={() => {
        status === GAME_STATUS.ONGOING && dispatch(updateStatus(GAME_STATUS.PAUSED));
        status === GAME_STATUS.PAUSED && dispatch(updateStatus(GAME_STATUS.ONGOING));
      }}
    >
      {status === GAME_STATUS.ONGOING && <ImgPause />}
      {status === GAME_STATUS.PAUSED && <ImgPlay />}
      {status === GAME_STATUS.OVER && <ImgDone />}
      <span>{getFormattedTimer(timer)}</span>
    </div>
  );
};

export default GameTimer;
