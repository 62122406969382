import React, { useEffect, useRef, useState } from "react";
import { GAME_STATUS } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { updateTimer } from "../../../../../redux/features/game/gameSlice";

import { SETTING_KEY } from "../../../../../common/enums";
import { getFormattedTimer } from "../../../../../common/utils/dateUtils";

import "./timer.css";

let _timerInterval: number;

interface TimerProps {
  className?: string;
}

const Timer = ({ className }: TimerProps): JSX.Element | null => {
  const gameDate = useAppSelector((state) => state.game.date);
  const gameTimer = useAppSelector((state) => state.game.timer);
  const status = useAppSelector((state) => state.game.status);
  const progress = useAppSelector((state) => state.game.progress);
  const gameBoard = useAppSelector((state) => state.game.gameBoard);
  const settings = useAppSelector((state) => state.settings);

  const dispatch = useAppDispatch();

  const [timer, setTimer] = useState(gameTimer || 0);

  const gameDateRef = useRef(gameDate);
  const timerRef = useRef(timer);

  const handleVisibilityChange = () => {
    if (document.hidden) {
      stopTimer();
    } else if (timerRef.current > 0) {
      startTimer();
    }
  };

  const stopTimer = () => {
    window.clearInterval(_timerInterval);
  };

  const startTimer = () => {
    window.clearInterval(_timerInterval);
    _timerInterval = window.setInterval(() => setTimer((timer) => timer + 1), 1000);
  };

  // init
  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      dispatch(updateTimer(timerRef.current));
      stopTimer();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // maj progress, isThrowingDice
  useEffect(() => {
    // start Timer for first throw
    if (progress === 0 && gameBoard?.throwIndex === 0 && gameBoard.isThrowingDice === true) {
      startTimer();
    }
  }, [progress, gameBoard?.isThrowingDice]);

  // maj status
  useEffect(() => {
    if (status === GAME_STATUS.INITIAL) {
      // stop and reset Timer
      stopTimer();
      setTimer(0);
    } else if (status === GAME_STATUS.ONGOING && progress > 0) {
      // resume timer
      startTimer();
    } else if (status === GAME_STATUS.OVER) {
      // stop Timer
      stopTimer();
    }
  }, [status]);

  // maj progress
  useEffect(() => {
    if (progress === 0) {
      clearInterval(_timerInterval);
      setTimer(0);
    } else {
      dispatch(updateTimer(timer));
    }
  }, [progress]);

  // maj timer
  useEffect(() => {
    timerRef.current = timer;
  }, [timer]);

  // on reset game
  useEffect(() => {
    // stop and reset Timer
    if (gameDateRef.current && gameDateRef.current !== gameDate) {
      stopTimer();
      setTimer(0);
    }
  }, [gameDate]);

  // Show Timer if game is ongnoing and feature enabled from settings
  return status === GAME_STATUS.ONGOING && settings[SETTING_KEY.SHOW_TIMER] === true ? (
    <div className={"timer_container" + (className ? ` ${className}` : "")}>
      <span className="timer_value">{getFormattedTimer(timer, false)}</span>
    </div>
  ) : null;
};

export default Timer;
