import React, { useEffect, useState } from "react";

import GameService from "../../../../services/gameService";

import { ImgRefreshCircle, ImgTrophy } from "../../../../common/img/common";
import { TopScore } from "../../../../common/types";
import { Loader } from "../../../../components/ui";

import ScoreRow from "./scoreRow/ScoreRow";

import "./topScores.css";
import { Button } from "../../../../components/forms";

const TopScores = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadError, setLoadError] = useState<boolean>(false);
  const [topScores, setTopScores] = useState<TopScore[]>([]);

  const loadTopScores = () => {
    setLoadError(false);
    setIsLoading(true);
    // fetch Top Scores
    GameService.getTopScores()
      .then((topScores) => {
        setTopScores(topScores);
      })
      .catch(() => {
        setLoadError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    loadTopScores();
  }, []);

  return (
    <div className="topScores_container">
      {/* Title */}
      <div className="topScores_title_container">
        <div className="topScores_title">
          <ImgTrophy className="topScores_medal_img" />
          <span>Top Scores</span>
        </div>
        <Button
          className="topScores_refresh_button"
          img={isLoading ? <Loader color="#fff" /> : <ImgRefreshCircle />}
          onClick={() => !isLoading && loadTopScores()}
        />
      </div>

      {/* Content */}
      <div className={`topScores_content${isLoading ? " isLoading" : ""}`}>
        {/* loader */}
        {isLoading ? (
          <Loader color="#fff" />
        ) : (
          <>
            {!loadError ? (
              <>
                {/* GOLD, SILVER, BRONZE */}
                <div className="topScores_123">
                  {topScores.slice(0, 3).map((topScore, index) => (
                    <ScoreRow key={index} topScore={topScore} />
                  ))}
                </div>
                {/* separation */}
                <div className="topScores_separator" />
                {/* 4 to 10 */}
                <div className="topScores_4to10">
                  {topScores.slice(3).map((topScore, index) => (
                    <ScoreRow key={index} topScore={topScore} />
                  ))}
                </div>
              </>
            ) : (
              // error
              <div className="topScores_error">Impossible de charger les Top Scores :/</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TopScores;
