import { COL_KEY, ROW_KEY, ROW_TYPE } from "../../game-box/core/enums";

export const buildParentCellClassName = (rowType: ROW_TYPE, _rowKey: ROW_KEY, _colKey?: COL_KEY): string => {
  const colKey = _colKey?.toLowerCase();
  const rowKey = _rowKey.toLowerCase();
  let className = "";
  // COL
  if (colKey) className += `col_${colKey} `;
  // ROW TYPE + ROW KEY
  switch (rowType) {
    case ROW_TYPE.SCORE: {
      className += `cell_score score_${rowKey}`;
      break;
    }
    case ROW_TYPE.RESULT: {
      className += `cell_result result_${rowKey}`;
      break;
    }
    case ROW_TYPE.BONUS: {
      className += `cell_bonus bonus_${rowKey}`;
      break;
    }
    case ROW_TYPE.INDICATOR: {
      className += `cell_indicator indicator_${rowKey}`;
      break;
    }
    default:
      className += "";
  }
  return className;
};

export const buildCellClassName = (rowType: ROW_TYPE, rowKey: ROW_KEY, colKey?: COL_KEY): string => {
  return `cell ${buildParentCellClassName(rowType, rowKey, colKey)}`;
};
