import React from "react";
import { ScoreSheet } from "../../../../../game-box/core/types";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { scoreYamsBonus, spendAdditionalThrow } from "../../../../../redux/features/game/gameSlice";

import { ActionRow } from "../../../../../components/game";

import { additionalThrowInfoLines, yamsBonusInfoLines } from "../../../../../common/messages/game";
import { ImgPlusOne, ImgOneHundred } from "../../../../../common/img/common";

import "./gameActions.css";
import { GAME_MODE, GAME_STATUS } from "../../../../../game-box/core/enums";

interface GameActionsProps {
  currentScoreSheet: ScoreSheet;
}

const GameActions = ({ currentScoreSheet }: GameActionsProps): JSX.Element => {
  const { mode, status, rule, gameBoard, additionalThrows } = useAppSelector((state) => state.game);
  //todo: use selector from slice ?
  const additionalThrow = additionalThrows ? additionalThrows.find((at) => currentScoreSheet.id === at.id) : undefined;
  const canUseAdditionalThrow =
    additionalThrow &&
    additionalThrow.canUse === true &&
    status === GAME_STATUS.ONGOING &&
    (!gameBoard || gameBoard.throwIndex === gameBoard.maxNumberOfThrows);
  const canUseYamsBonus = currentScoreSheet.canUseYamsBonus === true && status === GAME_STATUS.ONGOING;

  const dispatch = useAppDispatch();

  return (
    <div className="gameActions_container">
      {/* use addition throw */}
      {additionalThrow && (
        <ActionRow
          className={canUseAdditionalThrow === true ? "" : " disabled"}
          onClickAction={() => dispatch(spendAdditionalThrow(additionalThrow))}
          img={<ImgPlusOne className="img_plusOne" />}
          title="Utiliser un coup sup'"
          infoLines={additionalThrowInfoLines(rule)}
        />
      )}

      {/* score yams bonus (free mode only) */}
      {rule.withYamsBonus && mode.key === GAME_MODE.FREE && (
        <ActionRow
          className={canUseYamsBonus === true ? "" : " disabled"}
          onClickAction={() => dispatch(scoreYamsBonus(currentScoreSheet))}
          img={<ImgOneHundred />}
          title="Inscrire un bonus de Yams"
          infoLines={yamsBonusInfoLines(rule)}
        />
      )}
    </div>
  );
};

export default GameActions;
