import React from "react";
import { GAME_MODE } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectGameMode } from "../../../../../redux/features/game/gameSlice";

import { ImgFreeGame, ImgPlayers, ImgRemotePlayer } from "../../../../../common/img/common";
import { DiceOne, DiceTwo, DiceThree, DiceFour, DiceFive } from "../../../../../common/img/dice";

import "./gameModeSelection.css";

const GameModeSelection = (): JSX.Element => {
  const game = useAppSelector((state) => state.game);
  const gameModes = useAppSelector((state) => state.gameModes);

  const dispatch = useAppDispatch();

  return (
    <div className="gameModeSelection_container">
      <div className="gameMode_cards">
        {gameModes &&
          gameModes.map((mode) => (
            /* GameMode card */
            <div
              key={mode.key}
              className={`gameMode_card ${mode.key}` + (game.mode && game.mode.key === mode.key ? " selected" : "")}
              onClick={() => dispatch(selectGameMode(mode))}
            >
              {/* title */}
              <span className="gameMode_card_title">{mode.name}</span>

              {/* content */}
              <div className="gameMode_card_content">
                {/* FREE */}
                {mode.key === GAME_MODE.FREE && (
                  <div className="gameMode_img_free_game">
                    <ImgFreeGame />
                  </div>
                )}
                {/* SIMULATION */}
                {mode.key === GAME_MODE.SIMULATION && (
                  <div className="gameMode_card_content_dice">
                    <DiceOne />
                    <DiceTwo />
                    <DiceThree />
                    <DiceFour />
                    <DiceFive />
                  </div>
                )}

                {/* ONLINE */}
                {mode.key === GAME_MODE.ONLINE && (
                  <>
                    <ImgRemotePlayer className="gameMode_img_remote_player" />
                    <ImgPlayers className="gameMode_img_players" />
                  </>
                )}
              </div>
            </div>
          ))}
      </div>

      {/* description */}
      <div className="gameMode_description">
        {game.mode &&
          game.mode.descriptionLines &&
          game.mode.descriptionLines.map((line, i) => <span key={i}>{line}</span>)}
      </div>
    </div>
  );
};

export default GameModeSelection;
