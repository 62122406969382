export default `
  <div>
    <div style={{ fontSize: "1.1em" }}>
      <center>
        <strong>Le Rapido est une règle "maison" dérivée du Yahtzee, dans une version plus complexe.</strong>
      </center>
    </div>
    <br />
    <div>Il se joue en 2 colonnes :</div>
    <div>
      - la colonne <strong>ALÉATOIRE</strong>, dans laquelle un score ne peut être saisi que dans la seule case non
      remplie définie aléatoirement par le jeu.
    </div>
    <div>
      - la colonne <strong>LIBRE</strong>, dans laquelle un score peut être saisi dans n'importe quelle case non remplie
      (comme au Yahtzee).
    </div>
    <br />
    <div>Contrairement au Yahtzee, il n'inclut pas la règle du bonus de Yams ni la règle du Joker.</div>
    <div>Il inclut des combinaisons originales telles que MOINS DE 11 et QUE DES DÉS PAIRS.</div>
    <div>Le bonus de la partie haute est de 40 points.</div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>Le déroulement</strong>
    </div>
    <br />
    <div>
      A chaque fois que c'est votre tour, vous pouvez lancer au maximum 3 fois les dés, et conserver ou relancer les dés
      que vous souhaitez après chaque lancer.
    </div>
    <div>Vous pouvez ensuite décider d'inscrire un score :</div>
    <div>- soit dans la seule case disponible dans la colonne aléatoire,</div>
    <div>- soit dans n'importe quelle case disponible dans la colonne libre.</div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>Le bonus</strong>
    </div>
    <br />
    <div>
      Si le total de la partie haute est supérieure ou égale à 36 points, vous marquez un <strong>bonus</strong> de 40
      points.
    </div>
    <div>
      Cette valeur de 36 points correspond à une somme de 3 dés sur 5 possibles pour chaque combinaison de la partie
      haute :
    </div>
    <div>2x3 + 4x3 + 6x3 = 36</div>
    <br />
    <br />
    <div style={{ fontSize: "1.1em" }}>
      <strong>Deux coups supplémentaires</strong>
    </div>
    <br />
    <div>
      Le Rapido ajoute aussi une règle spéciale vous permettant de jouer
      <strong> un coup supplémentaire</strong> <u>deux fois au cours de la partie</u>.
    </div>
    <div>
      Si au bout de 3 lancers vous être coincé pour inscrire un score, vous pouvez donc utiliser un "coup
      supplémentaire" afin de tenter de vous débloquer d'une mauvaise situation.
    </div>
    <div>
      Vous pouvez choisir d'utiliser vos deux coups supplémentaires à la suite (en 4ème et 5ème lancers) ou séparément
      (en 4ème lancer lors de 2 tours de votre choix). À vous de trouver le bon moment pour les utiliser ;)
    </div>
  </div>
`;
