import React, { useState } from "react";
import { GAME_STATUS } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { newGame } from "../../../../../redux/features/game/gameSlice";

import { ModalConfirmationView } from "../../../../../components/ui";
import { ActionRow } from "../../../../../components/game";

import "./newGameAction.css";

const NewGameAction = (): JSX.Element => {
  const gameStatus = useAppSelector((state) => state.game.status);

  const dispatch = useAppDispatch();

  const [isOnNewGameConfirmation, setOnNewGameConfirmation] = useState(false);

  return (
    <div className="newGameAction_container">
      {/* New game button */}
      <ActionRow
        className="new_game_button"
        onClickAction={() =>
          gameStatus === GAME_STATUS.ONGOING ? setOnNewGameConfirmation(true) : dispatch(newGame())
        }
        title="Nouvelle partie"
      />
      {/* New game modal confirmation */}
      {isOnNewGameConfirmation === true && (
        <ModalConfirmationView
          text={["Souhaitez-vous démarrer une nouvelle partie ?", "La partie en cours sera perdue."]}
          confirmationButtonTitle="Nouvelle partie"
          onClose={() => setOnNewGameConfirmation(false)}
          onConfirm={() => {
            setOnNewGameConfirmation(false);
            dispatch(newGame());
          }}
        />
      )}
    </div>
  );
};

export default NewGameAction;
