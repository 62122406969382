import React from "react";
import { GAME_STATUS } from "../../../../game-box/core/enums";

import { useAppSelector } from "../../../../redux/hooks";

import GameTuto from "./gameTuto/GameTuto";
import GameModeBoard from "./gameBoard";
import GameOver from "./gameOver/GameOver";
import Timer from "./timer/Timer";

import "./gameTable.css";

const GameTable = (): JSX.Element => {
  const gameStatus = useAppSelector((state) => state.game.status);
  const hasTutorialToShow = useAppSelector((state) => !Object.values(state.info.tutorial).every((v) => v === false));

  return (
    <>
      {/* GameTuto (only if there is at least one tuto to show) */}
      {hasTutorialToShow && <GameTuto />}

      <div className="mobile_gameTable_container">
        {gameStatus === GAME_STATUS.ONGOING && (
          // Game in progress
          <GameModeBoard />
        )}
        {gameStatus === GAME_STATUS.OVER ? (
          // Game over
          <GameOver />
        ) : (
          // Timer
          <Timer className="gameBoard_timer" />
        )}
      </div>
    </>
  );
};

export default GameTable;
