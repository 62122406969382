export default `
  <div>
    <div style="font-size: 1.1em">
      <center>
        <strong>
          Le myams est une règle "maison" dérivée du Yahtzee, dans une version plus complexe.
        </strong>
      </center>
    </div>
    <br />
    <br />
    <div><strong>Il se joue en 3 colonnes :</strong></div>
    <br />
    <div>
      - la colonne <strong>DESCENDANTE</strong>, dans laquelle un score ne peut être saisi
      que dans la case non-remplie la plus en haut possible.
    </div>
    <div>
      - la colonne <strong>ASCENDANTE</strong>, dans laquelle un score ne peut être saisi
      que dans la case non-remplie la plus en bas possible.
    </div>
    <div>
      - la colonne <strong>LIBRE</strong>, dans laquelle un score peut être saisi
      dans n'importe quelle case non-remplie (comme au Yahtzee).
    </div>
    <br/>
    <div>
      Contrairement au Yahtzee, il n'inclut pas la règle du bonus de Yams ni la règle du Joker.
    </div>
    <div>
      Il inclut toutefois les combinaisons CHANCE PLUS et CHANCE MOINS au lieu de la combinaison CHANCE.
    </div>
    <div>
      Le score de la CHANCE PLUS doit être supérieure au score de la CHANCE MOINS, et vice versa,
      ou doit être de 0 le cas échéant.
    </div>
    <br/>
    <br/>
    <div style="font-size: 1.1em">
      <strong>LE DÉROULEMENT</strong>
    </div>
    <br/>
    <div>
      À chaque tour, vous pouvez lancer au maximum 3 fois les dés,
      puis conserver ou relancer le(s) dé(s) que vous souhaitez après chaque lancer.
    </div>
    <br />
    <div>Vous pouvez ensuite décider d'inscrire un score :</div>
    <div>- soit dans la colonne DESCENDANTE, dans la case non-remplie la plus en haut possible,</div>
    <div>- soit dans la colonne ASCENDANTE, dans la case non-remplie la plus en bas possible,</div>
    <div>- soit dans n'importe quelle case non-remplie de la colonne LIBRE.</div>
    <br/>
    <br/>
    <div style="font-size: 1.1em">
      <strong>LE BONUS</strong>
    </div>
    <br/>
    <div>
      Si le total de la partie haute est supérieure ou égale à 63 points,
      vous marquez un <strong>bonus</strong> de 35 points.
    </div>
    <br/>
    <div>
      Cette valeur de 63 points correspond à une somme de 3 dés (sur 5 possibles)
      pour chaque combinaison de la partie haute :
    </div>
    <div>3x1 + 3x2 + 3x3 + 3x4 + 3x5 + 3x6 = 63</div>
    <br/>
    <br/>
    <div style="font-size: 1.1em">
      <strong>LE LANCER SUPPLÉMENTAIRE</strong>
    </div>
    <br/>
    <div>
      Le myams ajoute aussi une règle spéciale vous permettant de débloquer
      <strong> un lancer supplémentaire</strong> <u>une seule fois au cours de la partie</u>.
    </div>
    <br/>
    <div>
      Si au bout de 3 lancers vous être coincé pour inscrire un score, vous pouvez débloquer
      votre "lancer supplémentaire" (un 4ème lancer donc).
    </div>
    <div>
      À vous de trouver le bon moment pour l'utiliser ;-)
    </div>
  </div>
`;
