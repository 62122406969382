/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ERROR_TYPE, NOTIF_TYPE } from "../../../common/enums";
import { ApiError } from "../../../common/types";
import { addUiError, showNotification } from "../../__actions/ui";

export const handleError = (func: string, error: ApiError, uiErrorPathRoot: string, silentError?: boolean) => {
  console.error(`${func} error: ${error.message}`);
  // show error if necessary
  return (dispatch: any) => {
    // if silent error, ignore it
    if (silentError === true) {
      return;
    }
    // if action error, addUiError
    else if (error.type === ERROR_TYPE.ACTION_ITEM && uiErrorPathRoot) {
      if (error.field) {
        dispatch(addUiError(`${uiErrorPathRoot}.${error.field}`, error.message));
      } else {
        dispatch(addUiError(uiErrorPathRoot, error.message));
      }
    }
    // if global error, showNotification
    else if (error.type === ERROR_TYPE.GLOBAL) {
      dispatch(showNotification(NOTIF_TYPE.ERROR, error.message));
    }
  };
};
