import React from "react";

import { ImgLogo } from "../appLogo/logo";

import "./splashScreen.css";

const SplashScreen = (): JSX.Element => {
  return (
    <div className="splashScreen">
      <ImgLogo />
      <span>Chargement en cours...</span>
    </div>
  );
};
export default SplashScreen;
