import { createSlice } from "@reduxjs/toolkit";
import { GameMode } from "../../game-box/core/types";
import { getGameModes } from "../../game-box";

const initialState: GameMode[] = getGameModes();

const gameModesSlice = createSlice({
  name: "gameModes",
  initialState,
  reducers: {},
});

export default gameModesSlice.reducer;
