import React from "react";
import packageInfo from "../../../../package.json";

import { ImgLogo } from "./logo";

import "./appLogo_OLD.css";

interface AppLogo_OLDProp {
  className?: string;
  orientation?: string;
  stroke?: string;
  fill?: string;
  onClickBeta?: () => void;
}
const AppLogo_OLD = ({
  className,
  orientation = "horizontal",
  stroke = "#000",
  fill = "#eee",
  onClickBeta,
}: AppLogo_OLDProp): JSX.Element => (
  <div className={`appLogo_OLD_container ${orientation}${className ? ` ${className}` : ""}`}>
    <ImgLogo stroke={stroke} fill={fill} />
    <div className="logo_title_container" style={{ color: fill }}>
      <span className="logo_title">Le myams</span>
      <span className="beta_label" onClick={onClickBeta ? () => onClickBeta() : undefined}>
        BETA
      </span>
      <span className="version_label">v{packageInfo.version}</span>
    </div>
  </div>
);

export default AppLogo_OLD;
