import React, { useEffect, useState } from "react";
import html2canvas from "html2canvas";

import "./shareGame.css";
import { Button } from "../../../components/forms";

interface ShareGameProps {
  onLoading: (isLoading: boolean) => void;
  onClose: () => void;
}
const ShareGame = ({ onLoading, onClose }: ShareGameProps): JSX.Element => {
  const [image, setImage] = useState<string>("");
  const [canClose, setCanClose] = useState<boolean>(false);

  const captureApp = () => {
    const app = document.getElementById("App");
    if (app) {
      // hide modals
      const modalContainer = app.querySelector(".modal_container");
      const modalContainerStyle = modalContainer?.getAttribute("style");
      modalContainer?.setAttribute("style", "visibility: hidden");
      // hide account bar
      // const accountBar = app.querySelector(".account_bubble_text");
      // const accountBarStyle = accountBar?.getAttribute("style");
      // accountBar?.setAttribute("style", "visibility: hidden");
      // disable score animation
      const gameOverScore = app.querySelector(".gameOver_infos_over_score");
      const gameOverScoreStyle = gameOverScore?.getAttribute("style");
      gameOverScore?.setAttribute("style", gameOverScoreStyle ?? "" + "animation: none;");

      //mobile_gameOver_container
      const gameOverContainer = app.querySelector(".mobile_gameOver_container");
      gameOverContainer?.classList.toggle("IS_SHARING");

      onLoading(true);

      html2canvas(app)
        .then((canvas) => {
          setImage(canvas.toDataURL("image/png"));
        })
        .catch((e) => {
          console.error("html2canvas error:", e);
        })
        .finally(() => {
          // reset modals
          modalContainerStyle
            ? modalContainer?.setAttribute("style", modalContainerStyle)
            : modalContainer?.removeAttribute("style");
          // reset account bar
          // accountBarStyle ? accountBar?.setAttribute("style", accountBarStyle) : accountBar?.removeAttribute("style");
          // Reset score animation
          gameOverScore?.setAttribute("style", gameOverScoreStyle ?? "");

          //mobile_gameOver_container
          const gameOverContainer = app.querySelector(".mobile_gameOver_container");
          gameOverContainer?.classList.toggle("IS_SHARING");

          onLoading(false);
        });
    }
  };

  const shareImage = async () => {
    if (navigator.share) {
      const response = await fetch(image);
      const blob = await response.blob();
      const file = new File([blob], "fileName.png", { type: blob.type });
      await navigator
        .share({
          title: "Partagez votre grille",
          files: [file],
        })
        .then(() => {
          setCanClose(true);
        })
        .catch((error) => {
          console.error("Erreur de partage : ", error);
          // TODO: notif ?
        });
    }
  };

  useEffect(() => {
    captureApp();
  }, []);

  return (
    <div className="shareGame_container">
      {image && (
        <div className="shareGame_content">
          <img className="shareGame_image" src={image} alt="" />
          <div className="shareGame_buttons">
            <Button className="shareGame_shareButton" title="JE PARTAGE MA GRILLE" onClick={() => shareImage()} />
            {canClose && <Button className="shareGame_closeButton" title="FERMER" onClick={() => onClose()} />}
          </div>
        </div>
      )}
    </div>
  );
};
export default ShareGame;
