import React, { ReactNode, useState } from "react";

import "./infoBubble.css";

interface InfoBubbleProps {
  children: ReactNode;
  text?: string | string[];
  textLines?: string[];
  position?: string;
}

const InfoBubble = ({ children, text, textLines, position = "top right" }: InfoBubbleProps): JSX.Element => {
  const [showBubble, setShowBubble] = useState(false);
  const lines = textLines || (Array.isArray(text) ? text : [text]);
  return (
    <div className="infoBubble" onMouseEnter={() => setShowBubble(true)} onMouseLeave={() => setShowBubble(false)}>
      {showBubble && (
        <div className={`infoBubble_text ${position}`}>
          {lines.map((line, index) => (
            <div key={index}>{line}</div>
          ))}
        </div>
      )}
      <div className="infoBubble_content">{children}</div>
    </div>
  );
};

export default InfoBubble;
