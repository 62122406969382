import { COL_KEY } from "../enums";
import { Col } from "../types";

export const COL: Record<COL_KEY, Col> = {
  [COL_KEY.FREE]: {
    key: COL_KEY.FREE,
    name: "Libre",
    shortName: "libre",
    longName: "Colonne libre",
    description: "On inscrit les scores dans cette colonne dans n'importe quel ordre.",
  },
  [COL_KEY.FROM_TOP_TO_BOTTOM]: {
    key: COL_KEY.FROM_TOP_TO_BOTTOM,
    name: "Descendante",
    shortName: "descente",
    longName: "Colonne descendante",
    description: "On inscrit les scores dans cette colonne du haut vers le bas.",
  },
  [COL_KEY.FROM_BOTTOM_TO_TOP]: {
    key: COL_KEY.FROM_BOTTOM_TO_TOP,
    name: "Montante",
    longName: "Colonne montante",
    shortName: "montée",
    description: "On inscrit les scores dans cette colonne du bas vers le haut.",
  },
  [COL_KEY.RANDOM]: {
    key: COL_KEY.RANDOM,
    name: "Aléatoire",
    shortName: "aléatoire",
    longName: "Colonne aléatoire",
    description: "On inscrit les scores dans cette colonne dans un ordre défini aléatoirement par le jeu.",
  },
};
