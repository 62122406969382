import packageInfo from "../../package.json";

export enum VERSIONS {
  V0_10_0 = 100, // Added TypeScript, so we have a lot of new model types -> Hard reset the old persisted state
  V1_1_0 = 110, // Secure game in progress with token
}

export const parseVersion = (version: string | number): number => {
  return typeof version === "number" ? version : parseInt(version.replaceAll(".", "")) || 0;
};

export const computePersistedVersion = (): number => {
  const rootPersistedState = localStorage.getItem("persist:state");
  const persistedState = rootPersistedState ? JSON.parse(rootPersistedState) : undefined;
  const persisted = persistedState ? JSON.parse(persistedState._persist) : undefined;
  return persisted?.version ? parseVersion(persisted.version) : 0;
};

/**
 * Performs a custom migration process of the app state.
 * by checking the version of the persisted state and the current version of the app
 *
 * @return {void} This function does not return a value.
 */
export const migrateState = (): void => {
  try {
    // remove an old storage key used for old app version
    localStorage.removeItem("state");

    // get old version and current version
    const persistedVersion = computePersistedVersion();
    const newVersion = parseVersion(packageInfo.version);

    // no migration needed
    if (persistedVersion === 0 || persistedVersion === newVersion) {
      return;
    }

    /*** Migrations : ***/
    // v0.10.0 (100)
    if (persistedVersion < VERSIONS.V0_10_0) {
      localStorage.removeItem("persist:state");
    }
    // v1.1.0 (110)
    else if (persistedVersion < VERSIONS.V1_1_0) {
      // reset game is made by UpdatePrompt element after clicking update button
    }

    /* DEBUG: clear all state, for mobile debug only !!! */
    /* eslint-disable no-undef */
    // process.env.NODE_ENV !== "production" && localStorage.clear();
  } catch (err) {
    console.error("localStorage.removeItem('state') error", err);
  }
};
