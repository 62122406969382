import React, { ReactElement, useEffect, useState } from "react";

import "./bouncingPopup.css";

const computeAnchorLeft = (anchorElement?: Element | null): number | undefined => {
  if (anchorElement === null || anchorElement === undefined) return undefined;
  const { x: anchorX, width: anchorWidth } = anchorElement?.getBoundingClientRect() || {};
  return anchorX + anchorWidth / 2 - 18 - 20 / 2;
};

interface BouncingPopupProps {
  show?: boolean;
  anchorElement?: Element | null;
  content?: ReactElement;
  confirmLabel?: ReactElement | string;
  onConfirm?: () => void;
}
const BouncingPopup = ({ show, anchorElement, content, confirmLabel, onConfirm }: BouncingPopupProps): JSX.Element => {
  const [anchorLeft, setAnchorLeft] = useState<number | undefined>();

  useEffect(() => setAnchorLeft(computeAnchorLeft(anchorElement)), [anchorElement]);

  return (
    <div className={`bouncingPopupContainer${show === true ? " show" : show === false ? " hide" : ""}`}>
      <div
        className="bouncingPopup_content"
        style={anchorLeft && anchorLeft < 4 ? { borderBottomLeftRadius: "6px" } : {}}
      >
        {/* Popup Content */}
        {content}
        {/* Confirm button */}
        {confirmLabel && (
          <div className="bouncingPopup_confirm_button" onClick={onConfirm}>
            {confirmLabel}
          </div>
        )}
        {/* Anchor (triangle) to element */}
        {anchorElement && (
          <div style={{ left: `${anchorLeft}px`, bottom: "-24px" }} className="bouncingPopup_anchor"></div>
        )}
      </div>
    </div>
  );
};

export default BouncingPopup;
