import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ImgProps extends Record<string, any> {
  className?: string;
}

export const ImgArrowUp = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
  </svg>
);

export const ImgArrowDown = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z"></path>
  </svg>
);

export const ImgArrowLeft = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z"></path>
  </svg>
);

export const ImgArrowRight = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path>
  </svg>
);

export const ImgInfo = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M11 18h2v-2h-2v2zm1-16C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14c-2.21 0-4 1.79-4 4h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"></path>
  </svg>
);

export const ImgInfoExclamation = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-.696-3.534c.63 0 1.332-.288 2.196-1.458l.911-1.22a.334.334 0 0 0-.074-.472.38.38 0 0 0-.505.06l-1.475 1.679a.241.241 0 0 1-.279.061.211.211 0 0 1-.12-.244l1.858-7.446a.499.499 0 0 0-.575-.613l-3.35.613a.35.35 0 0 0-.276.258l-.086.334a.25.25 0 0 0 .243.312h1.73l-1.476 5.922c-.054.234-.144.63-.144.918 0 .666.396 1.296 1.422 1.296zm1.83-10.536c.702 0 1.242-.414 1.386-1.044.036-.144.054-.306.054-.414 0-.504-.396-.972-1.134-.972-.702 0-1.242.414-1.386 1.044a1.868 1.868 0 0 0-.054.414c0 .504.396.972 1.134.972z"></path>{" "}
  </svg>
);

export const ImgAddPlayer = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M15 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm-9-2V7H4v3H1v2h3v3h2v-3h3v-2H6zm9 4c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path>
  </svg>
);

export const ImgRemove = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"></path>
  </svg>
);

export const ImgEdit = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
  </svg>
);

export const ImgValidate = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
  </svg>
);

export const ImgFullScreen = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="2 2 20 20"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M7 14H5v5h5v-2H7v-3zm-2-4h2V7h3V5H5v5zm12 7h-3v2h5v-5h-2v3zM14 5v2h3v3h2V5h-5z"></path>
  </svg>
);

export const ImgFullScreenExit = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="2 2 20 20"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M5 16h3v3h2v-5H5v2zm3-8H5v2h5V5H8v3zm6 11h2v-3h3v-2h-5v5zm2-11V5h-2v5h5V8h-3z"></path>
  </svg>
);

export const ImgRules = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    {/* <path d="M4 14h4v-4H4v4zm0 5h4v-4H4v4zM4 9h4V5H4v4zm5 5h12v-4H9v4zm0 5h12v-4H9v4zM9 5v4h12V5H9z"></path> */}
    <path d="M19 3h-4.18C14.4 1.84 13.3 1 12 1c-1.3 0-2.4.84-2.82 2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm-7 0c.55 0 1 .45 1 1s-.45 1-1 1-1-.45-1-1 .45-1 1-1zm2 14H7v-2h7v2zm3-4H7v-2h10v2zm0-4H7V7h10v2z"></path>
  </svg>
);

export const DiceFive = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width={props.width || "22px"}
    height={props.height || "22px"}
    viewBox="0 0 485 485"
    {...props}
  >
    <path d="M0,0v485h485V0H0z M455,455H30V30h425V455z" />
    <path d="M118.75,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,401.25,118.75,401.25z" />
    <path d="M118.75,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S99.451,153.75,118.75,153.75z" />
    <path d="M242.5,277.5c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S223.201,277.5,242.5,277.5z" />
    <path d="M366.25,401.25c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,401.25,366.25,401.25z" />
    <path d="M366.25,153.75c19.299,0,35-15.701,35-35s-15.701-35-35-35s-35,15.701-35,35S346.951,153.75,366.25,153.75z" />
  </svg>
);

export const ImgPlay = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M8 5v14l11-7z"></path>
  </svg>
);

export const ImgPause = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z"></path>
  </svg>
);

export const ImgCancel = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    width="26px"
    height="26px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"></path>
  </svg>
);

export const ImgDone = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
  </svg>
);

export const ImgSettings = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M19.43 12.98c.04-.32.07-.64.07-.98s-.03-.66-.07-.98l2.11-1.65c.19-.15.24-.42.12-.64l-2-3.46c-.12-.22-.39-.3-.61-.22l-2.49 1c-.52-.4-1.08-.73-1.69-.98l-.38-2.65C14.46 2.18 14.25 2 14 2h-4c-.25 0-.46.18-.49.42l-.38 2.65c-.61.25-1.17.59-1.69.98l-2.49-1c-.23-.09-.49 0-.61.22l-2 3.46c-.13.22-.07.49.12.64l2.11 1.65c-.04.32-.07.65-.07.98s.03.66.07.98l-2.11 1.65c-.19.15-.24.42-.12.64l2 3.46c.12.22.39.3.61.22l2.49-1c.52.4 1.08.73 1.69.98l.38 2.65c.03.24.24.42.49.42h4c.25 0 .46-.18.49-.42l.38-2.65c.61-.25 1.17-.59 1.69-.98l2.49 1c.23.09.49 0 .61-.22l2-3.46c.12-.22.07-.49-.12-.64l-2.11-1.65zM12 15.5c-1.93 0-3.5-1.57-3.5-3.5s1.57-3.5 3.5-3.5 3.5 1.57 3.5 3.5-1.57 3.5-3.5 3.5z"></path>
  </svg>
);

export const ImgFreeGame = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#000"}
    {...props}
  >
    <path d="M17.75 7L14 3.25l-10 10V17h3.75l10-10zm2.96-2.96c.39-.39.39-1.02 0-1.41L18.37.29c-.39-.39-1.02-.39-1.41 0L15 2.25 18.75 6l1.96-1.96z"></path>
    <path fillOpacity=".36" d="M0 20h24v4H0z"></path>
  </svg>
);

export const ImgUndo = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M12.5 8c-2.65 0-5.05.99-6.9 2.6L2 7v9h9l-3.62-3.62c1.39-1.16 3.16-1.88 5.12-1.88 3.54 0 6.55 2.31 7.6 5.5l2.37-.78C21.08 11.03 17.15 8 12.5 8z"></path>
  </svg>
);

export const ImgPlusOne = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="26px"
    height="26px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    stroke={props.color || "#424242"}
    {...props}
  >
    <path d="M10 8H8v4H4v2h4v4h2v-4h4v-2h-4zm4.5-1.92V7.9l2.5-.5V18h2V5z"></path>
  </svg>
);

export const ImgPlayers = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M16 11c1.66 0 2.99-1.34 2.99-3S17.66 5 16 5c-1.66 0-3 1.34-3 3s1.34 3 3 3zm-8 0c1.66 0 2.99-1.34 2.99-3S9.66 5 8 5C6.34 5 5 6.34 5 8s1.34 3 3 3zm0 2c-2.33 0-7 1.17-7 3.5V19h14v-2.5c0-2.33-4.67-3.5-7-3.5zm8 0c-.29 0-.62.02-.97.05 1.16.84 1.97 1.97 1.97 3.45V19h6v-2.5c0-2.33-4.67-3.5-7-3.5z"></path>
  </svg>
);

export const ImgRemotePlayer = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <circle cx="9" cy="9" r="4"></circle>
    <path d="M9 15c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4zm7.76-9.64l-1.68 1.69c.84 1.18.84 2.71 0 3.89l1.68 1.69c2.02-2.02 2.02-5.07 0-7.27zM20.07 2l-1.63 1.63c2.77 3.02 2.77 7.56 0 10.74L20.07 16c3.9-3.89 3.91-9.95 0-14z"></path>
  </svg>
);

export const ImgMyAccount = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z"></path>
  </svg>
);

export const ImgMyAccount2 = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 45.532 45.532"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M22.766,0.001C10.194,0.001,0,10.193,0,22.766s10.193,22.765,22.766,22.765c12.574,0,22.766-10.192,22.766-22.765 S35.34,0.001,22.766,0.001z M22.766,6.808c4.16,0,7.531,3.372,7.531,7.53c0,4.159-3.371,7.53-7.531,7.53 c-4.158,0-7.529-3.371-7.529-7.53C15.237,10.18,18.608,6.808,22.766,6.808z M22.761,39.579c-4.149,0-7.949-1.511-10.88-4.012 c-0.714-0.609-1.126-1.502-1.126-2.439c0-4.217,3.413-7.592,7.631-7.592h8.762c4.219,0,7.619,3.375,7.619,7.592 c0,0.938-0.41,1.829-1.125,2.438C30.712,38.068,26.911,39.579,22.761,39.579z" />
  </svg>
);

export const ImgSmiley = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z"></path>
  </svg>
);

export const ImgZoomIn = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zm2.5-4h-2v2H9v-2H7V9h2V7h1v2h2v1z"></path>
  </svg>
);

export const ImgZoomOut = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14zM7 9h5v1H7z"></path>
  </svg>
);

export const ImgThemeColor = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M12 3c-4.97 0-9 4.03-9 9s4.03 9 9 9c.83 0 1.5-.67 1.5-1.5 0-.39-.15-.74-.39-1.01-.23-.26-.38-.61-.38-.99 0-.83.67-1.5 1.5-1.5H16c2.76 0 5-2.24 5-5 0-4.42-4.03-8-9-8zm-5.5 9c-.83 0-1.5-.67-1.5-1.5S5.67 9 6.5 9 8 9.67 8 10.5 7.33 12 6.5 12zm3-4C8.67 8 8 7.33 8 6.5S8.67 5 9.5 5s1.5.67 1.5 1.5S10.33 8 9.5 8zm5 0c-.83 0-1.5-.67-1.5-1.5S13.67 5 14.5 5s1.5.67 1.5 1.5S15.33 8 14.5 8zm3 4c-.83 0-1.5-.67-1.5-1.5S16.67 9 17.5 9s1.5.67 1.5 1.5-.67 1.5-1.5 1.5z"></path>
  </svg>
);

export const ImgLogout = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M13 3h-2v10h2V3zm4.83 2.17l-1.42 1.42C17.99 7.86 19 9.81 19 12c0 3.87-3.13 7-7 7s-7-3.13-7-7c0-2.19 1.01-4.14 2.58-5.42L6.17 5.17C4.23 6.82 3 9.26 3 12c0 4.97 4.03 9 9 9s9-4.03 9-9c0-2.74-1.23-5.18-3.17-6.83z"></path>
  </svg>
);

export const ImgKey = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="22px"
    viewBox="0 0 512 512"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M463.748,48.251c-64.336-64.336-169.013-64.335-233.349,0.001c-43.945,43.945-59.209,108.706-40.181,167.461 L4.396,401.536c-2.813,2.813-4.395,6.621-4.395,10.606V497c0,8.291,6.709,15,15,15h84.858c3.984,0,7.793-1.582,10.605-4.395 l21.211-21.226c3.237-3.237,4.819-7.778,4.292-12.334l-2.637-22.793l31.582-2.974c7.178-0.674,12.847-6.343,13.521-13.521 l2.974-31.582l22.793,2.651c4.233,0.571,8.496-0.85,11.704-3.691c3.193-2.856,5.024-6.929,5.024-11.206V363h27.422 c3.984,0,7.793-1.582,10.605-4.395l38.467-37.958c58.74,19.043,122.381,4.929,166.326-39.046 C528.084,217.266,528.084,112.587,463.748,48.251z M421.313,154.321c-17.549,17.549-46.084,17.549-63.633,0 s-17.549-46.084,0-63.633s46.084-17.549,63.633,0S438.861,136.772,421.313,154.321z" />
  </svg>
);

export const ImgEmail = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
  </svg>
);

export const ImgTrashCan = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"></path>
  </svg>
);

export const ImgWarning = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
    {...props}
  >
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
  </svg>
);

export const ImgDotsMenu = (props: ImgProps): JSX.Element => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width="22px"
    height="22px"
    viewBox="20 140 512 256"
    preserveAspectRatio="xMidYMid meet"
    fill={props.color || "#424242"}
    {...props}
  >
    <g
      transform="translate(0.000000,0.000000) rotate(90, 10, 20) scale(0.100000,-0.100000)"
      fill={props.color || "#424242"}
      stroke="none"
    >
      <path
        d="M841 3024 c-253 -68 -410 -331 -346 -579 67 -256 329 -415 580 -350
190 50 330 207 358 401 48 331 -270 615 -592 528z"
      />
      <path
        d="M2441 3024 c-253 -68 -410 -331 -346 -579 67 -256 329 -415 580 -350
190 50 330 207 358 401 48 331 -270 615 -592 528z"
      />
      <path
        d="M4041 3024 c-253 -68 -410 -331 -346 -579 67 -256 329 -415 580 -350
190 50 330 207 358 401 48 331 -270 615 -592 528z"
      />
    </g>
  </svg>
);

export const ImgOneHundred = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="22px"
    height="22px"
    viewBox="0 0 47.5 47.5"
    fill={props.color || "#bb1a34"}
    {...props}
  >
    <defs id="defs6">
      <clipPath id="clipPath16" clipPathUnits="userSpaceOnUse">
        <path id="path18" d="M 0,38 38,38 38,0 0,0 0,38 Z" />
      </clipPath>
    </defs>
    <g transform="matrix(1.25,0,0,-1.25,0,47.5)" id="g10">
      <g id="g12">
        <g clipPath="url(#clipPath16)" id="g14">
          <g transform="translate(2.7275,16)" id="g20">
            <path
              id="path22"
              style={{ fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 0,0 c -0.617,0 -0.953,0.256 -1.127,0.471 -0.171,0.211 -0.348,0.585 -0.225,1.165 l 2.728,12.706
          -1.714,-0.097 -0.013,0 c -0.516,0 -0.892,0.167 -1.127,0.458 -0.219,0.272 -0.298,0.621 -0.221,0.981
          0.151,0.701 0.883,1.285 1.668,1.329 l 4.008,0.227 c 0.077,0.005 0.149,0.008 0.216,0.008 0.147,0
          0.536,0 0.784,-0.307 0.252,-0.312 0.166,-0.709 0.138,-0.839 L 1.991,1.546 C 1.804,0.662 1.072,0.057
          0.125,0.004 L 0,0 Z"
            />
          </g>
          <g transform="translate(16.8853,31.7456)" id="g24">
            <path
              id="path26"
              style={{ fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 0,0 -0.137,-0.004 c -1.94,-0.111 -3.555,-2.304 -4.32,-5.866 -0.477,-2.228 -0.38,-3.899
          0.273,-4.707 0.297,-0.368 0.717,-0.554 1.249,-0.554 l 0.14,0.004 c 1.94,0.109 3.554,2.301
          4.318,5.863 0.479,2.229 0.383,3.901 -0.27,4.708 C 0.956,-0.187 0.534,0 0,0 m -3.414,-13.746
          c -1.439,0 -2.635,0.539 -3.459,1.559 -1.163,1.438 -1.467,3.651 -0.878,6.396 1.032,4.812
          4.208,8.187 7.902,8.395 1.59,0.09 2.906,-0.452 3.793,-1.549 1.163,-1.439 1.467,-3.651 0.878,-6.397
          -1.032,-4.81 -4.209,-8.184 -7.904,-8.394 -0.112,-0.007 -0.223,-0.01 -0.332,-0.01"
            />
          </g>
          <g transform="translate(31.8867,33.3862)" id="g28">
            <path
              id="path30"
              style={{ fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 0,0 -0.136,-0.004 c -1.94,-0.111 -3.555,-2.304 -4.32,-5.865 -0.478,-2.228 -0.381,-3.901
          0.272,-4.708 0.297,-0.368 0.719,-0.555 1.251,-0.555 l 0.14,0.004 c 1.939,0.109 3.554,2.301
          4.318,5.864 0.48,2.227 0.383,3.899 -0.269,4.707 C 0.957,-0.187 0.535,0 0,0 m -3.411,-13.748
          c -1.44,0 -2.637,0.54 -3.462,1.561 -1.163,1.439 -1.467,3.651 -0.878,6.396 1.033,4.813 4.209,8.187
          7.903,8.395 1.603,0.09 2.904,-0.453 3.79,-1.549 1.163,-1.44 1.467,-3.652 0.878,-6.397 -1.031,-4.809
          -4.206,-8.183 -7.902,-8.396 -0.111,-0.008 -0.221,-0.01 -0.329,-0.01"
            />
          </g>
          <g transform="translate(12.0005,1.999)" id="g32">
            <path
              id="path34"
              style={{ fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 0,0 c -0.81,0 -1.572,0.496 -1.873,1.299 -0.388,1.034 0.136,2.186 1.17,2.575 0.338,0.126
        8.4,3.108 20.536,4.12 1.102,0.096 2.068,-0.726 2.16,-1.827 C 22.084,5.066 21.266,4.1 20.166,4.008
        8.576,3.042 0.779,0.157 0.702,0.128 0.471,0.042 0.233,0 0,0"
            />
          </g>
          <g transform="translate(3.0015,8)" id="g36">
            <path
              id="path38"
              style={{ fillOpacity: 1, fillRule: "nonzero", stroke: "none" }}
              d="m 0,0 c -0.804,0 -1.562,0.488 -1.868,1.283 -0.396,1.031 0.118,2.188 1.149,2.583 0.542,0.209
        13.515,5.126 32.612,6.131 1.113,0.069 2.045,-0.789 2.103,-1.892 C 34.054,7.002 33.207,6.061
        32.104,6.003 13.681,5.033 0.843,0.182 0.715,0.133 0.48,0.043 0.238,0 0,0"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export const ImgRollTheDice = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    x="0px"
    y="0px"
    width="32px"
    height="32px"
    // style={{ enableBbackground: "new 0 0 512 512" }}
    viewBox="0 0 512 512"
    fill={props.color || "#bb1a34"}
    {...props}
  >
    <g className="layer">
      <title>Layer 1</title>
      <g id="surface1">
        <path
          id="svg_3"
          fillRule="nonzero"
          fill="rgb(0%,0%,0%)"
          d="m441.07422,320.21484l-76.05469,39.77734l-5.59766,0c1.66406,-4.69141 2.57031,-9.74219 2.57031,-15c0,-24.8125 -20.1875,-44.99609 -45,-44.99609l-55.45703,0l-71.10156,-47.40234c-12.36328,-8.24219 -26.74609,-12.59766 -41.60156,-12.59766l-61.41406,0c-6.19141,-17.45703 -22.86328,-30 -42.41797,-30l-30,0c-8.28516,0 -15,6.71484 -15,15l0,209.99609c0,8.28516 6.71484,15 15,15l30,0c17.06641,0 31.94531,-9.55078 39.57031,-23.59375l93.10156,45.69922c10.34766,5.16406 21.92578,7.89453 33.48828,7.89453l120.5,0c13.93359,0 27.5625,-3.87109 39.39844,-11.18359c129.98438,-80.11719 121.89844,-75.04297 122.93359,-75.81641c19.89063,-14.91797 23.92188,-43.09766 8.99609,-62.99609c-14.61719,-19.48828 -42.13281,-23.73047 -61.91406,-9.78125zm-381.07422,84.77734c0,8.26953 -6.73047,15 -15,15l-15,0l0,-179.99609l15,0c8.26953,0 15,6.73047 15,15l0,149.99609zm416.44141,-36.34766l-121.13281,74.63281c-7.11328,4.39453 -15.28906,6.71484 -23.64844,6.71484l-120.5,0c-6.94922,0 -13.90625,-1.64063 -20.125,-4.75c-0.03125,-0.01563 -0.06642,-0.03125 -0.09767,-0.05078l-100.93749,-49.54297l0,-125.65234l58.83204,0c8.91016,0 17.54297,2.61328 24.95702,7.55859l74.88672,49.92188c2.46094,1.64063 5.35547,2.51953 8.31642,2.51953l60,0c8.26953,0 15,6.72656 15,14.99609c0,8.27344 -6.73047,15 -15,15l-119.99612,0c-8.28513,0 -15,6.71875 -15,15c0,8.28516 6.71487,15 15,15l171.71097,0c2.41797,0 4.80469,-0.58594 6.94922,-1.70703c84.65234,-44.35156 80.51172,-41.91406 82.33594,-43.28516c6.61719,-4.96484 16.03516,-3.61719 20.99609,2.99609c4.85547,6.47653 3.69531,15.59375 -2.54688,20.64844zm0,0"
        />
      </g>
      <rect
        transform="rotate(39.8056, 345.001, 136)"
        ry="10"
        rx="10"
        stroke="#000000"
        fillOpacity="0"
        id="svg_1"
        height="82"
        width="82"
        y="95"
        x="304.00067"
        // strokeLinecap="null"
        // strokeLinejoin="null"
        strokeDasharray="null"
        strokeWidth="10"
        fill="#000000"
      />
      <rect
        transform="rotate(-92.454, 232.001, 75)"
        ry="10"
        rx="10"
        stroke="#000000"
        fillOpacity="0"
        id="svg_2"
        height="82"
        width="82"
        y="34"
        x="191.00067"
        // strokeLinecap="null"
        // strokeLinejoin="null"
        strokeDasharray="null"
        strokeWidth="10"
        fill="#000000"
      />
      <rect
        transform="rotate(-28.4112, 419.999, 256)"
        ry="10"
        rx="10"
        stroke="#000000"
        fillOpacity="0"
        id="svg_4"
        height="82"
        width="82"
        y="215"
        x="378.99861"
        // strokeLinecap="null"
        // strokeLinejoin="null"
        strokeDasharray="null"
        strokeWidth="10"
        fill="#000000"
      />
      <rect
        transform="rotate(17.9295, 283.999, 243)"
        ry="10"
        rx="10"
        stroke="#000000"
        fillOpacity="0"
        id="svg_5"
        height="82"
        width="82"
        y="202"
        x="242.99861"
        // strokeLinecap="null"
        // strokeLinejoin="null"
        strokeDasharray="null"
        strokeWidth="10"
        fill="#000000"
      />
      <rect
        transform="rotate(-10.0588, 457.999, 93)"
        ry="10"
        rx="10"
        stroke="#000000"
        fillOpacity="0"
        id="svg_6"
        height="82"
        width="82"
        y="52"
        x="416.99861"
        // strokeLinecap="null"
        // strokeLinejoin="null"
        strokeDasharray="null"
        strokeWidth="10"
        fill="#000000"
      />
    </g>
  </svg>
);

export const ImgLockedCircle = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="-1 0 19 19"
    // fill="#fff"
    // stroke="#000"
    {...props}
  >
    <path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zm-4.23-.585a.476.476 0 0 0-.475-.475h-.7V7.262a2.52 2.52 0 0 0-5.041 0v1.261h-.7a.476.476 0 0 0-.475.475v5.108a.476.476 0 0 0 .475.475h6.44a.477.477 0 0 0 .476-.475zM10.22 7.262v1.261H6.762V7.262a1.73 1.73 0 0 1 3.459 0zm-1.413 4.363v1.309a.317.317 0 1 1-.633 0v-1.309a.79.79 0 1 1 .633 0z" />
  </svg>
);

export const ImgRefreshCircle = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="#fff"
    stroke="#000"
    {...props}
  >
    <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM6.6 11.23C6.77 10.04 7.3 8.97 8.13 8.13C10.13 6.14 13.28 6.03 15.42 7.77V6.82C15.42 6.41 15.76 6.07 16.17 6.07C16.58 6.07 16.92 6.41 16.92 6.82V9.49C16.92 9.9 16.58 10.24 16.17 10.24H13.5C13.09 10.24 12.75 9.9 12.75 9.49C12.75 9.08 13.09 8.74 13.5 8.74H14.25C12.7 7.66 10.56 7.81 9.18 9.19C8.58 9.79 8.2 10.57 8.07 11.44C8.02 11.81 7.7 12.08 7.33 12.08C7.29 12.08 7.26 12.08 7.22 12.07C6.83 12.02 6.54 11.64 6.6 11.23ZM15.87 15.87C14.8 16.94 13.4 17.47 12 17.47C10.78 17.47 9.57 17.04 8.57 16.23V17.17C8.57 17.58 8.23 17.92 7.82 17.92C7.41 17.92 7.07 17.58 7.07 17.17V14.5C7.07 14.09 7.41 13.75 7.82 13.75H10.49C10.9 13.75 11.24 14.09 11.24 14.5C11.24 14.91 10.9 15.25 10.49 15.25H9.74C11.29 16.33 13.43 16.18 14.81 14.8C15.41 14.2 15.79 13.42 15.92 12.55C15.98 12.14 16.35 11.85 16.77 11.91C17.18 11.97 17.46 12.35 17.41 12.76C17.23 13.97 16.7 15.04 15.87 15.87Z" />
  </svg>
);

export const ImgShare = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="#fff"
    stroke="#000"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.803 5.33333C13.803 3.49238 15.3022 2 17.1515 2C19.0008 2 20.5 3.49238 20.5 5.33333C20.5 7.17428 19.0008 8.66667 17.1515 8.66667C16.2177 8.66667 15.3738 8.28596 14.7671 7.67347L10.1317 10.8295C10.1745 11.0425 10.197 11.2625 10.197 11.4872C10.197 11.9322 10.109 12.3576 9.94959 12.7464L15.0323 16.0858C15.6092 15.6161 16.3473 15.3333 17.1515 15.3333C19.0008 15.3333 20.5 16.8257 20.5 18.6667C20.5 20.5076 19.0008 22 17.1515 22C15.3022 22 13.803 20.5076 13.803 18.6667C13.803 18.1845 13.9062 17.7255 14.0917 17.3111L9.05007 13.9987C8.46196 14.5098 7.6916 14.8205 6.84848 14.8205C4.99917 14.8205 3.5 13.3281 3.5 11.4872C3.5 9.64623 4.99917 8.15385 6.84848 8.15385C7.9119 8.15385 8.85853 8.64725 9.47145 9.41518L13.9639 6.35642C13.8594 6.03359 13.803 5.6896 13.803 5.33333Z"
    />
  </svg>
);

export const ImgMedal = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill="#fff"
    stroke="#000"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 16C15.866 16 19 12.866 19 9C19 5.13401 15.866 2 12 2C8.13401 2 5 5.13401 5 9C5 12.866 8.13401 16 12 16ZM12 6C11.7159 6 11.5259 6.34084 11.1459 7.02251L11.0476 7.19887C10.9397 7.39258 10.8857 7.48944 10.8015 7.55334C10.7173 7.61725 10.6125 7.64097 10.4028 7.68841L10.2119 7.73161C9.47396 7.89857 9.10501 7.98205 9.01723 8.26432C8.92945 8.54659 9.18097 8.84072 9.68403 9.42898L9.81418 9.58117C9.95713 9.74833 10.0286 9.83191 10.0608 9.93531C10.0929 10.0387 10.0821 10.1502 10.0605 10.3733L10.0408 10.5763C9.96476 11.3612 9.92674 11.7536 10.1565 11.9281C10.3864 12.1025 10.7318 11.9435 11.4227 11.6254L11.6014 11.5431C11.7978 11.4527 11.8959 11.4075 12 11.4075C12.1041 11.4075 12.2022 11.4527 12.3986 11.5431L12.5773 11.6254C13.2682 11.9435 13.6136 12.1025 13.8435 11.9281C14.0733 11.7536 14.0352 11.3612 13.9592 10.5763L13.9395 10.3733C13.9179 10.1502 13.9071 10.0387 13.9392 9.93531C13.9714 9.83191 14.0429 9.74833 14.1858 9.58118L14.316 9.42898C14.819 8.84072 15.0706 8.54659 14.9828 8.26432C14.895 7.98205 14.526 7.89857 13.7881 7.73161L13.5972 7.68841C13.3875 7.64097 13.2827 7.61725 13.1985 7.55334C13.1143 7.48944 13.0603 7.39258 12.9524 7.19887L12.8541 7.02251C12.4741 6.34084 12.2841 6 12 6Z"
      // fill="#1C274C"
    />
    <path
      d="M7.09301 15.9414L6.71424 17.323C6.0859 19.6148 5.77173 20.7607 6.19097 21.3881C6.3379 21.6079 6.535 21.7844 6.76372 21.9008C7.41634 22.2331 8.424 21.7081 10.4393 20.658C11.1099 20.3086 11.4452 20.1339 11.8014 20.0959C11.9335 20.0818 12.0665 20.0818 12.1986 20.0959C12.5548 20.1339 12.8901 20.3086 13.5607 20.658C15.576 21.7081 16.5837 22.2331 17.2363 21.9008C17.465 21.7844 17.6621 21.6079 17.809 21.3881C18.2283 20.7607 17.9141 19.6148 17.2858 17.323L16.907 15.9414C15.5208 16.9231 13.8278 17.5 12 17.5C10.1722 17.5 8.47915 16.9231 7.09301 15.9414Z"
      // fill="#1C274C"
    />
  </svg>
);

export const ImgTrophy = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 512 512"
    fill="#fff"
    stroke="#000"
    {...props}
  >
    <g>
      <path
        d="M102.49,0c0,27.414,0,104.166,0,137.062c0,112.391,99.33,156.25,153.51,156.25
		c54.18,0,153.51-43.859,153.51-156.25c0-32.896,0-109.648,0-137.062H102.49z M256.289,50.551l-68.164,29.768v98.474l-0.049,19.53
		c-0.526-0.112-47.274-10.112-47.274-78.391c0-28.17,0-69.6,0-69.6h60.385L256.289,50.551z"
      />
      <polygon
        points="315.473,400.717 291.681,367.482 279.791,318.506 256,322.004 232.209,318.506 220.314,367.482 
		205.347,388.394 196.527,400.476 196.699,400.476 196.527,400.717 	"
      />
      <polygon
        points="366.93,432.24 366.93,432 145.07,432 145.07,511.598 145.07,511.76 145.07,511.76 145.07,512 
		366.93,512 366.93,432.402 366.93,432.24 	"
      />
      <path
        d="M511.638,96.668c-0.033-1.268-0.068-2.336-0.068-3.174V45.1h-73.889v38.736h35.152v9.658
		c0,1.127,0.037,2.557,0.086,4.258c0.389,13.976,1.303,46.707-21.545,70.203c-5.121,5.266-11.221,9.787-18.219,13.613
		c-3.883,17.635-10.109,33.564-18.104,47.814c26.561-6.406,48.026-17.898,64.096-34.422
		C513.402,159.734,512.121,113.918,511.638,96.668z"
      />
      <path
        d="M60.625,167.955c-22.848-23.496-21.934-56.227-21.541-70.203c0.047-1.701,0.082-3.131,0.082-4.258v-9.658
		h34.842h0.07l0,0h0.24V45.1H0.43v48.394c0,0.838-0.032,1.906-0.068,3.174c-0.482,17.25-1.76,63.066,32.494,98.293
		c16.068,16.524,37.531,28.014,64.092,34.422c-7.996-14.25-14.22-30.182-18.103-47.816C71.846,177.74,65.746,173.221,60.625,167.955
		z"
      />
    </g>
  </svg>
);

export const ImgBrokenImage = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 1024 1024"
    fill="#fff"
    stroke="#000"
    overflow="hidden"
    {...props}
  >
    <path
      d="M896 213.333333v281.6l-128-128-170.666667 170.666667-170.666666-170.666667-170.666667 
    170.666667-128-128V213.333333c0-46.933333 38.4-85.333333 85.333333-85.333333h597.333334c46.933333 
    0 85.333333 38.4 85.333333 85.333333z m-128 273.066667l128 128V810.666667c0 46.933333-38.4 85.333333-85.333333 
    85.333333H213.333333c-46.933333 0-85.333333-38.4-85.333333-85.333333V529.066667l128 128 170.666667-170.666667 
    170.666666 170.666667 170.666667-170.666667z"
    />
  </svg>
);

export const ImgNoNetwork = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="32px"
    height="32px"
    viewBox="0 0 256 256"
    fill="#fff"
    stroke="#fff"
    overflow="hidden"
    {...props}
  >
    <g fill="#fff" fillRule="nonzero" stroke="none">
      <g transform="scale(8,8)">
        <path d="M3.70703,2.29297l-1.41406,1.41406l9.0625,9.0625l1.57226,1.57227l2.57617,2.57617l2.23828,2.23828l10.55078,10.55078l1.41406,-1.41406l-8.44531,-8.44531l0.49609,-0.49414c-0.81149,-0.84435 -1.80743,-1.50225 -2.91797,-1.92773l-3.50781,-3.50781c0.22127,-0.01401 0.44305,-0.02539 0.66797,-0.02539c3.086,0 5.87891,1.28084 7.87891,3.33984l1.41016,-1.41406c-2.359,-2.419 -5.64806,-3.92578 -9.28906,-3.92578c-0.83963,0 -1.65805,0.08557 -2.45117,0.24219l-2.43945,-2.43945c1.54354,-0.5058 3.17966,-0.80273 4.89063,-0.80273c4.465,0 8.50816,1.84369 11.41016,4.80469l1.41406,-1.41406c-3.265,-3.325 -7.80822,-5.39062 -12.82422,-5.39062c-2.28372,0 -4.4661,0.43364 -6.47656,1.2168zM5.82031,10.0625c-0.951,0.656 -1.83753,1.3987 -2.64453,2.2207l1.41406,1.41406c0.807,-0.824 1.71069,-1.54955 2.67969,-2.18555zM9.44531,13.6875c-1.003,0.591 -1.92638,1.30286 -2.73437,2.13086l1.41016,1.41406c0.81,-0.834 1.75402,-1.53312 2.79102,-2.07812zM13.17773,17.41992c-1.117,0.425 -2.11955,1.08559 -2.93555,1.93359l1.41406,1.41016c0.826,-0.866 1.90609,-1.49014 3.12109,-1.74414zM16,21.89258c-0.883,0 -1.67175,0.39109 -2.21875,0.99609l2.21875,2.21875l2.21875,-2.21875c-0.547,-0.605 -1.33575,-0.99609 -2.21875,-0.99609z"></path>
      </g>
    </g>
  </svg>
);

export const ImgNetwork = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="32px"
    height="32px"
    viewBox="0 0 512 512"
    fill="#fff"
    stroke="#fff"
    overflow="hidden"
    {...props}
  >
    <style type="text/css"></style>
    <g>
      <path
        d="M0,180.934l64.007,63.995c105.847-105.846,278.138-105.846,383.997,0L512,180.934
		C370.809,39.768,141.178,39.768,0,180.934z"
      />
      <path
        d="M95.998,276.947l63.996,63.996c52.93-52.93,139.069-52.93,191.999,0L416,276.947
		C327.751,188.685,184.262,188.685,95.998,276.947z"
      />
      <path d="M191.998,372.934l64.008,64.007l63.996-64.007C284.669,337.614,227.318,337.614,191.998,372.934z" />
    </g>
  </svg>
);

export const ImgChronometer = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="32px"
    height="32px"
    viewBox="0 0 423.117 423.117"
    fill="#fff"
    stroke="#fff"
    overflow="hidden"
    {...props}
  >
    <g>
      <path
        d="M367.698,152.124c6.803,0,13.197-2.648,18.007-7.458c4.811-4.811,7.46-11.205,7.46-18.008s-2.649-13.197-7.46-18.007
		l-25.209-25.21c-4.81-4.811-11.204-7.459-18.007-7.459s-13.197,2.648-18.007,7.459c-9.13,9.129-9.858,23.52-2.199,33.497
		l-5.997,5.62c-21.426-17.122-47.094-29.146-75.156-34.201V75.542c20.176-0.756,36.359-17.396,36.359-37.753
		C277.489,16.952,260.537,0,239.7,0h-57.117c-20.837,0-37.789,16.952-37.789,37.789c0,20.357,16.183,36.997,36.359,37.753v12.815
		c-28.308,5.1-54.178,17.291-75.716,34.652l-5.162-5.363c8.251-9.993,7.706-24.857-1.64-34.205
		c-4.81-4.811-11.206-7.459-18.008-7.459s-13.197,2.648-18.007,7.459l-25.209,25.21c-4.81,4.81-7.459,11.204-7.459,18.007
		s2.649,13.197,7.459,18.007c4.81,4.811,11.206,7.459,18.008,7.459c5.755,0,11.216-1.903,15.675-5.4l5.625,5.845
		c-21.508,28.32-34.293,63.61-34.293,101.834c0,93.029,75.686,168.715,168.716,168.715c93.03,0,168.715-75.686,168.715-168.715
		c0-38.49-12.965-74.004-34.745-102.424l6.211-5.821C355.905,150.018,361.638,152.124,367.698,152.124z M349.857,254.402
		c0,76.487-62.228,138.715-138.715,138.715c-76.488,0-138.716-62.228-138.716-138.715c0-76.488,62.228-138.716,138.716-138.716
		C287.63,115.687,349.857,177.914,349.857,254.402z"
      />
      <path
        d="M211.142,147.44c-58.979,0-106.962,47.983-106.962,106.962s47.983,106.961,106.962,106.961s106.961-47.982,106.961-106.961
		S270.121,147.44,211.142,147.44z M267.975,224.569l-35.322,30.934c-0.261,5.146-2.333,10.217-6.263,14.147
		c-8.421,8.421-22.075,8.421-30.496,0c-8.421-8.422-8.421-22.075,0-30.496c4.659-4.659,10.918-6.729,17.008-6.232l35.307-30.921
		c6.233-5.457,15.708-4.831,21.167,1.401C274.834,209.635,274.206,219.111,267.975,224.569z"
      />
    </g>
  </svg>
);

export const ImgReleaseNote = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="-3 0 24 24"
    fill="#fff"
    stroke="fff"
    {...props}
  >
    <g id="SVGRepo_iconCarrier">
      <path d="m9.47368421 4.15789474v2.10526315h-7.36842105v12.86315791h12.63157894v-7.6h2.1052632v7.368421c0 1.1578948-.9473685 2.1052632-2.1052632 2.1052632h-12.63157894c-1.15789474 0-2.10526316-.9473684-2.10526316-2.1052632v-12.63157891c0-1.15789473.94736842-2.10526315 2.10526316-2.10526315zm0 10.52631576v2.1052632h-5.26315789v-2.1052632zm3.15789469-3.1578947v2.1052631h-8.42105258v-2.1052631zm0-3.15789475v2.10526315h-8.42105258v-2.10526315zm3.1578948-5.26315789.9868421 2.17105263 2.1710526.9868421-2.1710526.98684211-.9868421 2.17105263-.9868421-2.17105263-2.1710527-.98684211 2.1710527-.9868421zm-3.1578948-2.10526316.6578948 1.44736842 1.4473684.65789474-1.4473684.65789473-.6578948 1.44736843-.6578947-1.44736843-1.4473684-.65789473 1.4473684-.65789474z" />
    </g>
  </svg>
);

export const ImgTwoStars = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="-3 0 24 24"
    fill="#fff"
    {...props}
  >
    <path d="M7.45284 2.71266C7.8276 1.76244 9.1724 1.76245 9.54716 2.71267L10.7085 5.65732C10.8229 5.94743 11.0526 6.17707 11.3427 6.29148L14.2873 7.45284C15.2376 7.8276 15.2376 9.1724 14.2873 9.54716L11.3427 10.7085C11.0526 10.8229 10.8229 11.0526 10.7085 11.3427L9.54716 14.2873C9.1724 15.2376 7.8276 15.2376 7.45284 14.2873L6.29148 11.3427C6.17707 11.0526 5.94743 10.8229 5.65732 10.7085L2.71266 9.54716C1.76244 9.1724 1.76245 7.8276 2.71267 7.45284L5.65732 6.29148C5.94743 6.17707 6.17707 5.94743 6.29148 5.65732L7.45284 2.71266Z" />
    <path d="M16.9245 13.3916C17.1305 12.8695 17.8695 12.8695 18.0755 13.3916L18.9761 15.6753C19.039 15.8348 19.1652 15.961 19.3247 16.0239L21.6084 16.9245C22.1305 17.1305 22.1305 17.8695 21.6084 18.0755L19.3247 18.9761C19.1652 19.039 19.039 19.1652 18.9761 19.3247L18.0755 21.6084C17.8695 22.1305 17.1305 22.1305 16.9245 21.6084L16.0239 19.3247C15.961 19.1652 15.8348 19.039 15.6753 18.9761L13.3916 18.0755C12.8695 17.8695 12.8695 17.1305 13.3916 16.9245L15.6753 16.0239C15.8348 15.961 15.961 15.8348 16.0239 15.6753L16.9245 13.3916Z" />
  </svg>
);
