export enum ACTION_ITEM {
  MENU = "MENU",
  CANCEL_LAST_SCORE = "CANCEL_LAST_SCORE",
  UNLOCK_EXTRA_THROW = "UNLOCK_EXTRA_THROW",
}

export enum MENU_ITEM {
  INFOS = "INFOS",
  HELP = "HELP",
  RULES = "RULES",
  SETTINGS = "SETTINGS",
  REPLAY = "REPLAY",
  GIVE_UP = "GIVE_UP",
}

export enum ERROR_TYPE {
  GLOBAL = "global",
  ACTION_ITEM = "action",
}

export enum NOTIF_TYPE {
  INFO = "info",
  WARN = "warn",
  ERROR = "error",
  SUCCESS = "success",
}

export enum SETTING_KEY {
  CLICK_ON_DICE_TO_PRESERVE = "CLICK_ON_DICE_TO_PRESERVE",
  SHOW_INDICATOR_ON_DICE = "SHOW_INDICATOR_ON_DICE",
  ENABLE_VIBRATION_ON_DICE = "ENABLE_VIBRATION_ON_DICE",
  ENABLE_VIBRATION_ON_SCORESHEET = "ENABLE_VIBRATION_ON_SCORESHEET",
  ENABLE_VIBRATION_ON_DICE_THROW_BUTTON = "ENABLE_VIBRATION_ON_DICE_THROW_BUTTON",
  SHOW_TIMER = "SHOW_TIMER",
  SHOW_PENDING_SCORES = "SHOW_PENDING_SCORES",
  SHOW_BONUS_INDICATOR = "SHOW_BONUS_INDICATOR",
}

/* Tutorial */
export enum TUTORIAL_KEY {
  SHOW_TUTO_1_THROW_DICE = "SHOW_TUTO_1_THROW_DICE",
  SHOW_TUTO_2_PRESERVE_DICE = "SHOW_TUTO_2_PRESERVE_DICE",
  SHOW_TUTO_3_SCORE_SHEET = "SHOW_TUTO_3_SCORE_SHEET",
  SHOW_TUTO_4_CANCEL_SCORE = "SHOW_TUTO_4_CANCEL_SCORE",
  SHOW_TUTO_5_EXTRA_THROW = "SHOW_TUTO_5_EXTRA_THROW",
  SHOW_TUTO_6_MENU = "SHOW_TUTO_6_MENU",
}

//* New features */
export enum NEW_FEATURE_KEY {
  SHOW_SHARE_GAME = "SHOW_SHARE_GAME",
}
