import { RESULT_KEY } from "../enums";
import { Game, ScoreSheet } from "../types";

export interface GameToSaveDto {
  date: string;
  timer: number;
  score: number;
  scoreSheet: ScoreSheet;
}

export const buildGameToSaveDto = (game: Game): GameToSaveDto => {
  return {
    date: game.date,
    timer: game.timer,
    score: game.scoreSheets[0].cells.find((c) => c.rowKey === RESULT_KEY.GRAND_TOTAL)?.value ?? -1,
    scoreSheet: game.scoreSheets[0],
  };
};
