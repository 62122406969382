/* eslint-disable @typescript-eslint/no-explicit-any */
/*** DEPRECATED ***/
const API_URL = "/api";
const HEADERS = {
  ACCEPT: {
    JSON: "application/json",
  },
  CONTENT_TYPE: {
    JSON: "application/json",
  },
};

export enum METHODS {
  GET = "GET",
  PUT = "PUT",
  POST = "POST",
  DELETE = "DELETE",
}

type ApiError = {
  message: string;
};
// type ApiData = {

// };
type ApiNotif = {
  type: string;
  message: string;
};

export type ApiCallback = (error?: ApiError, data?: unknown, notif?: ApiNotif) => void;

export const fetchApi = (
  _url: string,
  _method: METHODS,
  _params: Record<string, string | number> | FormData | null,
  _headers: Record<string, string> | null,
  _callback: ApiCallback,
): void => {
  // method
  const method = _method || METHODS.GET;
  // url
  const url =
    method === METHODS.GET && _params && !(_params instanceof FormData)
      ? _url +
        "?" +
        Object.keys(_params)
          .map((k) => k + "=" + _params[k])
          .join("&")
      : _url;
  // headers
  const headers = _headers ? { ..._headers } : { "Content-Type": HEADERS.CONTENT_TYPE.JSON };
  // params
  const body = _params instanceof FormData ? _params : JSON.stringify(_params || {});
  // process request
  fetch(`${API_URL}${url}`, {
    method,
    headers,
    // body,
    body,
  })
    .then((res) => {
      try {
        return res.json();
      } catch (err: any) {
        return _callback({ message: err });
      }
    })
    .then((res) => {
      if (res.status === "KO") {
        return _callback(res.error || "Erreur iconnue");
      }
      return _callback(undefined, res.data, res.notif);
    })
    .catch((err) => {
      return _callback({ message: err });
    });
};
