import React from "react";
import { GAME_STATUS } from "../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { startGame } from "../../../../redux/features/game/gameSlice";

import { SETTING_KEY } from "../../../../common/enums";

import GameModeSelection from "./gameModeSelection";
import GameTimer from "./gameTimer";
import GameHistory from "./gameHistory";
import GameBoard from "./gameBoard";
import GameActions from "./gameActions";
import GameAdditionalThrows from "./gameAdditionalThrows";
import NewGameAction from "./newGameAction";
import { Button } from "../../../../components/forms";
import { Separation } from "../../../../components/ui";

import { ImgPlay } from "../../../../common/img/common";
import { gameBoardFirstRoundInfoLines } from "../../../../common/messages/game";

import "./gameTable.css";

const TableSeparation = <Separation size="66%" margin="16px" />;

const GameTable = (): JSX.Element => {
  const { status, mode, rule, scoreSheets, gameBoard, history } = useAppSelector((state) => state.game);
  const settings = useAppSelector((state) => state.settings);
  const currentScoreSheet = scoreSheets.find((s) => s.isCurrent === true);
  const mustClickOnDiceToPreserve = settings[SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE];

  const dispatch = useAppDispatch();

  return (
    <div className="gameTable_container">
      {/*
        Game INITIAL
    */}
      {status === GAME_STATUS.INITIAL ? (
        <div className="game_mode_selection">
          {/* Game mode selection  */}
          <GameModeSelection />

          <Separation />

          {/* Start game button */}
          <Button
            className="start_game_button"
            img={<ImgPlay />}
            title="Jouer"
            disabled={!mode}
            onClick={() => dispatch(startGame())}
          />
        </div>
      ) : (
        /*
      Game in progress or over
    */
        <div className="gameTable">
          {/* Timer */}
          <GameTimer />

          {/* History */}
          {history.scores.length > 0 && (
            <>
              {TableSeparation}
              <GameHistory />
            </>
          )}

          {status !== GAME_STATUS.OVER ? (
            /* Info - Current player */
            currentScoreSheet &&
            scoreSheets.length > 1 && (
              <>
                {TableSeparation}
                <div className="gameTable_info player">à {currentScoreSheet.playerName} de jouer !</div>
              </>
            )
          ) : (
            /* Info - Game over and New game button */
            <>
              {TableSeparation}
              <div className="gameTable_info game_over">Partie terminée</div>
              <NewGameAction />
              {gameBoard && TableSeparation}
            </>
          )}

          {/* Game board */}
          {gameBoard && (
            <>
              {/* Info - game board first round */}
              {history.scores.length === 0 && (
                <>
                  {TableSeparation}
                  <div className="gameTable_info first_round">
                    {gameBoardFirstRoundInfoLines(gameBoard, mustClickOnDiceToPreserve).map((line, i) => (
                      <span key={i}>{line}</span>
                    ))}
                  </div>
                </>
              )}
              <GameBoard className="gameTable_game_board" />
            </>
          )}

          {/* Actions */}
          {currentScoreSheet && (
            <>
              {!gameBoard && TableSeparation}
              <GameActions currentScoreSheet={currentScoreSheet} />
            </>
          )}

          {/* Additional throws */}
          {rule.numberOfAdditionalThrows > 0 && (
            <>
              {TableSeparation}
              <GameAdditionalThrows />
            </>
          )}

          {/* if not Game over : New game button */}
          {status !== GAME_STATUS.OVER && (
            <>
              {TableSeparation}
              <NewGameAction />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default GameTable;
