/**
 * Return a random player name which is not already existing
 */
export const randomPlayerName = (excludedNames: string[] = []): string => {
  if (excludedNames) {
    const funnyNames = [
      "Toto",
      "Batman",
      "Chuck Norris",
      "Juda Nanass",
      "Juda Bricot",
      "Zlatan",
      "Patate",
      "Don Piano",
      "Tarzan",
      "Kadoc",
      "Bender",
      "Petit papa Noël",
      "Pikachu",
      "Mitch Buchannon",
      "Coco l'asticot",
    ];
    const availables = [];
    for (let i = 0; i < funnyNames.length; i++) {
      const name = funnyNames[i];
      let foundName = false;
      for (let j = 0; j < excludedNames.length; j++) {
        if (!foundName) {
          foundName = name === excludedNames[j];
        }
      }
      if (!foundName) {
        availables.push(name);
      }
    }
    if (availables.length > 0) {
      return availables[Math.floor(Math.random() * availables.length)];
    }
    return "Joueur " + excludedNames.length + 1;
  }
  return "Toto";
};
