import React from "react";

import { useAppSelector } from "../../../../../redux/hooks";

import { TopScore } from "../../../../../common/types";
import { Avatar } from "../../../../../components/forms";

import "./scoreRow.css";

interface ScoreRowProps {
  topScore: TopScore;
}
const ScoreRow = ({ topScore }: ScoreRowProps): JSX.Element => {
  const userPseudo = useAppSelector((state) => state.user.pseudo);

  const { rank, user, score } = topScore;
  return (
    <div className="topScores_scoreRow">
      {rank <= 3 ? (
        // rank in Avatar
        <>
          <div className="topScores_scoreRow_avatar">
            <Avatar src={user.avatar} pseudo={user.pseudo} />
            <span className={`topScores_scoreRow_rank_in_avatar rank_${rank}`}>{rank}</span>
          </div>
        </>
      ) : (
        // rank
        <span className="topScores_scoreRow_rank">
          {rank}
          {"."}
        </span>
      )}
      {/* pseudo */}
      <div className={`topScores_scoreRow_pseudo${user.pseudo === userPseudo ? " isUser" : ""}`}>
        <span>{user.pseudo}</span>
      </div>
      {/* score */}
      <span className="topScores_scoreRow_score">{score}</span>
    </div>
  );
};

export default ScoreRow;
