import React, { useEffect, useState } from "react";
import { GAME_STATUS } from "../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { saveGameOnAccount } from "../../../../redux/features/game/gameSlice";

import { MyamsPet } from "../../../../common/img/myamsPet/myamsPet";
import { ImgWarning } from "../../../../common/img/common";
import { MESSAGES } from "../../../../common/messages/userAccount";
import { Avatar } from "../../../../components/forms";
import { Loader } from "../../../../components/ui";

import AccountLogin from "../../account/accountLogin";
import MyAccount from "../../account/myAccount";

import "./accountBar.css";

let _bubbleInterval: string | number | NodeJS.Timeout | undefined;
let _helloPseudoTimeout: string | number | NodeJS.Timeout | undefined;

const PROMPT_LOGIN_TEXT = "Connectez-vous à votre compte afin de concourir pour les Top Scores !";
const GAME_SAVED_FIRST_TIME_TEXT =
  "Votre score a bien été enregistré. Il le sera à chaque fin de partie si vous êtes connecté à votre compte.";

interface AccountBarProps {
  className?: string;
  gameStatus: GAME_STATUS;
}
const AccountBar = ({ className, gameStatus }: AccountBarProps): JSX.Element => {
  const { pseudo, avatar, isActivated, con } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  // bar elems
  const [bubbleText, setBubbleText] = useState<string>();
  const [inGameBubbleText, setInGameBubbleText] = useState<string>();
  const [isInGame, setIsInGame] = useState<boolean>(false);
  const [showAvatarPseudo, setShowAvatarPseudo] = useState<boolean>(false);
  // modals
  const [showAccountLogin, setShowAccountLogin] = useState<boolean>(false);
  const [showMyAccount, setShowMyAccount] = useState<boolean>(false);

  const onClickBar = () => {
    if (con.isLogged) {
      setShowMyAccount(true);
    } else {
      setShowAccountLogin(true);
    }
  };

  const onEvent = (
    evt:
      | "game-init"
      | "prompt-login"
      | "logged-in"
      | "back-to-home"
      | "game-started"
      | "game-over-first-time-logged-in"
      | "game-over"
      | "game-saved-first-time"
      | "game-saved",
  ) => {
    // console.log(">>>>>> EVENT ", evt);
    // console.log("> con.isLogged", con.isLogged);
    // console.log("> gameStatus", gameStatus);
    // console.log("> isInGame", isInGame);

    // come clears
    clearInterval(_bubbleInterval);
    clearTimeout(_helloPseudoTimeout);
    setInGameBubbleText(undefined);

    switch (evt) {
      case "game-init": {
        if (isInGame) {
          onEvent("back-to-home");
        } else if (!con.isLogged) {
          onEvent("prompt-login");
        } else {
          onEvent("logged-in");
        }
        break;
      }
      case "prompt-login": {
        setShowAvatarPseudo(false);
        setIsInGame(false);
        writeBubbleText(PROMPT_LOGIN_TEXT, () => {
          if (gameStatus !== GAME_STATUS.INITIAL) {
            setTimeout(() => {
              setBubbleText(!con.isLogged ? "Me connecter" : undefined);
              setIsInGame(true);
            }, 2000);
          }
        });
        break;
      }
      case "logged-in": {
        const extraMessage = isActivated === false && con.isLogged ? `. ${MESSAGES.ACCOUNT_NOT_ACTIVATED}.` : "";
        writeBubbleText(`Bonjour ${pseudo}${extraMessage}`, () => {
          _helloPseudoTimeout = setTimeout(() => {
            setShowAvatarPseudo(true);
          }, 2000);
        });
        break;
      }
      case "back-to-home": {
        if (!con.isLogged) {
          setBubbleText(PROMPT_LOGIN_TEXT);
        } else {
          setShowAvatarPseudo(true);
        }
        setIsInGame(false);
        break;
      }
      case "game-started": {
        setShowAvatarPseudo(con.isLogged);
        setBubbleText(!con.isLogged ? "Me connecter" : undefined);
        setIsInGame(true);
        break;
      }
      case "game-over-first-time-logged-in": {
        dispatch(
          saveGameOnAccount((isSaved) => {
            if (isSaved) {
              onEvent("game-saved-first-time");
            } else {
              setShowAvatarPseudo(con.isLogged);
              setIsInGame(true);
              setBubbleText(!con.isLogged ? "Me connecter" : undefined);
            }
          }),
        );
        break;
      }
      case "game-over": {
        if (!con.isLogged) {
          onEvent("prompt-login");
        } else if (con.isLogged) {
          dispatch(
            saveGameOnAccount((isSaved) => {
              if (isSaved) {
                onEvent("game-saved");
              } else {
                setShowAvatarPseudo(con.isLogged);
                setIsInGame(true);
                setBubbleText(!con.isLogged ? "Me connecter" : undefined);
              }
            }),
          );
        }
        break;
      }
      case "game-saved-first-time": {
        const extraMessage = `. ${GAME_SAVED_FIRST_TIME_TEXT}`;
        writeBubbleText(
          `Bonjour ${pseudo}${extraMessage}`,
          () => {
            _helloPseudoTimeout = setTimeout(() => {
              setShowAvatarPseudo(true);
              setIsInGame(true);
              setInGameBubbleText(undefined);
            }, 3700);
          },
          true,
        );
        break;
      }
      case "game-saved": {
        writeBubbleText(
          "Votre score a bien été enregistré  ✔",
          () => {
            _helloPseudoTimeout = setTimeout(() => {
              setInGameBubbleText(undefined);
            }, 2000);
          },
          true,
        );
        break;
      }
    }
  };

  const writeBubbleText = (str: string, cb?: () => void, forceInGame?: boolean) => {
    clearInterval(_bubbleInterval);
    if (forceInGame) {
      setInGameBubbleText("");
    } else {
      setBubbleText("");
    }

    let text = "";
    let index = 0;
    _bubbleInterval = setInterval(() => {
      if (index >= str.length) {
        clearInterval(_bubbleInterval);
      } else {
        if (forceInGame) {
          setInGameBubbleText((text += str[index]));
        } else {
          setBubbleText((text += str[index]));
        }
        index++;
        if (index >= str.length && cb) {
          cb();
        }
      }
    }, 50);
  };

  useEffect(() => {
    // console.log("useeffect gameStatus", gameStatus, "(con.isLogged", con.isLogged, ")");
    switch (gameStatus) {
      case GAME_STATUS.INITIAL:
        onEvent("game-init");
        break;
      case GAME_STATUS.ONGOING:
        onEvent("game-started");
        break;
      case GAME_STATUS.OVER:
        onEvent("game-over");
    }
  }, [gameStatus]);

  useEffect(() => {
    // console.log("useeffect con.isLogged ", con.isLogged, "(gameStatus", gameStatus, ")");
    if (gameStatus === GAME_STATUS.INITIAL) {
      onEvent("game-init");
    } else if (gameStatus === GAME_STATUS.ONGOING) {
      onEvent("game-started");
    } else if (gameStatus === GAME_STATUS.OVER) {
      onEvent("game-over-first-time-logged-in");
    }
  }, [con.isLogged]);

  const AccountCircle = (): JSX.Element => {
    if (con.isLogging || con.isLoggingOut) {
      return <Loader className="account_circle" color="#fff" />;
    }
    if (showAvatarPseudo) {
      return <Avatar className="account_circle" src={avatar} pseudo={pseudo} />;
    }
    return (
      <div className="account_circle">
        <MyamsPet color="#fff" width={46} height={46} sayHello={true} />
      </div>
    );
  };
  const BubbleText = (): JSX.Element => {
    let textToShow = showAvatarPseudo && pseudo ? pseudo : bubbleText;
    if (inGameBubbleText) textToShow = inGameBubbleText;
    return (
      <div className={`account_bubble_text${showAvatarPseudo && pseudo ? " isPseudo" : ""}`}>
        {((!showAvatarPseudo && !isInGame) || inGameBubbleText) && <div className="account_bubble_text_anchor"></div>}
        {textToShow}
      </div>
    );
  };

  return (
    <>
      <div
        className={
          "account_bar_container" +
          `${className ? ` ${className}` : ""}` +
          `${isInGame ? " in-game" : ""}` +
          `${inGameBubbleText ? " in-game-bubble-text" : ""}`
        }
      >
        {/* TEST */}
        {/* {gameStatus === GAME_STATUS.ONGOING && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              // backgroundColor: "red",
              paddingRight: "4px",
              marginLeft: "-6px",
              maxWidth: "48px",
              maxHeight: "30px",
              // borderRight: "1px solid #aaa",
              zIndex: 30,
            }}
            onClick={() => {
              window.alert("gregreg");
            }}
          >
            <ImgArrowLeft fill="#fff" width="30px" height="30px" />
          </div>
        )} */}

        {/* Account Bar */}
        <div className="account_bar_content" onClick={() => onClickBar()}>
          {/* Avatar/Myams Pet */}
          {AccountCircle()}
          {/* Bubble text / pseudo */}
          {BubbleText()}
          {/* account warning */}
          {showAvatarPseudo && pseudo && !isActivated && <ImgWarning className="account_bubble_warning" />}
        </div>
      </div>

      {/* Login */}
      <AccountLogin
        visible={showAccountLogin}
        onClose={() => setShowAccountLogin(false)}
        onLoggedIn={() => setShowAccountLogin(false)}
        onRegistered={() => setShowAccountLogin(false)}
      />

      {/* My account */}
      <MyAccount
        visible={showMyAccount}
        onClose={() => setShowMyAccount(false)}
        onLogout={() => {
          setShowAvatarPseudo(false);
          setShowMyAccount(false);
        }}
      />
    </>
  );
};

export default AccountBar;
