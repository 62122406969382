import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../configureStore";

import { Ui } from "../../common/types";

const initialState: Ui = {
  // errors: [],
  notification: {},
  // desktop only
  zoom: {
    minLevel: 1,
    maxLevel: 10,
    defaultLevel: 5,
    level: 5,
    computedLevel: 1,
    diff: 0.04,
  },
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    _updateZoom: (state, action: PayloadAction<Record<string, number>>) => {
      state.zoom = { ...state.zoom, ...action.payload };
    },
  },
});

export default uiSlice.reducer;

const { _updateZoom } = uiSlice.actions;

/* Zoom (desktop only) */
export const zoomOut = (): AppThunk => {
  return (dispatch, getState) => {
    const { zoom } = getState().ui;
    const { defaultLevel, level, diff } = zoom;
    const newLevel = level - 1;
    const newComputedLevel = 1 - (defaultLevel - newLevel) * diff;

    // update zoom
    dispatch(_updateZoom({ level: newLevel, computedLevel: newComputedLevel }));
  };
};
export const zoomIn = (): AppThunk => {
  return (dispatch, getState) => {
    const { zoom } = getState().ui;
    const { defaultLevel, level, diff } = zoom;
    const newLevel = level + 1;
    const newComputedLevel = 1 - (defaultLevel - newLevel) * diff;

    // update zoom
    dispatch(_updateZoom({ level: newLevel, computedLevel: newComputedLevel }));
  };
};
