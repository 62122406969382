import React from "react";

import { useAppSelector } from "../../../redux/hooks";

import HeaderActions from "./headerActions/HeaderActions";
// import HeaderMyAccount from "./headerMyAccount";

import "./header.css";

const Header = (): JSX.Element => {
  const zoom = useAppSelector((state) => state.ui.zoom.computedLevel);

  return (
    <div className="app_header" style={{ zoom }}>
      <HeaderActions />
      {/* <HeaderMyAccount /> */}
    </div>
  );
};

export default Header;
