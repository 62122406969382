import { ROW_TYPE, COL_KEY, SCORE_KEY, BONUS_KEY, RESULT_KEY, ROW_SECTION, INDICATOR_KEY } from "../core/enums";
import { randomPlayerName } from "./playerUtils";
import {
  isNum,
  getCell,
  getRandomEmptyScoreCell,
  getPreviousScoreCell,
  getNextScoreCell,
  computeSubTotal,
  computeTotal_2,
  computeGrandTotal,
  computeBonusIndicator,
} from "./cellUtils";
import {
  sumOfTheValues,
  hasThreeOfAKind,
  hasFourOfAKind,
  hasFullHouse,
  hasSmallStraight,
  hasLargeStraight,
  hasYams,
  getLowChanceValue,
  getHighChanceValue,
  hasUnderEleven,
  hasOnlyPairs,
} from "./scoreUtils";
import { Cell, Dice, GameRule, ScoreSheet } from "../core/types";
import { SCORE } from "../core/const";

const ErrorMessages = (...args: (string | number | undefined)[]): Record<string, string> => ({
  SCORE_MUST_BE_A_NUMBER: "Le score doit être un nombre.",
  SCORE_MUST_BE_OTHER_THAN: `Veuillez saisir un score autre que ${args[0]}`,
  SCORE_MUST_BE_IN: `Veuillez saisir un score parmi ${args[0]}`,
  SCORE_MUST_BE: `Votre score ici ne peut être que ${args[0]}`,
  OR: ` ou ${args[0]}`,
  SCORE_MUST_BE_BETWEEN: `Veuillez saisir un score entre ${args[0]} et ${args[1]}`,
  OR_EQUAL_TO: ` ou égal à ${args[0]}`,
});

/**
 * Generates a empty score sheet (whith player name) based on the given game rule and optional existing score sheets.
 *
 * @param {GameRule} rule - The game rule object that defines the template for the score sheet.
 * @param {ScoreSheet[]} [scoreSheets] - An optional array of existing score sheets.
 * @return {ScoreSheet} - The generated score sheet.
 */
export const generateScoreSheet = (rule: GameRule, scoreSheets?: ScoreSheet[]): ScoreSheet => {
  // generate scoreSheetId
  const scoreSheetId = (scoreSheets ?? []).reduce((max, s) => Math.max(max, s.id), 0) + 1;
  // empty cols and rows based on template
  const { cols, rows } = rule.template;
  // compute cells from rows and cols
  const cells: Cell[] = [];
  rows.forEach((row) => {
    const { key, type, diceValue, allowedScores, minNumberOfDiceForBonus, bonusValue } = row;
    if (type !== ROW_TYPE.SEPARATION) {
      if (key === RESULT_KEY.GRAND_TOTAL) {
        cells.push({
          scoreSheetId,
          rowKey: key,
          colKey: "ALL_IN_ONE",
          type,
        });
      } else {
        cols.forEach((col) => {
          let cell: Cell = {
            scoreSheetId,
            rowKey: key,
            colKey: col.key,
            type,
          };
          if (type === ROW_TYPE.SCORE) {
            cell = {
              ...cell,
              section: row.section,
              diceValue,
              allowedScores,
              isLocked: true,
              isScoring: false,
              error: "",
            };
          }
          if (type === ROW_TYPE.BONUS) {
            cell.bonusValue = bonusValue;
          }
          if (key === INDICATOR_KEY.BONUS_INDICATOR) {
            cell.minNumberOfDiceForBonus = minNumberOfDiceForBonus;
          }
          cells.push(cell);
        });
      }
    }
  });
  return {
    id: scoreSheetId,
    playerName: randomPlayerName(scoreSheets?.map((s) => s.playerName) ?? []),
    isCurrent: false,
    canUseYamsBonus: false,
    cols,
    rows,
    cells,
  };
};

/**
 * Initializes the score cells in the given score sheet to the start of the game.
 *
 * @param {ScoreSheet} scoreSheet - The score sheet to initialize the cells in.
 * @return {Cell[]} - An array of the initialized score cells.
 */
const initScoreCells = (scoreSheet: ScoreSheet): Cell[] => {
  const scoreRows = scoreSheet.rows.filter((row) => row.type === ROW_TYPE.SCORE);
  const randomRowKey = scoreSheet.cols.find((col) => col.key === COL_KEY.RANDOM)
    ? scoreRows[Math.floor(Math.random() * scoreRows.length)].key
    : undefined;

  return scoreSheet.cells.map((cell) => {
    const { type, colKey, rowKey } = cell;
    // ignore if not score cell
    if (type !== ROW_TYPE.SCORE) {
      return cell;
    }
    // unlock one score cell per col
    else if (
      colKey === COL_KEY.FREE ||
      (colKey === COL_KEY.FROM_TOP_TO_BOTTOM && rowKey === scoreRows[0].key) ||
      (colKey === COL_KEY.FROM_BOTTOM_TO_TOP && rowKey === scoreRows[scoreRows.length - 1].key) ||
      (colKey === COL_KEY.RANDOM && rowKey === randomRowKey)
    ) {
      return { ...cell, isLocked: false };
    }
    // lock all others score cells
    return { ...cell, isLocked: true };
  });
};

/**
 * Initializes the score sheets for the start of the game
 *
 * @param {ScoreSheet[]} scoreSheets - The array of score sheets.
 * @return {ScoreSheet[]} - The updated array of score sheets.
 */
export const initScoreSheets = (scoreSheets: ScoreSheet[]): ScoreSheet[] => {
  const firstScoreSheet = scoreSheets[0];
  return scoreSheets.map((scoreSheet) => ({
    ...scoreSheet,
    isCurrent: scoreSheet.id === firstScoreSheet.id,
    cells: initScoreCells(scoreSheet),
  }));
};

/**
 * Checks the validity of a score, and return error message if score is invalid.
 *
 * @param {Cell} cell - The cell to check the score value for.
 * @param {ScoreSheet} scoreSheet - The score sheet from which to check.
 * @param {GameRule} rule - The game rule
 * @param {string | number} scoreValue - The score value to check.
 * @return {string | undefined} - An error message if the score value is invalid, otherwise undefined.
 */
export const checkScoreValidity = (
  cell: Cell,
  scoreSheet: ScoreSheet,
  rule: GameRule,
  scoreValue?: string | number,
): string | undefined => {
  if (!isNum(scoreValue)) {
    return ErrorMessages().SCORE_MUST_BE_A_NUMBER;
  }
  const score = typeof scoreValue === "string" ? parseInt(scoreValue, 10) : (scoreValue as number);

  let error;
  const { numberOfDice, numberOfDiceFaces } = rule;
  const { allowedScores } = cell;
  const forbiddenScores = [];
  // compute forbiddenScores
  if (!allowedScores && numberOfDice) {
    for (let i = 1; i < numberOfDice; i++) {
      forbiddenScores.push(i);
    }
  }
  let minScore = 0;
  let maxScore = numberOfDiceFaces * numberOfDice;
  let zeroAllowedIfMinHigher = false;
  // compute minScore, maxScore and zeroAllowedIfMinHigher
  //  chance
  if (cell.rowKey === SCORE_KEY.CHANCE) {
    minScore = numberOfDice;
    //  chance +
  } else if (cell.rowKey === SCORE_KEY.HIGH_CHANCE) {
    const cellLowChance = getCell(scoreSheet.cells, SCORE_KEY.LOW_CHANCE, cell.colKey);
    if (cellLowChance && isNum(cellLowChance.value)) {
      const lowChanceValue = cellLowChance.value;
      if (lowChanceValue === maxScore) {
        minScore = 0;
        maxScore = 0;
      } else if (lowChanceValue !== undefined && lowChanceValue < maxScore) {
        minScore = lowChanceValue + 1;
        zeroAllowedIfMinHigher = true;
      }
    } else {
      minScore = numberOfDice;
    }
    //  chance -
  } else if (cell.rowKey === SCORE_KEY.LOW_CHANCE) {
    const cellHighChance = getCell(scoreSheet.cells, SCORE_KEY.HIGH_CHANCE, cell.colKey);
    if (cellHighChance && isNum(cellHighChance.value)) {
      maxScore = (cellHighChance.value ?? 0) - 1;
      if (maxScore < numberOfDice) {
        maxScore = 0;
      }
    } else {
      minScore = numberOfDice;
    }
  }

  // Check errors :

  // not allowed ?
  if (allowedScores && allowedScores.indexOf(score) === -1) {
    const vals = allowedScores.toString().replace(/,/g, ", ");
    error = ErrorMessages(vals).SCORE_MUST_BE_IN;
  }
  // < minScore or > maxScore ?
  else if (!allowedScores && (score < minScore || score > maxScore)) {
    if ((score === 0 && zeroAllowedIfMinHigher === false) || score !== 0) {
      if (minScore === maxScore) {
        if (zeroAllowedIfMinHigher === true) {
          error = ErrorMessages(minScore).SCORE_MUST_BE + ErrorMessages(0).OR;
        } else {
          error = ErrorMessages(0).SCORE_MUST_BE;
        }
      } else {
        error = ErrorMessages(minScore, maxScore).SCORE_MUST_BE_BETWEEN;
        if (score !== 0 && zeroAllowedIfMinHigher) {
          error += ErrorMessages(0).OR_EQUAL_TO;
        }
      }
    }
  }
  // forbidden ?
  else if (forbiddenScores && forbiddenScores.indexOf(score) !== -1) {
    const vals = forbiddenScores.toString().replace(/,/g, ", ");
    error = ErrorMessages(vals).SCORE_MUST_BE_OTHER_THAN;
  }

  return error;
};

/**
 * Calculates the score value of a cell based on the dice set in the score sheet.
 *
 * @param {Cell} cell - The cell for which to calculate the score value.
 * @param {Dice[]} dice - The array of dice.
 * @param {ScoreSheet} scoreSheet - The related score sheet.
 * @return {number} - The score value of the cell.
 */
export const getCellScoreValueFromDice = (cell: Cell, dice: Dice[], scoreSheet: ScoreSheet): number => {
  const { cells } = scoreSheet;
  const { rowKey, colKey } = cell;
  const diceValues: number[] = dice.map((d) => d.value).filter((v): v is number => v !== null);
  const scoreRow = SCORE[rowKey as SCORE_KEY] ? SCORE[rowKey as SCORE_KEY] : undefined;

  if (!scoreRow || colKey === "ALL_IN_ONE") {
    return 0;
  }
  switch (rowKey) {
    case SCORE_KEY.ACES:
    case SCORE_KEY.TWOS:
    case SCORE_KEY.THREES:
    case SCORE_KEY.FOURS:
    case SCORE_KEY.FIVES:
    case SCORE_KEY.SIXES:
      return sumOfTheValues(diceValues.filter((val) => val === scoreRow.diceValue));
    case SCORE_KEY.THREE_OF_A_KIND:
      return hasThreeOfAKind(diceValues) ? sumOfTheValues(diceValues) : 0;
    case SCORE_KEY.FOUR_OF_A_KIND:
      return hasFourOfAKind(diceValues) ? sumOfTheValues(diceValues) : 0;
    case SCORE_KEY.FULL_HOUSE:
      return hasFullHouse(diceValues) ? scoreRow.value ?? 0 : 0;
    case SCORE_KEY.SMALL_STRAIGHT:
      return hasSmallStraight(diceValues) ? scoreRow.value ?? 0 : 0;
    case SCORE_KEY.LARGE_STRAIGHT:
      return hasLargeStraight(diceValues) ? scoreRow.value ?? 0 : 0;
    case SCORE_KEY.YAMS:
      return hasYams(diceValues) ? scoreRow.value ?? 0 : 0;
    case SCORE_KEY.LOW_CHANCE: {
      const highChanceCell = getCell(cells, SCORE_KEY.HIGH_CHANCE, colKey as COL_KEY);
      const highChance = highChanceCell?.value ?? 0;
      return getLowChanceValue(diceValues, highChance);
    }
    case SCORE_KEY.HIGH_CHANCE: {
      const lowChanceCell = getCell(cells, SCORE_KEY.LOW_CHANCE, colKey as COL_KEY);
      const lowChance = lowChanceCell?.value ?? 0;
      return getHighChanceValue(diceValues, lowChance);
    }
    case SCORE_KEY.CHANCE:
      return sumOfTheValues(diceValues);
    case SCORE_KEY.UNDER_ELEVEN:
      return hasUnderEleven(diceValues) ? scoreRow.value ?? 0 : 0;
    case SCORE_KEY.ONLY_PAIRS:
      return hasOnlyPairs(diceValues) ? scoreRow.value ?? 0 : 0;
    default:
      return 0;
  }
};

/**
 * Updates the cells in the scoreSheet based on a new score value to validate in a cell.
 *
 * @param {ScoreSheet} scoreSheet - The score sheet object.
 * @param {Cell} validatedCell - The validated cell object.
 * @param {number} scoreValue - The new score value.
 * @return {Cell[]} - The updated cells.
 */
const updateCellsFromNewScore = (
  scoreSheet: ScoreSheet,
  validatedCell: Cell,
  scoreValue: number,
  withYamsBonus: boolean,
): Cell[] => {
  const { colKey, rowKey, section } = validatedCell;

  // compute the next cell to unlock, if there is one
  let cellToUnlock: Cell | undefined;
  switch (colKey) {
    case COL_KEY.FREE: {
      // on free col, neither cell wil be unnlocked
      break;
    }
    case COL_KEY.FROM_TOP_TO_BOTTOM: {
      cellToUnlock = getNextScoreCell(scoreSheet.cells, scoreSheet.rows, validatedCell);
      break;
    }
    case COL_KEY.FROM_BOTTOM_TO_TOP: {
      cellToUnlock = getPreviousScoreCell(scoreSheet.cells, scoreSheet.rows, validatedCell);
      break;
    }
    case COL_KEY.RANDOM: {
      cellToUnlock = getRandomEmptyScoreCell(
        scoreSheet.cells.filter((c) => c.rowKey !== validatedCell.rowKey),
        colKey,
      );
      break;
    }
    default:
      console.warn("updateScoreSheetCells - Règle à implémenter pour la colonne", colKey);
      break;
  }

  // update cells with new score value and next unlocked cell
  let updatedCells = scoreSheet.cells.map((cell) => {
    // on the current col
    if (colKey === cell.colKey) {
      // set new score
      if (cell.rowKey === rowKey) {
        return {
          ...cell,
          value: scoreValue,
          pendingValue: undefined,
          isScoring: false,
          error: undefined,
        };
      }
      // unlock the next cell
      else if (cellToUnlock && cell.rowKey === cellToUnlock.rowKey) {
        return {
          ...cell,
          isLocked: false,
        };
      }
      return cell;
    }
    return cell;
  });

  // TOP SECTION - compute top results (bonus, indicator, subtotal, total_1)
  const { minSubtotalRequired, bonusValue } = scoreSheet.rows.find((r) => r.key === BONUS_KEY.BONUS) || {};
  const subtotal = computeSubTotal(updatedCells, colKey as COL_KEY);
  const bonus = subtotal >= (minSubtotalRequired ?? 0) ? bonusValue ?? 0 : 0;
  const bonusIndicator = computeBonusIndicator(updatedCells, colKey as COL_KEY);
  const total_1 = subtotal + bonus;

  if (section === ROW_SECTION.TOP) {
    updatedCells = updatedCells.map((cell) => {
      if (cell.colKey === colKey) {
        switch (cell.rowKey) {
          case INDICATOR_KEY.BONUS_INDICATOR: {
            return { ...cell, value: bonusIndicator };
          }
          case RESULT_KEY.SUBTOTAL: {
            return { ...cell, value: subtotal };
          }
          case BONUS_KEY.BONUS: {
            if (
              bonus > 0 ||
              updatedCells
                .filter((c) => c.section === ROW_SECTION.TOP && c.colKey === colKey && c.type === ROW_TYPE.SCORE)
                .every((c) => c.value !== undefined)
            ) {
              return { ...cell, value: bonus };
            }
            return cell;
          }
          case RESULT_KEY.TOTAL_1: {
            return { ...cell, value: total_1 };
          }
        }
      }
      return cell;
    });
  }

  // yamsBonus
  if (withYamsBonus) {
    const yamsBonusCell = getCell(updatedCells, BONUS_KEY.YAMS_BONUS, colKey);
    if (yamsBonusCell?.bonusValue) {
      updatedCells = updatedCells.map((cell) => {
        if (cell.rowKey === yamsBonusCell.rowKey && cell.colKey === colKey) {
          return { ...cell, value: (yamsBonusCell.value ?? 0) + (yamsBonusCell.bonusValue ?? 0) };
        }
        return cell;
      });
    }
  }

  // BOTTOM SECTION - compute top results  (total_2, total_col)
  const total_2 = computeTotal_2(updatedCells, colKey as COL_KEY);
  const total_col = total_1 + total_2;
  updatedCells = updatedCells.map((cell) => {
    if (cell.colKey === colKey) {
      if (section === ROW_SECTION.BOTTOM && cell.rowKey === RESULT_KEY.TOTAL_2) {
        return { ...cell, value: total_2 };
      } else if (cell.rowKey === RESULT_KEY.TOTAL_COL) {
        return { ...cell, value: total_col };
      }
    }
    return cell;
  });

  // grand total
  const grand_total = computeGrandTotal(updatedCells);
  updatedCells = updatedCells.map((cell) => {
    if (cell.rowKey === RESULT_KEY.GRAND_TOTAL) {
      return { ...cell, value: grand_total };
    }
    return cell;
  });

  return updatedCells;
};

/**
 * Computes the next set of score sheets from the given array of score sheets
 * based on the new score to write on cell
 * update cells with new score and availabiity, isCurrent and canUseYamsBonus
 *
 * @param {ScoreSheet[]} scoreSheets - The array of score sheets.
 * @param {Cell} cell - The cell object.
 * @param {number} scoreValue - The score value.
 * @return {ScoreSheet[]} The updated array of score sheets.
 */
export const getNextScoreSheetsFromNewScore = (
  scoreSheets: ScoreSheet[],
  cell: Cell,
  scoreValue: number,
  withYamsBonus: boolean,
): ScoreSheet[] => {
  const currentIndex = scoreSheets.findIndex((s) => s.id === cell.scoreSheetId);
  const nextIndex = currentIndex + 1 < scoreSheets.length ? currentIndex + 1 : 0;

  return scoreSheets.map((scoreSheet, index) => {
    if (currentIndex === index) {
      return {
        ...scoreSheet,
        cells: updateCellsFromNewScore(scoreSheet, cell, scoreValue, withYamsBonus),
        isCurrent: scoreSheets.length > 1 ? false : true,
        canUseYamsBonus: cell.rowKey === SCORE_KEY.YAMS && scoreValue > 0 ? true : scoreSheet.canUseYamsBonus,
      };
    } else if (nextIndex === index) {
      return { ...scoreSheet, isCurrent: true };
    }
    return scoreSheet;
  });
};
