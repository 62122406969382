import React from "react";
import { GAME_STATUS } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { preserveADice, throwDice } from "../../../../../redux/features/game/gameSlice";

import { DiceBoard } from "../../../../../components/game";
import { Button } from "../../../../../components/forms";
// import {
//   CLICK_ON_DICE_TO_PRESERVE
// } from '../../../../../common/constants/settings';

import { ImgRollTheDice } from "../../../../../common/img/common";

import "./gameBoard.css";

interface GameBoardProps {
  className?: string;
}

const GameBoard = ({ className }: GameBoardProps): JSX.Element | null => {
  const { status, gameBoard } = useAppSelector((state) => state.game);
  if (!gameBoard) return null;

  const isGameOver = status === GAME_STATUS.OVER;
  const { throwIndex, maxNumberOfThrows, isThrowingDice } = gameBoard;
  const clickOnDiceToPreserve = true; //settings.game[CLICK_ON_DICE_TO_PRESERVE]

  const dispatch = useAppDispatch();

  return (
    <div className={`gameBoard_container ${className}`}>
      {/* dices */}
      <DiceBoard
        className="gameBoard_dices"
        dices={gameBoard.dice}
        isThrowingDices={isThrowingDice}
        clickOnDiceToPreserve={clickOnDiceToPreserve}
        showIndicator={false}
        onPreserveDice={(dice) => dispatch(preserveADice(dice, !dice.isPreserved))}
      />
      {/* throw dices button */}
      <Button
        className={
          "gameBoard_throw_button" +
          (throwIndex === maxNumberOfThrows || isThrowingDice === true || isGameOver === true ? " disabled" : "")
        }
        img={<ImgRollTheDice />}
        title={`LANCER (${throwIndex}/${maxNumberOfThrows})`}
        onClick={() => dispatch(throwDice())}
      />
    </div>
  );
};

export default GameBoard;
