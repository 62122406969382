import React, { useEffect, useState } from "react";
import packageInfo from "../../../../../package.json";

import { AppLogo } from "../../../../components/ui";
import { Button } from "../../../../components/forms";

import "./welcome.css";

interface WelcomeProps {
  className?: string;
}

const Welcome = ({ className }: WelcomeProps): JSX.Element => {
  const [hasUpdate, setHasUpdate] = useState<boolean>(false);

  const onReceiveNewAppVersion = (): void => {
    setHasUpdate(true);
  };

  useEffect(() => {
    navigator.serviceWorker.addEventListener("controllerchange", onReceiveNewAppVersion);
    return () => navigator.serviceWorker.removeEventListener("controllerchange", onReceiveNewAppVersion);
  }, []);

  return (
    <div className={`welcome_container${className ? ` ${className}` : ""}`}>
      {/* Logo */}
      <AppLogo className="welcome_logo" />

      {/* Sepration */}
      <div className="welcome_separation">
        <span className="welcome_version">v{packageInfo.version}</span>
        {hasUpdate && (
          // Update Button
          <Button
            className="welcome_new_version_button"
            title="INSTALLER LA NOUVELLE VERSION"
            onClick={() => {
              window.location.reload();
            }}
          />
        )}
      </div>

      {/* message */}
      <div className="welcome_welcome_message">
        <div className="welcome_welcome_message_line">
          Bienvenue sur <span className="lemyams">Le myams</span>,
        </div>
        <div className="welcome_welcome_message_line">un jeu de yams complexe et stratégique.</div>
      </div>
    </div>
  );
};

export default Welcome;
