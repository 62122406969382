// import { ResultKey, RowKey, ScoreKey } from "../types";

export enum GAME_MODE {
  SIMULATION = "simulation",
  FREE = "free",
  ONLINE = "online",
}

export enum GAME_RULE {
  MYAMS = "myams",
  RAPIDO = "rapido",
  YAHTZEE = "yahtzee",
}

export enum GAME_STATUS {
  INITIAL = "initial",
  ONGOING = "ongoing",
  PAUSED = "paused",
  OVER = "over",
}

export enum COL_KEY {
  FREE = "FREE",
  FROM_TOP_TO_BOTTOM = "FROM_TOP_TO_BOTTOM",
  FROM_BOTTOM_TO_TOP = "FROM_BOTTOM_TO_TOP",
  RANDOM = "RANDOM",
}

export enum ROW_TYPE {
  SCORE = "score",
  RESULT = "result",
  BONUS = "bonus",
  INDICATOR = "indicator",
  SEPARATION = "separation",
}

export enum ROW_SECTION {
  TOP = "top",
  BOTTOM = "bottom",
}

export enum SCORE_KEY {
  ACES = "ACES",
  TWOS = "TWOS",
  THREES = "THREES",
  FOURS = "FOURS",
  FIVES = "FIVES",
  SIXES = "SIXES",
  THREE_OF_A_KIND = "THREE_OF_A_KIND",
  FOUR_OF_A_KIND = "FOUR_OF_A_KIND",
  FULL_HOUSE = "FULL_HOUSE",
  SMALL_STRAIGHT = "SMALL_STRAIGHT",
  LARGE_STRAIGHT = "LARGE_STRAIGHT",
  YAMS = "YAMS",
  LOW_CHANCE = "LOW_CHANCE",
  HIGH_CHANCE = "HIGH_CHANCE",
  CHANCE = "CHANCE",
  UNDER_ELEVEN = "UNDER_ELEVEN",
  ONLY_PAIRS = "ONLY_PAIRS",
}

export enum RESULT_KEY {
  SUBTOTAL = "SUBTOTAL",
  TOTAL_1 = "TOTAL_1",
  TOTAL_2 = "TOTAL_2",
  TOTAL_COL = "TOTAL_COL",
  GRAND_TOTAL = "GRAND_TOTAL",
}

export enum BONUS_KEY {
  BONUS = "BONUS",
  YAMS_BONUS = "YAMS_BONUS",
}

export enum INDICATOR_KEY {
  BONUS_INDICATOR = "BONUS_INDICATOR",
}

export enum SEPARATION_KEY {
  SEPARATION = "SEPARATION",
}

export type ROW_KEY = SCORE_KEY | RESULT_KEY | BONUS_KEY | INDICATOR_KEY | SEPARATION_KEY;
