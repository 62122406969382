// game rules
export const UPDATE_GAME_RULES = "UPDATE_GAME_RULES";

// game modes
export const UPDATE_GAME_MODES = "UPDATE_GAME_MODES";

// game
export const RESET_GAME = "RESET_GAME";
export const UPDATE_GAME = "UPDATE_GAME";
// scoreSheets
export const ADD_SCORESHEET = "ADD_SCORESHEET";
export const REMOVE_SCORESHEET = "REMOVE_SCORESHEET";
export const UPDATE_SCORESHEET = "UPDATE_SCORESHEET";
export const UPDATE_SCORESHEETS = "UPDATE_SCORESHEETS";
export const UPDATE_CELL = "UPDATE_CELL";

// gameBoard
export const UPDATE_GAMEBOARD = "UPDATE_GAMEBOARD";

// settings
export const UPDATE_SETTING = "UPDATE_SETTING";

// ui
/* tutorial */
export const UPDATE_UI_TUTORIAL = "UPDATE_UI_TUTORIAL";
/* errors */
export const ADD_UI_ERROR = "ADD_UI_ERROR";
export const REMOVE_UI_ERROR = "REMOVE_UI_ERROR";
export const REMOVE_UI_ERRORS = "REMOVE_UI_ERRORS";
/* notif */
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const REMOVE_NOTIFICATION = "REMOVE_NOTIFICATION";
/* zoom */
export const UPDATE_UI_ZOOM = "UPDATE_UI_ZOOM";

// user
export const UPDATE_USER = "UPDATE_USER";
export const UPDATE_USER_CON = "UPDATE_USER_CON";
export const UPDATE_USER_PROMPT = "UPDATE_USER_PROMPT";
export const UPDATE_USER_UPDT = "UPDATE_USER_UPDT";
export const RESET_USER_PROFILE = "RESET_USER_PROFILE";
export const RESET_USER = "RESET_USER";
