import React, { useState, useEffect, PropsWithChildren } from "react";

import "./radio.css";

/******
  Radio - an element used from RadioGroup or Radios
*/
interface RadioProps extends PropsWithChildren {
  selected?: boolean;
  label?: string | JSX.Element;
  sublabel?: string;
  color?: string;
  disabled?: boolean;
  onSelect: () => void;
}

export const Radio = ({
  selected = false,
  label,
  sublabel,
  color,
  disabled = false,
  onSelect,
  ...props
}: RadioProps): JSX.Element => {
  const [isSelected, setSelected] = useState(selected);
  const [isDisabled, setDisabled] = useState(disabled);
  let style;
  if (color && isSelected) {
    style = {
      backgroundColor: color,
      borderColor: color,
    };
  }
  useEffect(() => {
    setSelected(selected);
  }, [selected]);
  useEffect(() => {
    setDisabled(disabled);
  }, [disabled]);
  return (
    <div
      className={
        "radio_container" +
        (isSelected ? " selected" : "") +
        (isDisabled ? " disabled" : "") +
        (sublabel ? " withSublabel" : "")
      }
    >
      {/* radio content */}
      <div
        className="radio_content"
        onClick={() => {
          setSelected(true);
          onSelect();
        }}
      >
        {/* radio */}
        <div style={style || undefined} className="radio"></div>
        {/* label and sublabel */}
        <div className="label_and_sublabel">
          {label && <span className="label">{label}</span>}
          {sublabel && <span className="sublabel">{sublabel}</span>}
        </div>
      </div>
      {/* children */}
      {props.children && <div className="children">{props.children}</div>}
    </div>
  );
};

/******
 * RadioGroup - a container of Radio elements
 */
interface RadioGroupProps extends PropsWithChildren {
  direction?: "column" | "row";
  title?: string;
}

export const RadioGroup = ({ direction = "column", title, ...props }: RadioGroupProps): JSX.Element => (
  <div
    className={"radioGroup_container" + (direction === "column" ? " column" : "") + (direction === "row" ? " row" : "")}
  >
    {title && <div className="radioGroup_title">{title}</div>}
    {props.children}
  </div>
);

/******
 * Radios - a list of Radio elements
 */
// interface RadioProps {
//   items: any[];
//   keyForId: string;
//   keyForLabel: string;
//   keyForSublabel: string;
//   disabled?: boolean;
//   selectedItem?: any;
//   direction?: "column" | "row";
//   onSelectItem: (item: any) => void;
// }
// export const Radios = ({
//   items,
//   keyForId = "id",
//   keyForLabel = "label",
//   keyForSublabel = "sublabel",
//   disabled = false,
//   selectedItem,
//   direction = "column",
//   onSelectItem,
// } : RadioProps): JSX.Element => (
//   <div
//     className={
//       "radios_container" +
//       (direction === "column" ? " column" : "") +
//       (direction ===  "row" ? " row" : "")
//     }
//   >
//     {items.map((item) => (
//       <Radio
//         key={item[keyForId]}
//         id={item[keyForId]}
//         label={item[keyForLabel]}
//         sublabel={item[keyForSublabel]}
//         disabled={disabled}
//         selected={selectedItem && item[keyForId] === selectedItem[keyForId]}
//         onSelect={() => onSelectItem(item)}
//       />
//     ))}
//   </div>
// );
