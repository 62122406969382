export const AVATAR_ALLOWED_FORMATS = ["image/png", "image/x-png", "image/gif", "image/jpeg"];
export const AVATAR_MAX_SIZE = 1024 * 1024; // 1Mo

export const PSEUDO_MIN_CHARS = 4;
export const PSEUDO_MAX_CHARS = 16;
export const PSEUDO_ALLOWED_SPECIAL_CHARS = ["_", "-", "(", ")"];
export const PSEUDO_REGEX = RegExp("^([\\w-()]{4,16})$");

export const EMAIL_REGEX = RegExp("^[a-zA-Z0-9._-]+@[a-z0-9._-]{2,}\\.[a-z]{2,4}$");

export const PASSWORD_MIN_CHARS = 8;
export const PASSWORD_MAX_CHARS = 32;
