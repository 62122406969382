import React from "react";
import { INDICATOR_KEY, RESULT_KEY, ROW_TYPE } from "../../../game-box/core/enums";
import { GAME_STATUS } from "../../../game-box/core/enums";
import { Cell, ScoreSheet } from "../../../game-box/core/types";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  beginWriteScore,
  cancelWriteScore,
  endWriteScore,
  removeScoreSheet,
  updatePlayerName,
} from "../../../redux/features/game/gameSlice";

import { SETTING_KEY } from "../../../common/enums";
import { canTouch, isOnMobile } from "../../../common/utils/appUtils";

import NameRow from "./nameRow";
import ColTitlesRow from "./colTitlesRow";
import { ScoreInput } from "../../../components/game";

import { buildParentScoreCellClassName, buildScoreCellClassName, canScoreOnCell } from "./scoreSheetHelper";
import "../scoreSheets.css";
import "./scoreSheet.css";

interface ScoreSheetElemProps {
  scoreSheet: ScoreSheet;
}

const ScoreSheetElem = ({ scoreSheet }: ScoreSheetElemProps): JSX.Element => {
  const { status, scoreSheets, gameBoard } = useAppSelector((state) => state.game);
  const settings = useAppSelector((state) => state.settings);
  const canEditName = status === GAME_STATUS.INITIAL;
  const canBeRemoved = status === GAME_STATUS.INITIAL && scoreSheets.length > 1;
  const isAllowedToScore = !gameBoard || (gameBoard.throwIndex > 0 && gameBoard.isThrowingDice === false);
  const isGameOver = status === GAME_STATUS.OVER;

  const dispatch = useAppDispatch();

  const endWriteScoreOnMobile = (cell: Cell, scoreValue?: number) => {
    // Vibration
    settings[SETTING_KEY.ENABLE_VIBRATION_ON_SCORESHEET] && navigator.vibrate && navigator.vibrate(100);
    dispatch(endWriteScore(cell, scoreValue));
  };

  const { cols } = scoreSheet;
  const colSpan = cols ? cols.length : 1;
  // render cells of row
  const renderCellsOfRow = (cells: Cell[]): JSX.Element[] => {
    const cellsToRender: JSX.Element[] = [];
    // for each cell
    cells.forEach((cell, i) => {
      const { value, pendingValue, allowedScores, isScoring, error } = cell;
      const onClickCell = canScoreOnCell(scoreSheet, cell, isAllowedToScore)
        ? () => (gameBoard ? endWriteScoreOnMobile(cell, pendingValue) : dispatch(beginWriteScore(cell)))
        : undefined;
      // push cell
      cellsToRender.push(
        <td key={i} className={buildParentScoreCellClassName(cell)}>
          <div
            className={buildScoreCellClassName(cell) + (isAllowedToScore === true ? " isAllowedToScore" : "")}
            onTouchStart={canTouch() ? onClickCell : undefined}
            onClick={!canTouch() ? onClickCell : undefined}
          >
            {value !== undefined && cell.type === ROW_TYPE.INDICATOR && value > 0 && "+"}
            {value !== undefined
              ? value
              : pendingValue !== undefined && settings[SETTING_KEY.SHOW_PENDING_SCORES] === true
              ? pendingValue
              : undefined}
            {/* show ScoreInput if isScoring is true */}
            {isScoring === true && (
              <ScoreInput
                suggestedScores={allowedScores}
                error={error}
                onBlur={() => dispatch(cancelWriteScore(cell))}
                onValidateScore={(score) => dispatch(endWriteScore(cell, score))}
              />
            )}
          </div>
        </td>,
      );
    });
    return cellsToRender;
  };
  // render scoreSheet
  const renderScoreSheet = (scoreSheet: ScoreSheet): JSX.Element[] => {
    const { rows, cols, cells } = scoreSheet;
    const colSpan = cols.length;
    const scoreSheetToRender: JSX.Element[] = [];
    // for each row
    rows
      .filter((row) =>
        settings[SETTING_KEY.SHOW_BONUS_INDICATOR] === true ? true : row.key !== INDICATOR_KEY.BONUS_INDICATOR,
      )
      .forEach((row, index) => {
        // push row separation (no col)
        if (row.type === ROW_TYPE.SEPARATION) {
          scoreSheetToRender.push(
            <tr key={index} className="row_separation">
              <td></td>
            </tr>,
          );
        }
        // push row grand total (no col)
        else if (row.key === RESULT_KEY.GRAND_TOTAL) {
          const cell = cells.find((c) => c.rowKey === row.key);
          scoreSheetToRender.push(
            <tr key={index}>
              <td colSpan={colSpan} className={`cell_result result_${row.key.toLowerCase()}`}>
                <div className={`cell cell_result result_${row.key.toLowerCase()}`}>{cell?.value}</div>
              </td>
            </tr>,
          );
        }
        // push row cells of the row
        else {
          const cellsOfRow = cells.filter((cell) => cell.rowKey === row.key);
          scoreSheetToRender.push(<tr key={row.key}>{renderCellsOfRow(cellsOfRow)}</tr>);
        }
      });
    return scoreSheetToRender;
  };
  // render NameRow, ColTitlesRow and scoreSheet
  return (
    <table
      className={
        "grid scoreSheet_grid" +
        (colSpan === 1 ? " one_col" : "") +
        (colSpan === 2 ? " two_cols" : "") +
        (colSpan === 3 ? " three_cols" : "") +
        (scoreSheet.isCurrent !== true ? " locked" : "") +
        (canEditName === true
          ? " initial"
          : isGameOver !== true
          ? scoreSheet.isCurrent === true
            ? " current_scoreSheet"
            : " pending_scoreSheet"
          : "")
      }
    >
      {/*
        Header : Name and Col titles
      */}
      <thead>
        {!isOnMobile() && (
          <>
            <NameRow
              name={scoreSheet.playerName}
              canEditName={canEditName}
              canBeRemoved={canBeRemoved}
              colSpan={colSpan}
              onUpdateName={(name) => dispatch(updatePlayerName(scoreSheet, name))}
              onRemove={() => dispatch(removeScoreSheet(scoreSheet))}
            />
            <tr className="row_separation">
              <td></td>
            </tr>
          </>
        )}
        <ColTitlesRow cols={cols} canShowColTitlesInfos={canEditName || scoreSheet.isCurrent} />
        <tr className="row_separation">
          <td></td>
        </tr>
      </thead>

      {/*
        Body : ScoreSheet with Cells
      */}
      <tbody>{scoreSheet.cells && renderScoreSheet(scoreSheet)}</tbody>
    </table>
  );
};

export default ScoreSheetElem;
