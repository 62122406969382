import React, { ReactNode, useState } from "react";

import { ACTION_ITEM, MENU_ITEM } from "../../../common/enums";
import { canTouch } from "../../../common/utils/appUtils";

import { Menu } from "../../ui";

import "./actionButton.css";

interface ActionButtonProps {
  id: ACTION_ITEM;
  content: ReactNode;
  disabled?: boolean;
  menuItems?: MENU_ITEM[];
  onTouch?: () => void;
  onTouchMenuItem?: (menuItem: MENU_ITEM) => void;
}

const ActionButton = ({
  id,
  content,
  disabled,
  menuItems,
  onTouch,
  onTouchMenuItem,
}: ActionButtonProps): JSX.Element => {
  const [isMenuVisible, setMenuVisible] = useState(false);

  const onClick = () => {
    if (menuItems) {
      setMenuVisible(!isMenuVisible);
    } else if (onTouch) {
      onTouch();
    }
  };

  return (
    <div
      id={id}
      className={`actionButton${disabled ? " disabled" : ""}`}
      onTouchStart={!disabled && canTouch() ? onClick : undefined}
      onClick={!disabled && !canTouch() ? onClick : undefined}
    >
      {content}
      {menuItems && (
        <Menu
          visible={isMenuVisible}
          menuItems={menuItems}
          onTouchMenuItem={(menuItem: MENU_ITEM) => onTouchMenuItem?.(menuItem)}
          onBlur={() => setMenuVisible(false)}
        />
      )}
    </div>
  );
};

export default ActionButton;
