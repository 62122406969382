import React, { useState } from "react";

import { useAppSelector } from "../../../../redux/hooks";

import { ImgDotsMenu, ImgInfoExclamation, ImgReleaseNote, ImgRules } from "../../../../common/img/common";
import { ModalView } from "../../../../components/ui";

import GameRuleDetails from "../../../rulesOfTheGame/GameRuleDetails";
import ReleaseNote from "../releaseNote/ReleaseNote";
import Informations from "../informations/Informations";

import "./homeMenu.css";

interface HomeMenuItemProps {
  img: JSX.Element;
  title: string;
  onClick: () => void;
}
const HomeMenuItem = ({ img, title, onClick }: HomeMenuItemProps) => {
  return (
    <div className="mobile_home_menu_item" onClick={onClick}>
      {img}
      {title}
    </div>
  );
};

const HomeMenu = (): JSX.Element => {
  const gameRules = useAppSelector((state) => state.gameRules);

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);
  const [showRules, setShowRules] = useState<boolean>(false);
  const [showReleaseNote, setShowReleaseNote] = useState<boolean>(false);
  const [showInformations, setShowInformations] = useState<boolean>(false);

  return (
    <div className="mobile_home_menu_container">
      {/* Background overlay */}
      <div
        className={`mobile_home_menu_background_overlay${isMenuVisible ? " visible" : ""}`}
        onClick={() => setIsMenuVisible(false)}
      />

      {/* Menu Imd dots */}
      <div className="mobile_home_menu" onClick={() => setIsMenuVisible(!isMenuVisible)}>
        <ImgDotsMenu color="#fff" />
      </div>

      {/* Menu overlay */}
      <div className={`mobile_home_menu_overlay${isMenuVisible ? " visible" : ""}`}>
        {/* Rules */}
        <HomeMenuItem
          img={<ImgRules color="#fff" />}
          title="Règles du Jeu"
          onClick={() => {
            setShowRules(true);
            setIsMenuVisible(false);
          }}
        />
        {/* Release Note */}
        <HomeMenuItem
          img={<ImgReleaseNote color="#fff" />}
          title="Notes de version"
          onClick={() => {
            setShowReleaseNote(true);
            setIsMenuVisible(false);
          }}
        />
        {/* Informations */}
        <HomeMenuItem
          img={<ImgInfoExclamation color="#fff" stroke="#000" />}
          title="Informations générales"
          onClick={() => {
            setShowInformations(true);
            setIsMenuVisible(false);
          }}
        />
      </div>

      {/*** Modals ***/}

      {/* Rules */}
      {showRules && (
        <ModalView
          modalStyle={{ maxWidth: "1200px", width: "100%", height: "80%" }}
          modalContentStyle={{ padding: "20px 0 16px 16px" }}
          img={<ImgRules />}
          title="RÈGLES DU JEU"
          canCloseByClickingOutside={false}
          onClose={() => setShowRules(false)}
        >
          <GameRuleDetails rule={gameRules[0]} />
        </ModalView>
      )}

      {/* Release Note */}
      {showReleaseNote && (
        <ModalView
          modalStyle={{ width: "90%", maxHeight: "75%" }}
          modalContentStyle={{ padding: "20px 16px" }}
          canCloseByClickingOutside={false}
          onClose={() => setShowReleaseNote(false)}
        >
          <ReleaseNote onClickClose={() => setShowReleaseNote(false)} />
        </ModalView>
      )}

      {/* Informations */}
      {showInformations && (
        <ModalView
          modalStyle={{ width: "90%" }}
          modalContentStyle={{ padding: "20px 16px" }}
          canCloseByClickingOutside={false}
          onClose={() => setShowRules(false)}
        >
          <Informations onClickClose={() => setShowInformations(false)} />
        </ModalView>
      )}
    </div>
  );
};

export default HomeMenu;
