import { UserProfile } from "../common/types";
import axios from "./axiosConfig";

interface LoginResponse {
  user: UserProfile;
  access_token: string;
}

//temp debug
function debugTimeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class AuthService {
  /*** Register ***/
  async resgister(pseudo: string, email: string, password: string, passwordConf: string): Promise<LoginResponse> {
    // await debugTimeout(800);
    const response = await axios.post("/api/auth/register", { pseudo, email, password, passwordConf });
    return response.data;
  }
  async activateAccount(activationId: string): Promise<LoginResponse> {
    // todo: remove timeout (used to show a loader for now cause action is fast)
    await debugTimeout(800);
    const response = await axios.post("/api/auth/activate_account", { activationId });
    return response.data;
  }
  /*** Login ***/
  async login(pseudo: string, password: string): Promise<LoginResponse> {
    // await debugTimeout(800);
    const response = await axios.post("/api/auth/login", { pseudo, password });
    return response.data;
  }

  async requestPasswordReset(email: string): Promise<void> {
    // await debugTimeout(800);
    await axios.post("/api/auth/request_password_reset", { email });
  }

  async saveNewPassword(password: string, passwordResetId: string): Promise<LoginResponse> {
    // todo: remove timeout (used to show a loader for now cause action is fast)
    await debugTimeout(800);
    const response = await axios.post("/api/auth/save_new_password", { password, passwordResetId });
    return response.data;
  }

  async logout(): Promise<boolean> {
    // todo: remove timeout (used to show a loader for now cause action is fast and no api call)
    await debugTimeout(800);
    // todo: reset token on server ? tricky because a JWT can't be invlidated on server
    return true;
  }

  async deleteAccount(): Promise<boolean> {
    // await debugTimeout(800);
    const response = await axios.delete("/api/auth/remove_account");
    return response.data;
  }
}

export default new AuthService();
