import React from "react";

import { ModalView, Separation } from "../../ui";
import { Button } from "../../forms";

import "./modalConfirmationView.css";

interface ModalConfirmationViewProps {
  title?: string;
  modalStyle?: Record<string, unknown>;
  modalContentStyle?: Record<string, unknown>;
  canCloseByClickingOutside?: boolean;
  text: string | string[];
  confirmationButtonTitle?: string;
  confirmationButtonClassName?: string;
  onConfirm: () => void;
  onClose: () => void;
}
const ModalConfirmationView = ({
  title,
  modalStyle,
  modalContentStyle,
  canCloseByClickingOutside = true,
  text,
  confirmationButtonTitle = "OK",
  confirmationButtonClassName,
  onConfirm,
  onClose,
}: ModalConfirmationViewProps): JSX.Element => {
  return (
    <ModalView
      title={title}
      modalStyle={{
        minWidth: "100px",
        minHeight: "100px",
        marginBottom: "20%",
        ...modalStyle,
      }}
      modalContentStyle={{
        padding: "20px",
        // backgroundColor: "rgba(0, 0, 0, 0.88)",
        ...modalContentStyle,
      }}
      canCloseByClickingOnCross={false}
      canCloseByClickingOutside={canCloseByClickingOutside}
      onClose={onClose}
    >
      <div className="modalConfirmationView_container">
        {/* Text */}
        <div className={"modalConfirmationView_text" + (title ? "" : " no-title")}>
          {Array.isArray(text) ? text.map((t, i) => <span key={i}>{t}</span>) : text}
        </div>

        <Separation margin="20px" />

        {/* Buttons */}
        <div className="modalConfirmationView_buttons">
          {/* cancel */}
          <Button
            className="modalConfirmationView_cancel_button"
            title="Annuler"
            onClick={(e) => {
              e.stopPropagation();
              onClose();
            }}
          />
          {/* confirm */}
          <Button
            className={
              "modalConfirmationView_confirmation_button " +
              (confirmationButtonClassName ? confirmationButtonClassName : "")
            }
            title={confirmationButtonTitle}
            onClick={(e) => {
              e.stopPropagation();
              onConfirm();
            }}
          />
        </div>
      </div>
    </ModalView>
  );
};

export default ModalConfirmationView;
