import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../configureStore";

import { Info } from "../../common/types";
import { NEW_FEATURE_KEY, TUTORIAL_KEY } from "../../common/enums";

const initialState: Info = {
  tutorial: {
    [TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE]: true,
    [TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE]: true,
    [TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET]: true,
    [TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE]: true,
    [TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW]: true,
    [TUTORIAL_KEY.SHOW_TUTO_6_MENU]: true,
  },
  newFeatures: {
    [NEW_FEATURE_KEY.SHOW_SHARE_GAME]: true,
  },
};

type UpdateTutorialPayload = { key: TUTORIAL_KEY; value: boolean };
type UpdateNewFeaturesPayload = { key: NEW_FEATURE_KEY; value: boolean };

const infoSlice = createSlice({
  name: "info",
  initialState,
  reducers: {
    // tutorial
    resetTutorial: (state) => {
      state.tutorial = {
        [TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE]: true,
        [TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE]: true,
        [TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET]: true,
        [TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE]: true,
        [TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW]: true,
        [TUTORIAL_KEY.SHOW_TUTO_6_MENU]: true,
      };
    },
    cancelTutorial: (state) => {
      state.tutorial = {
        [TUTORIAL_KEY.SHOW_TUTO_1_THROW_DICE]: false,
        [TUTORIAL_KEY.SHOW_TUTO_2_PRESERVE_DICE]: false,
        [TUTORIAL_KEY.SHOW_TUTO_3_SCORE_SHEET]: false,
        [TUTORIAL_KEY.SHOW_TUTO_4_CANCEL_SCORE]: false,
        [TUTORIAL_KEY.SHOW_TUTO_5_EXTRA_THROW]: false,
        [TUTORIAL_KEY.SHOW_TUTO_6_MENU]: false,
      };
    },
    _updateTutorial: (state, action: PayloadAction<UpdateTutorialPayload>) => {
      state.tutorial[action.payload.key] = action.payload.value;
    },
    // newFeatures
    _updateNewFeatures: (state, action: PayloadAction<UpdateNewFeaturesPayload>) => {
      state.newFeatures[action.payload.key] = action.payload.value;
    },
  },
});

export default infoSlice.reducer;

export const { resetTutorial, cancelTutorial } = infoSlice.actions;

const { _updateTutorial, _updateNewFeatures } = infoSlice.actions;

export const updateTutorial = (key: TUTORIAL_KEY, value: boolean): AppThunk => {
  return (dispatch) => {
    dispatch(_updateTutorial({ key, value }));
  };
};

export const updateNewFeatures = (key: NEW_FEATURE_KEY, value: boolean): AppThunk => {
  return (dispatch) => {
    dispatch(_updateNewFeatures({ key, value }));
  };
};
