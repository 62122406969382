import React, { useState, useEffect } from "react";

import { ImgArrowUp, ImgArrowDown } from "./images";

import "./cardContainer.css";

interface CardContainerProps {
  img: React.JSX.Element;
  title: string;
  subtitle?: string;
  showSubtitleOnlyWhenNotExpanded?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  hasPadding?: boolean;
  children: React.JSX.Element;
  onExpand?: () => void;
}
const CardContainer = ({
  img,
  title,
  subtitle,
  showSubtitleOnlyWhenNotExpanded = true,
  expandable = true,
  expanded = true,
  hasPadding = true,
  children,
  onExpand,
}: CardContainerProps): JSX.Element => {
  const [isExpanded, setExpanded] = useState(expanded);
  const [isAnimated, setAnimated] = useState(false);
  useEffect(() => setExpanded(expanded), [expanded]);
  return (
    <div className={"card_container" + (expandable ? " expandable" : "") + (isExpanded ? " expanded" : "")}>
      {/* Header */}
      <div
        className="card_header"
        onClick={
          expandable
            ? () => {
                onExpand && isExpanded === false && onExpand();
                setExpanded(!isExpanded);
                setAnimated(true);
              }
            : undefined
        }
      >
        {/* img */}
        <div className="header_img">{img}</div>
        {/* title */}
        <div className="header_title">{title}</div>
        {/* subtitle */}
        {subtitle && (!showSubtitleOnlyWhenNotExpanded || !isExpanded) && (
          <div className="header_subtitle">{subtitle}</div>
        )}
        {/* arrow expand/fold */}
        {expandable && <div className="header_arrow">{isExpanded ? <ImgArrowUp /> : <ImgArrowDown />}</div>}
      </div>
      {/* Content */}
      <div
        style={hasPadding === false ? { padding: 0 } : undefined}
        className={"card_content" + (isExpanded ? " visible" : " hidden") + (isAnimated ? " animated" : "")}
      >
        {children}
      </div>
    </div>
  );
};

export default CardContainer;
