import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk } from "../../configureStore";

import {
  AdditionalThrow,
  Cell,
  Dice,
  Game,
  GameBoard,
  GameMode,
  GameRule,
  ScoreSheet,
} from "../../../game-box/core/types";
import { GAME_MODE, GAME_STATUS, ROW_TYPE } from "../../../game-box/core/enums";
import {
  checkScoreValidity,
  filterPlayerName,
  generateGameBoard,
  generateScoreSheet,
  getCellScoreValueFromDice,
  getGameModes,
  getGameRules,
  getRandomDiceSet,
  initAdditionalThrows,
  initScoreSheets,
  udateGameWithYamsBonus,
  updateGameForNewScore,
  updateGameForScoreCancellation,
} from "../../../game-box";
import { checkGameToken, generateGame64, generateGameToken, generateGameToSaveToken } from "../../../game-box/security";
import { SETTING_KEY } from "../../../common/enums";
import { ROLLING_DICE_DELAY } from "../../../game-box/core/const";
import GameService from "../../../services/gameService";

const initialState: Game = {
  date: new Date().toString(),
  token: null,
  status: GAME_STATUS.INITIAL,
  progress: 0,
  timer: 0,
  mode: getGameModes()[0],
  rule: getGameRules()[0],
  scoreSheets: [],
  pastScoreSheets: [],
  gameBoard: undefined,
  pastGameBoards: undefined,
  additionalThrows: [],
  history: {
    scores: [],
  },
  isSaved: false,
};

type UpdateCellPayload = { scoreSheetId: number; updatedCell: Cell };

const gameSlice = createSlice({
  name: "game",
  initialState,
  reducers: {
    resetGame: () => initialState,
    updateGame: (state, action: PayloadAction<Game>) => action.payload,
    selectRule: (state, action: PayloadAction<GameRule>) => {
      state.rule = action.payload;
    },
    selectMode: (state, action: PayloadAction<GameMode>) => {
      state.mode = action.payload;
    },
    updateStatus: (state, action: PayloadAction<GAME_STATUS>) => {
      state.status = action.payload;
    },
    updateTimer: (state, action: PayloadAction<number>) => {
      state.timer = action.payload;
    },
    /** scoreSheets **/
    addScoreSheet: (state, action: PayloadAction<ScoreSheet>) => {
      state.scoreSheets.push(action.payload);
    },
    updateScoreSheet: (state, action: PayloadAction<ScoreSheet>) => {
      state.scoreSheets = state.scoreSheets.map((s) => (s.id === action.payload.id ? action.payload : s));
    },
    removeScoreSheet: (state, action: PayloadAction<ScoreSheet>) => {
      state.scoreSheets = state.scoreSheets.filter((s) => s.id !== action.payload.id);
    },
    updateScoreSheets: (state, action: PayloadAction<ScoreSheet[]>) => {
      state.scoreSheets = action.payload;
    },
    updateCell: (state, action: PayloadAction<UpdateCellPayload>) => {
      const { scoreSheetId, updatedCell } = action.payload;
      state.scoreSheets = state.scoreSheets.map((s) =>
        s.id === scoreSheetId
          ? {
              ...s,
              cells: s.cells.map((c) =>
                c.rowKey === updatedCell.rowKey && c.colKey === updatedCell.colKey ? updatedCell : c,
              ),
            }
          : s,
      );
    },
    /** gameBoard **/
    updateGameBoard: (state, action: PayloadAction<GameBoard>) => {
      state.gameBoard = action.payload;
    },
  },
});

export default gameSlice.reducer;

export const { updateStatus, updateTimer, updateScoreSheet, removeScoreSheet } = gameSlice.actions;
const {
  resetGame,
  updateGame,
  selectRule,
  selectMode,
  /** scoreSheets **/
  addScoreSheet,
  updateScoreSheets,
  updateCell,
  /** gameBoard **/
  updateGameBoard,
} = gameSlice.actions;

export const newGame = (onMobile?: boolean): AppThunk => {
  return (dispatch, getState) => {
    const gameRules = getState().gameRules;
    const gameModes = getState().gameModes;
    const game = getState().game;

    const rule = onMobile ? gameRules[0] : game.rule;
    const mode = onMobile ? gameModes[0] : game.mode;

    dispatch(resetGame());
    dispatch(selectRule(rule));
    dispatch(selectMode(mode));
    dispatch(addScoreSheet(generateScoreSheet(rule)));
  };
};

export const selectGameRule = (rule: GameRule): AppThunk => {
  return (dispatch, getState) => {
    const { scoreSheets } = getState().game;

    // (re)generate score sheets according to the selected rule
    const newScoreSheets = scoreSheets.map((scoreSheet) => ({
      ...generateScoreSheet(rule),
      id: scoreSheet.id,
      playerName: scoreSheet.playerName,
    }));

    dispatch(selectRule(rule));
    dispatch(updateScoreSheets(newScoreSheets));
  };
};

export const selectGameMode = (mode: GameMode): AppThunk => {
  return (dispatch) => {
    // maybe later some additional actions should be dispatched here

    dispatch(selectMode(mode));
  };
};

export const addPlayer = (): AppThunk => {
  return (dispatch, getState) => {
    const { rule, scoreSheets } = getState().game;

    const newScoreSheet = generateScoreSheet(rule, scoreSheets);

    dispatch(addScoreSheet(newScoreSheet));
  };
};

export const updatePlayerName = (scoreSheet: ScoreSheet, newName: string): AppThunk => {
  return (dispatch, getState) => {
    const { scoreSheets } = getState().game;

    // update playerName on scoreSheet
    const excludedNames = scoreSheets.map((s) => s.playerName);
    const updatedScoreSheet = { ...scoreSheet, playerName: filterPlayerName(newName, excludedNames) };

    dispatch(updateScoreSheet(updatedScoreSheet));
  };
};

export const startGame = (): AppThunk => {
  return (dispatch, getState) => {
    const { game } = getState();
    const { mode, rule } = game;
    const { numberOfAdditionalThrows } = rule;

    if (game.scoreSheets.length === 0) {
      dispatch(addScoreSheet(generateScoreSheet(rule)));
    }
    const scoreSheets = getState().game.scoreSheets;

    // initialize scoreSheets for the start of the game
    const initializedScoreSheets = initScoreSheets(scoreSheets);
    // if mode simulation: generate a gameBoard
    const gameBoard = mode.key === GAME_MODE.SIMULATION ? generateGameBoard(rule) : undefined;
    const pastGameBoards = gameBoard ? [] : undefined;
    // if rule contains additional throws, init additionalThrows
    const additionalThrows =
      numberOfAdditionalThrows > 0 ? initAdditionalThrows(scoreSheets, numberOfAdditionalThrows) : [];

    const updatedGame = {
      ...game,
      date: new Date().toString(),
      status: GAME_STATUS.ONGOING,
      scoreSheets: initializedScoreSheets,
      gameBoard,
      pastGameBoards,
      additionalThrows,
    };

    dispatch(
      updateGame({
        ...updatedGame,
        token: generateGameToken(updatedGame),
      }),
    );
  };
};

export const beginWriteScore = (cell: Cell): AppThunk => {
  return (dispatch) => {
    dispatch(updateCell({ scoreSheetId: cell.scoreSheetId, updatedCell: { ...cell, isScoring: true } }));
  };
};

export const cancelWriteScore = (cell: Cell): AppThunk => {
  return (dispatch) => {
    dispatch(
      updateCell({ scoreSheetId: cell.scoreSheetId, updatedCell: { ...cell, isScoring: false, error: undefined } }),
    );
  };
};

export const endWriteScore = (cell: Cell, scoreValue?: string | number): AppThunk => {
  return (dispatch, getState) => {
    const game = getState().game;
    const { rule, scoreSheets, gameBoard } = game;
    const scoreSheet = scoreSheets.find((s) => s.id === cell.scoreSheetId);

    if (!scoreSheet) {
      console.error("no scoreSheet found for cell", cell.rowKey, cell.colKey);
      return;
    }

    // check the score value validity and get error if it's not valid
    const error = checkScoreValidity(cell, scoreSheet, rule, scoreValue);

    // invalid score, update cell with error
    if (error) {
      const updatedCell = { ...cell, error };
      dispatch(updateCell({ scoreSheetId: cell.scoreSheetId, updatedCell }));
    }
    // score is valid
    else {
      // update game for new score
      const updatedGame = updateGameForNewScore(game, cell, scoreValue as number);

      dispatch(updateGame({ ...updatedGame, token: generateGameToken(updatedGame) }));

      // reset pending score values (only if a gameBoard is defined)
      gameBoard && dispatch(cancelPreviewScores());
    }
  };
};

export const beginPreviewScores = (): AppThunk => {
  return (dispatch, getState) => {
    const { gameBoard, scoreSheets } = getState().game;
    const currentScoreSheet = scoreSheets.find((scoreSheet) => scoreSheet.isCurrent === true);
    if (!gameBoard || !currentScoreSheet) {
      console.error("beginPreviewScores: no gameBoard or currentScoreSheet");
      return;
    }

    // uppate pendingValue on currentScoreSheet cells
    const cells = currentScoreSheet.cells.map((cell) =>
      cell.isLocked || cell.type !== ROW_TYPE.SCORE
        ? cell
        : { ...cell, pendingValue: getCellScoreValueFromDice(cell, gameBoard.dice, currentScoreSheet) },
    );

    dispatch(updateScoreSheet({ ...currentScoreSheet, cells }));
  };
};

export const cancelPreviewScores = (): AppThunk => {
  return (dispatch, getState) => {
    const { scoreSheets } = getState().game;
    const currentScoreSheet = scoreSheets.find((scoreSheet) => scoreSheet.isCurrent === true);
    if (!currentScoreSheet) {
      console.error("cancelPreviewScores: no currentScoreSheet");
      return;
    }

    // remove pendingValue on currentScoreSheet cells
    const cells = currentScoreSheet.cells.map((cell) => ({ ...cell, pendingValue: undefined }));

    dispatch(updateScoreSheet({ ...currentScoreSheet, cells }));
  };
};

export const cancelLastScore = (): AppThunk => {
  return (dispatch, getState) => {
    const game = getState().game;

    // update game for score cancellation
    const updatedGame = updateGameForScoreCancellation(game);

    dispatch(updateGame({ ...updatedGame, token: generateGameToken(updatedGame) }));

    // show pending score values (only if a gameBoard is defined)
    game.gameBoard && dispatch(beginPreviewScores());
  };
};

export const spendAdditionalThrow = (additionalThrow: AdditionalThrow): AppThunk => {
  return (dispatch, getState) => {
    const { game } = getState();
    const { gameBoard, additionalThrows } = game;

    // compute new additionalThrows
    const updatedAdditionalThrows = additionalThrows.map((at) => {
      if (at.id === additionalThrow.id) {
        const used = at.used + 1;
        const canUse = used < at.max;
        return { ...at, canUse, used };
      }
      return at;
    });

    // update gameBoard maxNumberOfThrows due to using additional throw
    const updatedGameBoard = gameBoard ? { ...gameBoard, maxNumberOfThrows: gameBoard.throwIndex + 1 } : undefined;

    // update game with new additionalThrows
    dispatch(updateGame({ ...game, gameBoard: updatedGameBoard, additionalThrows: updatedAdditionalThrows }));
  };
};

export const scoreYamsBonus = (scoreSheet: ScoreSheet): AppThunk => {
  return (dispatch, getState) => {
    const updatedGame = udateGameWithYamsBonus(getState().game, scoreSheet);

    dispatch(updateGame(updatedGame));
  };
};

export const throwDice = (): AppThunk => {
  return (dispatch, getState) => {
    const { settings, game } = getState();
    const { gameBoard } = game;
    if (!gameBoard) {
      console.error("throwDice: no gameBoard");
      return;
    }

    // reset pending score values
    dispatch(cancelPreviewScores());

    // update gameBoard with isThrowingDice as true, and prepare dice set :
    //  if isPreserved : value doesn't change
    //  else : force generate different value than current (7 - current value)
    //        before getting new random value (in order to force animation dice rolling in Dice jsx component)
    const nextGameBoard = {
      ...gameBoard,
      isThrowingDice: true,
      dice: gameBoard.dice.map((d) => ({
        ...d,
        value: d.isPreserved ? d.value : 7 - (d.value ?? Math.ceil(Math.random() * 6)),
        isRolling: !d.isPreserved,
        isPreserved: settings[SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE] === true ? d.isPreserved : true,
      })),
    };

    dispatch(updateGameBoard(nextGameBoard));

    // generate random dice for each not preserved dice
    setTimeout(() => {
      const { gameBoard, rule } = getState().game;
      if (!gameBoard) {
        console.error("throwDice: no gameBoard");
        return;
      }

      // update gameBoard with random dice for each not preserved dice
      dispatch(updateGameBoard({ ...gameBoard, dice: getRandomDiceSet(gameBoard.dice, rule) }));

      // ... but in UI, dice is still rolling until ROLLING_DICE_DELAY is finished, I konw result before user, I'm GOD !
    }, ROLLING_DICE_DELAY / 3);
    setTimeout(() => {
      // end of rolling dice after ROLLING_DICE_DELAY
      const { gameBoard } = getState().game;
      if (!gameBoard) {
        console.error("throwDice: no gameBoard");
        return;
      }

      // update gameBoard with throwIndex and and isThrowingDice as false
      dispatch(
        updateGameBoard({
          ...gameBoard,
          throwIndex: gameBoard.throwIndex + 1,
          isThrowingDice: false,
          dice: gameBoard.dice.map((d) => ({ ...d, isRolling: false })),
        }),
      );

      // show pending score values
      dispatch(beginPreviewScores());
    }, ROLLING_DICE_DELAY);
  };
};

export const preserveADice = (dice: Dice, isPreserved: boolean): AppThunk => {
  return (dispatch, getState) => {
    const { gameBoard } = getState().game;

    if (!gameBoard) return;

    // compute new gameBoard dice set according to the isPreserved value on dice
    const diceSet = gameBoard.dice.map((d) => (d.index === dice.index ? { ...d, isPreserved } : d));

    dispatch(updateGameBoard({ ...gameBoard, dice: diceSet }));
  };
};

/* Save Game */
export const saveGameOnAccount = (cb?: (isSaved: boolean) => void): AppThunk => {
  return (dispatch, getState) => {
    const game = getState().game;
    const userId = getState().user.id;
    const isLogged = getState().user.con.isLogged;

    // check game token
    if (!checkGameToken(game)) {
      console.warn("!! Can't saveGameOnAccount: game token is invalid !!");
      cb && cb(false);
      return;
    }
    if (game.isSaved) {
      console.warn("!! Can't saveGameOnAccount: game is already saved !!");
      cb && cb(false);
      return;
    }
    if (game.status !== GAME_STATUS.OVER) {
      console.warn("!! Can't saveGameOnAccount: game is not over !!");
      cb && cb(false);
      return;
    }
    if (!isLogged || !userId) {
      console.warn("!! Can't saveGameOnAccount: user is not logged !!");
      cb && cb(false);
      return;
    }

    // Save Game on user account
    const game64 = generateGame64(game);
    const token = generateGameToSaveToken(game);
    GameService.saveGame({ userId, game64, token })
      .then((isSaved) => {
        dispatch(updateGame({ ...game, isSaved }));
        cb && cb(isSaved);
      })
      .catch((e) => {
        console.error("GameService.saveGame Error:", e);
        cb && cb(false);
      });
  };
};

/*** MOCKS ***/
export const mockGame = (game: Game): AppThunk => {
  return (dispatch) => {
    console.debug("MOCK GAME:", mockGame);
    dispatch(updateGame(game));
  };
};
