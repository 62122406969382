import React from "react";
import "./myamsPet.css";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ImgProps extends Record<string, any> {
  className?: string;
  color?: string;
  sayHello?: boolean;
}

export const MyamsPet = ({ sayHello, ...props }: ImgProps): JSX.Element => {
  return (
    <svg
      viewBox="-26 0 168 150"
      width={props.width ?? "128"}
      height={props.height ?? "116"}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g className="layer">
        <title>myams-icon</title>
        <g stroke={props.color ?? "#000"}>
          <title>legs</title>
          <line
            strokeLinecap="round"
            strokeWidth={props.strokeWidth ?? "5.00984px"}
            fill="rgba(0, 0, 0, 0)"
            x1="67.464"
            y1="114.879"
            x2="80.292"
            y2="146.436"
          />
          <line
            strokeLinecap="round"
            strokeWidth={props.strokeWidth ?? "5.00984px"}
            fill="rgba(0, 0, 0, 0)"
            x1="80.164"
            y1="146.628"
            x2="90.17"
            y2="146.371"
          />
          <line
            style={{ transformBox: "fill-box", transformOrigin: "50% 50%" }}
            strokeLinecap="round"
            strokeWidth={props.strokeWidth ?? "5.00984px"}
            fill="rgba(0, 0, 0, 0)"
            x1="20.128"
            y1="146.436"
            x2="32.956"
            y2="114.879"
            transform="matrix(-1, 0, 0, -1, -0.000002, -0.000001)"
          />
          <line
            strokeLinecap="round"
            strokeWidth={props.strokeWidth ?? "5.00984px"}
            fill="rgba(0, 0, 0, 0)"
            x1="9.427"
            y1="146.553"
            x2="19.433"
            y2="146.296"
          />
        </g>
        <g stroke={props.color ?? "#000"}>
          <title>arms</title>
          <g className={`right_arm${sayHello ? " sayHello" : ""}`}>
            <path
              strokeLinecap="round"
              strokeWidth={props.strokeWidth ?? "5.00984px"}
              fill="rgba(0, 0, 0, 0)"
              d="M 131.923 17.578 C 131.923 17.578 126.77839595507659 20.90788580529022 125.52 23.29 C 124.24876686271607 25.696414681240153 123.4332988640877 29.767508369808294 124.388 31.973 C 125.26732661801402 34.0043660718351 128.12414078286253 35.89601601147425 130.416 36.314 C 132.90745486231776 36.76838578111449 138.892 34.029 138.892 34.029"
            />
            <line
              strokeLinecap="round"
              strokeWidth={props.strokeWidth ?? "5.00984px"}
              fill="rgba(0, 0, 0, 0)"
              x1="106.719"
              y1="39.449"
              x2="134.941"
              y2="25.851"
            />
          </g>
          <line
            style={{ transformOrigin: "-10.665px 74.409px" }}
            strokeLinecap="round"
            strokeMiterlimit="5.02"
            strokeWidth={props.strokeWidth ?? "5.00984px"}
            fill="rgba(0, 0, 0, 0)"
            x1="-21.642"
            y1="73.549"
            x2="17.016"
            y2="77.541"
            transform="matrix(0.982902, -0.18413, 0.18413, 0.982902, 0, 0)"
          />

          <path
            style={{ transformOrigin: "-17.191px 74.903px" }}
            strokeLinecap="round"
            strokeMiterlimit="5.02"
            strokeWidth={props.strokeWidth ?? "5.00984px"}
            fill="rgba(0, 0, 0, 0)"
            d="M -20.855 66.79 C -20.855 66.79 -14.104976866131302 67.71804637735806 -11.936 69.47 C -9.89298410434531 71.12021082308337 -7.701165076125277 74.48185878968066 -7.947 76.706 C -8.189875049127352 78.90336234871886 -10.931309864311586 81.52808926362005 -13.289 82.748 C -15.941596973552508 84.12050077028498 -23.438 83.85 -23.438 83.85"
            transform="matrix(0.982902, -0.18413, 0.18413, 0.982902, 0, 0)"
          />
        </g>
        <g>
          <title>icon</title>
          <rect
            ry="10"
            rx="10"
            transform="matrix(0.906308, -0.422618, 0.422618, 0.906308, -21.051268, 33.04387)"
            id="svg_1"
            height="95"
            width="95"
            y="16.5"
            x="16.5"
            stroke="#000000"
            fill="#ffffff"
            strokeWidth="8"
          />
          <circle
            id="svg_3"
            r="11"
            cy="35.46338"
            cx="80.88818"
            strokeDasharray="null"
            stroke="#000000"
            fill="#000000"
            strokeWidth="0"
          />
          <circle
            id="svg_5"
            r="11"
            cy="48.39648"
            cx="31.54785"
            strokeDasharray="null"
            stroke="#000000"
            fill="#000000"
            strokeWidth="0"
          />
          <circle
            id="svg_6"
            r="11"
            cy="62.28027"
            cx="61.84375"
            strokeDasharray="null"
            stroke="#000000"
            fill="#000000"
            strokeWidth="0"
          />
          <circle
            id="svg_7"
            r="11"
            cy="94.19629"
            cx="64.5332"
            strokeDasharray="null"
            stroke="#000000"
            fill="#000000"
            strokeWidth="0"
          />
        </g>
        <g>
          <title>eyes</title>
          <circle cx="77.726" cy="37.397" r="4.618" fill="rgb(255, 255, 255)" strokeWidth="4.00787px;" />
          <circle cx="35.266" cy="49.135" r="4.618" fill="rgb(255, 255, 255)" strokeWidth="4.00787px;" />
          <circle cx="64.257" cy="94.314" r="4.618" fill="rgb(255, 255, 255)" strokeWidth="4.00787px;" />
        </g>
      </g>
    </svg>
  );
};
