import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../configureStore";

import { injectAuthToken, rejectAuthToken } from "../../services/axiosConfig";
import AuthService from "../../services/authService";
import UserService from "../../services/userService";

import { User, UserCon, UserProfile, UserPrompt, UserUpdate } from "../../common/types";
import { resetUrl } from "../../common/utils/appUtils";
import {
  checkAvatarField,
  checkLoginFields,
  checkRegistrationFields,
  checkRequestPasswordResetEmailField,
  checkValidateNewPasswordFields,
} from "../../common/utils/userUtils";
import { ERRORS } from "../../common/messages/userAccount";

const initialState: User = {
  id: null,
  pseudo: null,
  email: null,
  avatar: null,
  isActivated: false,
  createdAt: null,
  con: {
    isLogged: false,
    isLogging: false,
    isLoggingOut: false,
    isRegistering: false,
    authError: undefined,
  },
  prompt: {
    isPromptedToActivateAccount: false,
    isActivating: false,
    activationId: null,
    isPromptedToResetPassword: false,
    isResettingPassword: false,
    passwordResetId: null,
    passwordResetPseudo: null,
  },
  updt: {
    isSendingActivationEmail: false,
    isUpdatingAvatar: false,
    isUpdatingEmail: false,
    isUpdatingPassword: false,
    isRequestingPasswordReset: false,
    isRemovingAccount: false,
  },
};

type UpdateConPayload = {
  key: keyof UserCon;
  value: UserCon[keyof UserCon];
};
type UpdatePromptPayload = {
  key: keyof UserPrompt;
  value: UserPrompt[keyof UserPrompt];
};
type UpdateUpdatePayload = {
  key: keyof UserUpdate;
  value: UserUpdate[keyof UserUpdate];
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserCon: (state, action: PayloadAction<UpdateConPayload>) => {
      state.con = {
        ...state.con,
        [action.payload.key]: action.payload.value,
      };
    },
    updateUserPrompt: (state, action: PayloadAction<UpdatePromptPayload>) => {
      state.prompt = {
        ...state.prompt,
        [action.payload.key]: action.payload.value,
      };
    },
    updateUserUpdt: (state, action: PayloadAction<UpdateUpdatePayload>) => {
      state.updt[action.payload.key] = action.payload.value;
    },
    resetUserPrompt: (state) => {
      state.prompt = initialState.prompt;
    },
    resetUser: () => initialState,
    resetUserProfile: (state) => {
      return {
        ...state,
        pseudo: null,
        email: null,
        avatar: null,
        isActivated: false,
        createdAt: null,
      };
    },
    setUserProfile: (state, action: PayloadAction<UserProfile>) => {
      return {
        ...state,
        ...action.payload,
        con: {
          ...initialState.con,
          isLogged: true,
        },
      };
    },
    setPseudo: (state, action: PayloadAction<string | null>) => {
      state.pseudo = action.payload;
    },
  },
});

export default userSlice.reducer;

const { updateUserCon, updateUserPrompt, updateUserUpdt, resetUser, resetUserProfile, setUserProfile } =
  userSlice.actions;
export const { resetUserPrompt } = userSlice.actions;

export const checkAccountPromptUrl = (urlParams: string): AppThunk => {
  return (dispatch) => {
    // account activation link
    if (urlParams.startsWith("/user_account_activation")) {
      const elems = urlParams.split("/");
      const activationId = elems[elems.length - 1];
      dispatch(updateUserPrompt({ key: "isPromptedToActivateAccount", value: true }));
      dispatch(updateUserPrompt({ key: "activationId", value: activationId }));
    }
    //  user password reset link
    else if (urlParams.startsWith("/user_password_reset")) {
      const elems = urlParams.split("/");
      const passwordResetId = elems[elems.length - 1];
      const passwordResetPseudo = elems[elems.length - 2];
      dispatch(updateUserPrompt({ key: "isPromptedToResetPassword", value: true }));
      dispatch(updateUserPrompt({ key: "passwordResetId", value: passwordResetId }));
      dispatch(updateUserPrompt({ key: "passwordResetPseudo", value: passwordResetPseudo }));
    }
    // else reset url
    else {
      resetUrl();
    }
  };
};

/*** auth error ***/
export const clearAuthError = (): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "authError", value: undefined }));
  };
};

/*** Register ***/
export const register = (pseudo?: string, email?: string, password?: string, passwordConf?: string): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "authError", value: undefined }));

    // check fields
    const errors = checkRegistrationFields(pseudo, email, password, passwordConf);
    if (errors.length > 0) {
      dispatch(updateUserCon({ key: "authError", value: errors[0].text }));
    } else if (!pseudo || !email || !password || !passwordConf) {
      // catched by checkRegistrationFields(pseudo, email, password, passwordConf)
    }

    // try to register user
    else {
      dispatch(updateUserCon({ key: "isRegistering", value: true }));
      // call service
      AuthService.resgister(pseudo, email, password, passwordConf)
        .then(({ user, access_token }) => {
          injectAuthToken(access_token);
          dispatch(setUserProfile(user));
        })
        .catch((error) => {
          dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
        })
        .finally(() => {
          dispatch(updateUserCon({ key: "isRegistering", value: false }));
        });
    }
  };
};

export const activateUserAccount = (activationId?: string): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "authError", value: undefined }));

    // check fields
    if (!activationId) {
      dispatch(updateUserCon({ key: "authError", value: ERRORS.ACTIVATION_ID }));
    }

    // activate account
    else {
      dispatch(updateUserPrompt({ key: "isActivating", value: true }));
      // call service
      AuthService.activateAccount(activationId)
        .then(({ user, access_token }) => {
          injectAuthToken(access_token);
          dispatch(setUserProfile(user));
        })
        .catch((error) => {
          dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
        })
        .finally(() => {
          dispatch(updateUserPrompt({ key: "isActivating", value: false }));
        });
    }
  };
};

/*** Login ***/
export const login = (pseudo?: string, password?: string): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "authError", value: undefined }));

    // check fields
    const errors = checkLoginFields(pseudo, password);
    if (errors.length > 0) {
      dispatch(updateUserCon({ key: "authError", value: errors[0].text }));
    } else if (!pseudo || !password) {
      // catched by checkLoginFields(pseudo, password)
    }

    // try to login user
    else {
      dispatch(updateUserCon({ key: "isLogging", value: true }));
      // call service
      AuthService.login(pseudo, password)
        .then(({ user, access_token }) => {
          injectAuthToken(access_token);
          dispatch(setUserProfile(user));
        })
        .catch((error) => {
          dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
        })
        .finally(() => {
          dispatch(updateUserCon({ key: "isLogging", value: false }));
        });
    }
  };
};

export const requestPasswordReset = (email?: string, cb?: () => void): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "authError", value: undefined }));

    // check fields
    const errors = checkRequestPasswordResetEmailField(email);
    if (errors.length > 0) {
      dispatch(updateUserCon({ key: "authError", value: errors[0].text }));
    } else if (!email) {
      // catched by checkRequestPasswordResetEmailField(email)
    }

    // request password reset
    else {
      dispatch(updateUserUpdt({ key: "isRequestingPasswordReset", value: true }));
      // call service
      AuthService.requestPasswordReset(email)
        .then(() => {
          cb?.();
        })
        .catch((error) => {
          dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
        })
        .finally(() => {
          dispatch(updateUserUpdt({ key: "isRequestingPasswordReset", value: false }));
        });
    }
  };
};

export const saveNewPassword = (
  password?: string,
  passwordConf?: string,
  passwordResetId?: string,
  cb?: (isReseted: boolean) => void,
): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "authError", value: undefined }));

    // check fields
    const errors = checkValidateNewPasswordFields(password, passwordConf, passwordResetId);
    if (errors.length > 0) {
      dispatch(updateUserCon({ key: "authError", value: errors[0].text }));
    } else if (!password || !passwordConf || !passwordResetId) {
      // catched by checkValidateNewPasswordFields(password, passwordConf, passwordResetId)
    }

    // save new password
    else {
      dispatch(updateUserPrompt({ key: "isResettingPassword", value: true }));
      // call service
      AuthService.saveNewPassword(password, passwordResetId)
        .then(({ user, access_token }) => {
          injectAuthToken(access_token);
          dispatch(setUserProfile(user));
          cb && cb(true);
        })
        .catch((error) => {
          dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
          cb && cb(false);
        })
        .finally(() => {
          dispatch(updateUserPrompt({ key: "isResettingPassword", value: false }));
        });
    }
  };
};

export const logout = (): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "isLoggingOut", value: true }));
    AuthService.logout().then(() => {
      rejectAuthToken();
      dispatch(resetUser());
    });
  };
};

export const deleteAccount = (): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserUpdt({ key: "isRemovingAccount", value: true }));
    AuthService.deleteAccount().then(() => {
      rejectAuthToken();
      dispatch(resetUser());
    });
  };
};

/*** Profile ***/
export const loadProfile = (): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserCon({ key: "isLogging", value: true }));
    // call service
    UserService.loadProfile()
      .then((userProfile) => {
        dispatch(setUserProfile(userProfile));
      })
      .catch(() => {
        dispatch(resetUserProfile());
      })
      .finally(() => {
        dispatch(updateUserCon({ key: "isLogging", value: false }));
      });
  };
};

export const updateAvatar = (avatar: File): AppThunk => {
  return (dispatch) => {
    // check avatar
    const errors = checkAvatarField(avatar);
    if (errors.length > 0) {
      dispatch(updateUserCon({ key: "authError", value: errors[0].text }));
    }

    // save new avatar
    else {
      dispatch(updateUserUpdt({ key: "isUpdatingAvatar", value: true }));
      // call service
      UserService.updateAvatar(avatar)
        .then((userProfile) => {
          dispatch(setUserProfile(userProfile));
        })
        .catch((error) => {
          dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
        })
        .finally(() => {
          dispatch(updateUserUpdt({ key: "isUpdatingAvatar", value: false }));
        });
    }
  };
};

export const removeAvatar = (): AppThunk => {
  return (dispatch) => {
    dispatch(updateUserUpdt({ key: "isUpdatingAvatar", value: true }));
    // call service
    UserService.removeAvatar()
      .then((userProfile) => {
        dispatch(setUserProfile(userProfile));
      })
      .catch((error) => {
        dispatch(updateUserCon({ key: "authError", value: error.response.data.message }));
      })
      .finally(() => {
        dispatch(updateUserUpdt({ key: "isUpdatingAvatar", value: false }));
      });
  };
};
