import React, { useEffect } from "react";
import { GAME_STATUS } from "../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectGameMode, selectGameRule, startGame } from "../../../redux/features/game/gameSlice";

import { Button } from "../../../components/forms";
import { ImgPlay } from "../../../common/img/common";
import Mobile_GameView from "../../mobile/game/GameView";
import AccountBar from "./accountBar";
import HomeMenu from "./homeMenu/HomeMenu";
import Welcome from "./welcome/Welcome";
import TopScores from "./topScores/TopScores";

import "./home.css";

const Home = (): JSX.Element => {
  const gameRules = useAppSelector((state) => state.gameRules);
  const gameModes = useAppSelector((state) => state.gameModes);
  const gameStatus = useAppSelector((state) => state.game.status);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // Select MYAMS rule
    gameStatus === GAME_STATUS.INITIAL && dispatch(selectGameRule(gameRules[0]));
  }, [gameRules]);

  useEffect(() => {
    // Select SIMULATION mode
    gameStatus === GAME_STATUS.INITIAL && dispatch(selectGameMode(gameModes[0]));
  }, [gameModes]);

  return (
    <>
      {/*** Header ***/}
      <div className={`mobile_header_container${gameStatus !== GAME_STATUS.INITIAL ? " in-game" : ""}`}>
        {/* Account bar */}
        <AccountBar gameStatus={gameStatus} />
        {/* Menu */}
        {gameStatus === GAME_STATUS.INITIAL && <HomeMenu />}
      </div>

      {gameStatus !== GAME_STATUS.INITIAL ? (
        /*** Game View ***/
        <Mobile_GameView />
      ) : (
        /*** Home Welcome ***/
        <div className="mobile_home_container">
          <div className="mobile_home_content">
            {/* Welcome */}
            <Welcome className="mobile_home_welcome" />

            {/* Start game button */}
            <Button
              className="mobile_start_game_button"
              img={<ImgPlay />}
              title="Jouer"
              onClick={() => dispatch(startGame())}
            />
          </div>

          {/* Top scores */}
          <TopScores />
        </div>
      )}
    </>
  );
};

export default Home;
