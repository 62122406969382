import React, { useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {
  cancelLastScore,
  newGame,
  spendAdditionalThrow,
  startGame,
} from "../../../../../redux/features/game/gameSlice";

import { ACTION_ITEM, MENU_ITEM } from "../../../../../common/enums";
import { isOnMobile } from "../../../../../common/utils/appUtils";

import { ActionButton, ModalConfirmationView, ModalView } from "../../../../../components/ui";
import GameRuleDetails from "../../../../rulesOfTheGame";
import Settings from "../../../settings";

import { ImgDotsMenu, ImgPlusOne, ImgRules, ImgSettings, ImgUndo } from "../../../../../common/img/common";
import "./actionsBar.css";

interface ActionsBarProps {
  className?: string;
  actionItems: ACTION_ITEM[];
  menuItems: MENU_ITEM[];
}

const ActionsBar = ({ className, actionItems, menuItems }: ActionsBarProps): JSX.Element | null => {
  const { rule, progress, scoreSheets, gameBoard, additionalThrows } = useAppSelector((state) => state.game);
  if (!gameBoard) return null;

  const { throwIndex, maxNumberOfThrows, isThrowingDice } = gameBoard;
  const additionalThrow = additionalThrows
    ? additionalThrows.find((at) => scoreSheets.find((s) => s.isCurrent === true)?.id === at.id)
    : undefined;
  const canCancelLastScore = throwIndex === 0 && !isThrowingDice && progress > 0 && progress < 1;
  const canUseAdditionalThrow =
    additionalThrow && additionalThrow?.canUse === true && (!gameBoard || throwIndex === maxNumberOfThrows);

  const dispatch = useAppDispatch();

  const [showRules, setShowRules] = useState<boolean>(false);
  const [showSettings, setShowSettings] = useState<boolean>(false);
  const [showReplayGamePopup, setShowReplayGamePopup] = useState<boolean>(false);
  const [showLeaveGamePopup, setShowLeaveGamePopup] = useState<boolean>(false);
  const [showUseAdditionalThrowPopup, setShowUseAdditionalThrowPopup] = useState<boolean>(false);

  return (
    <div className={`mobile_actionsBar_container ${className ?? ""}`}>
      {actionItems.map((actionItem: ACTION_ITEM, i: number) => (
        <React.Fragment key={actionItem}>
          <ActionButton
            id={actionItem}
            disabled={
              (actionItem === ACTION_ITEM.CANCEL_LAST_SCORE && !canCancelLastScore) ||
              (actionItem === ACTION_ITEM.UNLOCK_EXTRA_THROW && !canUseAdditionalThrow)
            }
            content={
              <>
                {actionItem === ACTION_ITEM.MENU && <ImgDotsMenu />}
                {actionItem === ACTION_ITEM.CANCEL_LAST_SCORE && <ImgUndo />}
                {actionItem === ACTION_ITEM.UNLOCK_EXTRA_THROW && (
                  <>
                    <ImgPlusOne />
                    {additionalThrow?.used !== undefined && additionalThrow?.max !== undefined && (
                      <span style={{ userSelect: "none", fontSize: "0.7rem" }}>
                        ({additionalThrow.used}/{additionalThrow.max})
                      </span>
                    )}
                  </>
                )}
              </>
            }
            menuItems={actionItem === ACTION_ITEM.MENU ? menuItems : undefined}
            onTouch={() => {
              switch (actionItem) {
                case ACTION_ITEM.CANCEL_LAST_SCORE:
                  dispatch(cancelLastScore());
                  break;
                case ACTION_ITEM.UNLOCK_EXTRA_THROW:
                  setShowUseAdditionalThrowPopup(true);
                  break;
                default:
                  break;
              }
            }}
            onTouchMenuItem={(menuItem: MENU_ITEM) => {
              switch (menuItem) {
                case MENU_ITEM.REPLAY:
                  setShowReplayGamePopup(true);
                  break;
                case MENU_ITEM.GIVE_UP:
                  setShowLeaveGamePopup(true);
                  break;
                case MENU_ITEM.SETTINGS:
                  setShowSettings(true);
                  break;
                case MENU_ITEM.RULES:
                  setShowRules(true);
                  break;
                case MENU_ITEM.HELP:
                  console.log("HELP TODO");
                  break;
                case MENU_ITEM.INFOS:
                  console.log("INFOS TODO");
                  break;
                default:
                  break;
              }
            }}
          />
          {/* Separation */}
          {i + 1 < actionItems.length && <div className="action_bar_separation"></div>}
        </React.Fragment>
      ))}

      {/*** Modals ***/}

      {/* Rules */}
      {showRules && (
        <ModalView
          modalStyle={{ maxWidth: "1200px", width: "100%", height: "80%" }}
          modalContentStyle={{ padding: "20px 0 16px 16px" }}
          img={<ImgRules />}
          title="RÈGLES DU JEU"
          canCloseByClickingOutside={false}
          onClose={() => setShowRules(false)}
        >
          <GameRuleDetails rule={rule} />
        </ModalView>
      )}

      {/* Settings */}
      {showSettings && (
        <ModalView
          modalStyle={{ maxWidth: "1200px", width: "100%", maxHeight: "75%" }}
          modalContentStyle={{ padding: "0" }}
          img={<ImgSettings />}
          title="PRÉFÉRENCES"
          canCloseByClickingOutside={false}
          onClose={() => setShowSettings(false)}
        >
          <Settings />
        </ModalView>
      )}

      {/* Replay game */}
      {showReplayGamePopup && (
        <ModalConfirmationView
          canCloseByClickingOutside={false}
          text="Recommencer la partie ?"
          onConfirm={() => {
            dispatch(newGame(isOnMobile()));
            dispatch(startGame());
            setShowReplayGamePopup(false);
          }}
          onClose={() => setShowReplayGamePopup(false)}
        />
      )}

      {/* Leave game */}
      {showLeaveGamePopup && (
        <ModalConfirmationView
          canCloseByClickingOutside={false}
          text="Abandonner la partie en cours ?"
          onConfirm={() => {
            dispatch(newGame(isOnMobile()));
            setShowLeaveGamePopup(false);
          }}
          onClose={() => setShowLeaveGamePopup(false)}
        />
      )}

      {/* Use additional throw */}
      {showUseAdditionalThrowPopup && (
        <ModalConfirmationView
          canCloseByClickingOutside={false}
          text="Débloquer un lancer supplémentaire ?"
          onConfirm={() => {
            additionalThrow && dispatch(spendAdditionalThrow(additionalThrow));
            setShowUseAdditionalThrowPopup(false);
          }}
          onClose={() => setShowUseAdditionalThrowPopup(false)}
        />
      )}
    </div>
  );
};

export default ActionsBar;
