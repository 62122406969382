import axios from "axios";

const instance = axios.create({
  baseURL: "/",
});

const authToken = localStorage.getItem("authToken");
if (authToken) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
}

export function injectAuthToken(authToken: string): void {
  localStorage.setItem("authToken", authToken);
  instance.defaults.headers.common["Authorization"] = `Bearer ${authToken}`;
}
export function rejectAuthToken(): void {
  localStorage.removeItem("authToken");
  delete instance.defaults.headers.common["Authorization"];
}

export default instance;
