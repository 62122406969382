import { Dice, GameBoard, GameRule } from "../core/types";

/**
 * Generates a game board based on the given game rule.
 *
 * @param {GameRule} rule - The game rule to generate the game board.
 * @return {GameBoard} - The generated game board.
 */
export const generateGameBoard = (rule: GameRule): GameBoard => {
  const { numberOfDice, maxNumberOfThrows } = rule;
  // generate set of dice
  const dice: Dice[] = [];
  for (let i = 1; i <= numberOfDice; i++) {
    dice.push({
      index: i,
      value: null,
      valueString: null,
      isPreserved: false,
      isRolling: false,
    });
  }
  // return game board
  return {
    throwIndex: 0,
    maxNumberOfThrows,
    isThrowingDice: false,
    dice,
  };
};

/**
 * Generates a new random dice set based on the provided dice set and game rule.
 *
 * @param {Dice[]} diceSet - The original dice set.
 * @param {GameRule} rule - The game rule that specifies the number of dice faces.
 * @return {Dice[]} - The new dice set with values randomly generated based on the game rule.
 */
export const getRandomDiceSet = (diceSet: Dice[], rule: GameRule): Dice[] => {
  const { numberOfDiceFaces } = rule;
  return diceSet.map((dice) => {
    if (dice.isPreserved === false) {
      const value = Math.ceil(Math.random() * numberOfDiceFaces);
      return { ...dice, value };
    }
    return dice;
  });
};
