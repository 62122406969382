import { INDICATOR_KEY, ROW_TYPE } from "../enums";
import { Indicator } from "../types";

export const INDICATOR: Record<INDICATOR_KEY, Indicator> = {
  [INDICATOR_KEY.BONUS_INDICATOR]: {
    key: INDICATOR_KEY.BONUS_INDICATOR,
    type: ROW_TYPE.INDICATOR,
    name: "Indicateur de bonus",
    shortName: "Indicateur de bonus",
    longName: "Indicateur de bonus",
    description: [
      "Cet indicateur affiche le nombre de points d'avance ou de retard pour obtenir le bonus",
      "dans l'éventualité de réaliser 3 dés similaires sur chaque autre score restant à inscrire dans la partie haute.",
    ],
    minNumberOfDiceForBonus: 3,
  },
};
