import React, { useEffect, useState } from "react";
import { GAME_STATUS } from "../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { VERSIONS, computePersistedVersion } from "../../../redux/customMigration";
import { newGame } from "../../../redux/features/game/gameSlice";

import { MyamsPet } from "../../../common/img/myamsPet/myamsPet";
import { Button } from "../../../components/forms";

import "./updatePrompt.css";

const UpdatePrompt = (): JSX.Element | null => {
  const gameStatus = useAppSelector((state) => state.game.status);

  const dispatch = useAppDispatch();

  const [promptNewAppVersion, setPromptNewAppVersion] = useState<boolean>(false);

  const version = computePersistedVersion();
  const hardResetVersions = [VERSIONS.V1_1_0];
  const isHardReset = version < hardResetVersions.reduce((prev, curr) => Math.max(curr.valueOf(), prev), 0);

  const onReceiveNewAppVersion = (): void => {
    setPromptNewAppVersion(true);
  };

  useEffect(() => {
    navigator.serviceWorker.addEventListener("controllerchange", onReceiveNewAppVersion);
    return () => navigator.serviceWorker.removeEventListener("controllerchange", onReceiveNewAppVersion);
  }, []);

  return !promptNewAppVersion ? null : (
    <div className="updatePrompt_container">
      <MyamsPet color="#fff" width={46} height={46} sayHello={true} />
      <div className="update_label_container">
        <div>
          Une <span style={{ fontWeight: "900" }}>mise à jour</span> de l&apos;appliction est disponible.
        </div>
        <div>Souhaitez-vous l&apos;installer ?</div>
        {gameStatus === GAME_STATUS.ONGOING && (
          <>
            {isHardReset ? (
              <div className="update_label_hard">La partie en cours sera perdue !</div>
            ) : (
              <div className="update_label_soft">La partie en cours sera conservée</div>
            )}
          </>
        )}
      </div>
      <div className="update_buttons">
        <Button
          className="update_button_no"
          title="Plus tard"
          type="link"
          onClick={() => setPromptNewAppVersion(false)}
        />
        <Button
          className="update_button_yes"
          title="INSTALLER"
          onClick={() => {
            if (isHardReset) {
              dispatch(newGame());
            }
            window.location.reload();
          }}
        />
      </div>
    </div>
  );
};

export default UpdatePrompt;
