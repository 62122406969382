import React from "react";
import { GAME_STATUS } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { cancelLastScore } from "../../../../../redux/features/game/gameSlice";

import { ActionRow } from "../../../../../components/game";

import { cancelLastScoreInfoLines } from "../../../../../common/messages/game";
import { ImgUndo } from "../../../../../common/img/common";

import "./gameHistory.css";

const GameHistory = (): JSX.Element => {
  const game = useAppSelector((state) => state.game);
  const { mode, pastScoreSheets, gameBoard, history } = game;
  const canCancelLastScore =
    pastScoreSheets.length > 0 &&
    (!gameBoard || (gameBoard.throwIndex === 0 && !gameBoard.isThrowingDice && game.status !== GAME_STATUS.OVER));

  const dispatch = useAppDispatch();

  return (
    <div className="gameHistory_container">
      {/* last action */}
      <div className="gameHistory_last_score_info">
        {history.scores[0].map((line, index) => (
          <div key={index}>{line}</div>
        ))}
      </div>
      {/* action cancel last score */}
      {pastScoreSheets && (
        <ActionRow
          className={mode.key + (canCancelLastScore ? "" : " disabled")}
          onClickAction={() => dispatch(cancelLastScore())}
          img={<ImgUndo className="img_undo" />}
          title="Annuler ce score"
          infoLines={cancelLastScoreInfoLines(mode)}
        />
      )}
    </div>
  );
};

export default GameHistory;
