/**
 * Checks if there are multiple occurrences of the same value in an array.
 *
 * @param values - The array of values to check.
 * @param n - The minimum number of occurrences required.
 * @param strict - If true, only values with exactly n occurrences are considered.
 * @returns true if there are at least n occurrences of the same value, false otherwise.
 */
export const hasMultipleOfAKind = (values: number[], n: number, strict?: boolean): boolean => {
  let hasIt = false;

  values.forEach((v) => {
    const sameValues = values.filter((value) => value === v);

    if ((!strict && sameValues.length >= n) || sameValues.length === n) {
      hasIt = true;
    }
  });

  return hasIt;
};

/**
 * Checks if there are n values that follow each other in the array
 *
 * @param values - The array of numbers
 * @param n - The number of values to check if they follow each other
 * @returns true if there are n values that follow each other, otherwise false
 */
export const hasNValuesThatFollowEachOther = (values: number[], n: number): boolean => {
  const sortedValues = values.sort().filter((v, i, arr) => v !== arr[i + 1]);
  if (sortedValues.length >= n) {
    let hasNext = true;
    let i = 0;
    while (hasNext === true && i < n - 1) {
      const value = sortedValues[i];
      hasNext = sortedValues[i + 1] === value + 1;
      i++;
    }
    if (!hasNext && sortedValues.length >= n) {
      sortedValues.shift();
      return hasNValuesThatFollowEachOther(sortedValues, n);
    }
    return hasNext;
  }
  return false;
};

/**
 * Returns the value that exists at least n times in the values array
 * or exactly n times if strict is true.
 *
 * @param values - The array of numbers to search for the value.
 * @param n - The minimum number of times the value must exist in the array.
 * @param strict - If true, the value must exist exactly n times; if false, it can exist at least n times.
 * @returns The value that exists at least n times in the array, or null if no such value exists.
 */
export const getValueExistingNTimes = (values: number[], n: number, strict?: boolean): number | null => {
  let value = null;
  values.forEach((v) => {
    const sameValues = values.filter((value) => value === v);
    if ((!strict && sameValues.length >= n) || sameValues.length === n) {
      value = v;
    }
  });
  return value;
};

/**
 * Calculates the sum of the values in an array.
 *
 * @param {number[]} values - The array of numbers to be summed.
 * @return {number} The sum of the values in the array. Returns 0 if the array is empty or falsy.
 */
export const sumOfTheValues = (values: number[]): number => {
  return values && values.length > 0 ? values.reduce((acc, curr) => acc + curr) : 0;
};

/**
 * Checks if there is three similar numbers in the given array of numbers.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if there is three similar numbers, otherwise returns false.
 */
export const hasThreeOfAKind = (values: number[]): boolean => {
  return hasMultipleOfAKind(values, 3);
};

/**
 * Checks if there is four similar numbers in the given array of numbers.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if there is four similar numbers, otherwise returns false.
 */
export const hasFourOfAKind = (values: number[]): boolean => {
  return hasMultipleOfAKind(values, 4);
};

/**
 * Checks if there is a full house in the given array of numbers.
 * (3 same values of kind and 2 same values of other kind)
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if there is a full house, false otherwise.
 */
export const hasFullHouse = (values: number[]): boolean => {
  if (hasMultipleOfAKind(values, 3, true)) {
    const excludedValue = getValueExistingNTimes(values, 3, true);
    const otherValues = values.filter((v) => v !== excludedValue);
    return hasMultipleOfAKind(otherValues, 2, true);
  }
  return false;
};

/**
 * Checks if there is a small straight in the given array of numbers.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if there is a small straight, false otherwise.
 */
export const hasSmallStraight = (values: number[]): boolean => {
  return hasNValuesThatFollowEachOther(values, 4);
};

/**
 * Checks if there is a large straight in the given array of numbers.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if there is a large straight, false otherwise.
 */
export const hasLargeStraight = (values: number[]): boolean => {
  return hasNValuesThatFollowEachOther(values, 5);
};

/**
 * Checks if there is a Yams in the given array of numbers.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if there is a Yams, false otherwise.
 */
export const hasYams = (values: number[]): boolean => {
  return hasMultipleOfAKind(values, 5);
};

/**
 * Calculates the low chance value based on the given array of values and the high chance threshold.
 *
 * @param {number[]} values - An array of numbers.
 * @param {number} highChance - The high chance threshold.
 * @returns {number} The low chance value.
 */
export const getLowChanceValue = (values: number[], highChance: number): number => {
  return !highChance || sumOfTheValues(values) < highChance ? sumOfTheValues(values) : 0;
};

/**
 * Calculates the high chance value based on the given array of values and the low chance threshold.
 *
 * @param {number[]} values - An array of numbers.
 * @param {number} lowChance - The low chance threshold.
 * @returns {number} The high chance value.
 */
export const getHighChanceValue = (values: number[], lowChance: number): number => {
  return !lowChance || sumOfTheValues(values) > lowChance ? sumOfTheValues(values) : 0;
};

/**
 * Checks if the sum of the values in the given array is less than 11.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if the sum of the values is less than 11, false otherwise.
 */
export const hasUnderEleven = (values: number[]): boolean => {
  return sumOfTheValues(values) < 11;
};

/**
 * Checks if all the values in the array are even numbers.
 *
 * @param {number[]} values - The array of numbers to check.
 * @return {boolean} true if all values are even numbers, false otherwise.
 */
export const hasOnlyPairs = (values: number[]): boolean => {
  return values.every((v) => v % 2 === 0);
};
