import { ApiCallback, fetchApi, METHODS } from "./api";

const UserService = {
  hasSessionCookie: (cb: ApiCallback): void => {
    fetchApi("/user/has_session_cookie", METHODS.GET, null, null, cb);
  },
  register: (pseudo: string, email: string, password: string, cb: ApiCallback): void => {
    const body = { pseudo, email, password };
    fetchApi("/user/register", METHODS.PUT, body, null, cb);
  },
  sendAgainAccountActivationEmail: (email: string, cb: ApiCallback): void => {
    const body = { email };
    fetchApi("/user/send_activation_email", METHODS.POST, body, null, cb);
  },
  updateEmail: (email: string, cb: ApiCallback): void => {
    const body = { email };
    fetchApi("/user/update_email", METHODS.POST, body, null, cb);
  },
  activateUserAccount: (pseudo: string, password: string, activationId: string, cb: ApiCallback): void => {
    const body = { pseudo, password, activationId };
    fetchApi("/user/activate_account", METHODS.POST, body, null, cb);
  },
  login: (pseudo: string, password: string, cb: ApiCallback): void => {
    const body = { pseudo, password };
    fetchApi("/user/login", METHODS.POST, body, null, cb);
  },
  loadProfile: (cb: ApiCallback): void => {
    fetchApi("/user/profile", METHODS.GET, null, null, cb);
  },
  uploadAvatar: (avatar: File, cb: ApiCallback): void => {
    const formData = new FormData();
    formData.append("avatar", avatar);
    fetchApi("/user/upload_avatar", METHODS.POST, formData, {}, cb);
  },
  removeAvatar: (cb: ApiCallback): void => {
    fetchApi("/user/remove_avatar", METHODS.POST, null, null, cb);
  },
  updatePassword: (password: string, newPassword: string, cb: ApiCallback): void => {
    const body = { password, newPassword };
    fetchApi("/user/update_password", METHODS.POST, body, null, cb);
  },
  requestPasswordReset: (email: string, cb: ApiCallback): void => {
    const params = { email };
    fetchApi("/user/request_reset_password", METHODS.GET, params, null, cb);
  },
  validateNewPassword: (newPassword: string, passwordResetId: string, cb: ApiCallback): void => {
    const body = { newPassword, passwordResetId };
    fetchApi("/user/validate_new_password", METHODS.POST, body, null, cb);
  },
  logout: (cb: ApiCallback): void => {
    fetchApi("/user/logout", METHODS.GET, null, null, cb);
  },
  removeAccount: (cb: ApiCallback): void => {
    fetchApi("/user/remove_account", METHODS.DELETE, null, null, cb);
  },
};

export default UserService;
