import * as React from "react";
import { Dice } from "../../../../game-box/core/types";

import { canTouch } from "../../../../common/utils/appUtils";
import { ImgLockedCircle, ImgRefreshCircle } from "../../../../common/img/common";

import "./dice.css";
import "./diceYamsAnim.css";

interface DiceProps {
  dice: Dice;
  isFrozen?: boolean;
  isThrowingDices: boolean;
  yamsAnim: boolean;
  clickOnDiceToPreserve: boolean;
  showIndicator: boolean;
  onClick: () => void;
}

const DiceItem = ({
  dice,
  isFrozen,
  isThrowingDices,
  yamsAnim,
  clickOnDiceToPreserve,
  showIndicator,
  onClick,
}: DiceProps): JSX.Element => {
  const { value, isPreserved, isRolling } = dice;

  const onClickDice = () => value && !isThrowingDices && !isFrozen && onClick();

  return (
    <div
      className="dice_container"
      onTouchStart={canTouch() ? onClickDice : undefined}
      onClick={!canTouch() ? onClickDice : undefined}
    >
      {dice.value ? (
        <>
          <div
            className={
              `dice show-${value}` +
              `${clickOnDiceToPreserve && isPreserved ? " isPreserved" : ""}` +
              `${!clickOnDiceToPreserve && !isPreserved ? " isToThrow" : ""}` +
              `${isRolling ? " isRolling" : ""}` +
              `${isFrozen ? " isFrozen" : ""}` +
              `${yamsAnim ? " yamsAnim" : ""}`
            }
          >
            <div className="side one">
              <div className="dot one-1"></div>
            </div>
            <div className="side two">
              <div className="dot two-1"></div>
              <div className="dot two-2"></div>
            </div>
            <div className="side three">
              <div className="dot three-1"></div>
              <div className="dot three-2"></div>
              <div className="dot three-3"></div>
            </div>
            <div className="side four">
              <div className="dot four-1"></div>
              <div className="dot four-2"></div>
              <div className="dot four-3"></div>
              <div className="dot four-4"></div>
            </div>
            <div className="side five">
              <div className="dot five-1"></div>
              <div className="dot five-2"></div>
              <div className="dot five-3"></div>
              <div className="dot five-4"></div>
              <div className="dot five-5"></div>
            </div>
            <div className="side six">
              <div className="dot six-1"></div>
              <div className="dot six-2"></div>
              <div className="dot six-3"></div>
              <div className="dot six-4"></div>
              <div className="dot six-5"></div>
              <div className="dot six-6"></div>
            </div>
          </div>
          {showIndicator &&
            !isRolling &&
            !isFrozen &&
            ((clickOnDiceToPreserve && isPreserved) || (!clickOnDiceToPreserve && !isPreserved)) && (
              <div className="dice_indicator">
                {clickOnDiceToPreserve && isPreserved && <ImgLockedCircle style={{ stroke: "none" }} />}
                {!clickOnDiceToPreserve && !isPreserved && <ImgRefreshCircle />}
              </div>
            )}
        </>
      ) : (
        <div className="dice myams">
          <div className="side one">
            <div className="dot myams-1"></div>
            <div className="dot myams-2"></div>
            <div className="dot myams-3"></div>
            <div className="dot myams-4"></div>
          </div>
          <div className="side two">
            <div className="dot myams-1"></div>
            <div className="dot myams-2"></div>
            <div className="dot myams-3"></div>
            <div className="dot myams-4"></div>
          </div>
          <div className="side three">
            <div className="dot myams-1"></div>
            <div className="dot myams-2"></div>
            <div className="dot myams-3"></div>
            <div className="dot myams-4"></div>
          </div>
          <div className="side four">
            <div className="dot myams-1"></div>
            <div className="dot myams-2"></div>
            <div className="dot myams-3"></div>
            <div className="dot myams-4"></div>
          </div>
          <div className="side five">
            <div className="dot myams-1"></div>
            <div className="dot myams-2"></div>
            <div className="dot myams-3"></div>
            <div className="dot myams-4"></div>
          </div>
          <div className="side six">
            <div className="dot myams-1"></div>
            <div className="dot myams-2"></div>
            <div className="dot myams-3"></div>
            <div className="dot myams-4"></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DiceItem;
