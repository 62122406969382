import React, { useRef } from "react";
import { connect } from "react-redux";

import { removeUiError, removeUiErrors } from "../../../../../redux/__actions/ui/uiAction";
import { validateNewPassword } from "../../../../../redux/__actions/user/userAction";

import { ModalView, Loader, Separation } from "../../../../../components/ui";
import { InputField, Button } from "../../../../../components/forms";

import { INFOS } from "../../../../../common/messages/userAccount";
import {
  USER,
  USER_PASSWORD_RESET,
  USER_PASSWORD_RESET_PASSWORD,
  USER_PASSWORD_RESET_PASSWORD_CONF,
} from "../../../../../common/constants/uiErrorPaths";
import { resetUrl } from "../../../../../common/utils/appUtils";

import "../accountPopin.css";

const PromptResetPassword = ({ user, errors, validateNewPassword, removeUiError, removeUiErrors }) => {
  const passwordRef = useRef();
  const passwordConfRef = useRef();
  return (
    <ModalView
      modalStyle={{
        minWidth: "unset",
        minHeight: "unset",
      }}
      canCloseByClickingOutside={false}
      onClose={() => {
        removeUiErrors(USER);
        resetUrl();
      }}
    >
      <div className="account_modal_prompt_container">
        {/* Title */}
        <div className="account_popin_form_title">
          <span>{user.prompt.passwordResetPseudo}, définissez votre nouveau mot de passe</span>
          <span>à l{"'"}aide du formulaire suivant :</span>
        </div>

        <Separation />

        {/* Form */}
        <div className="account_popin_form">
          {/* Pseudo (hidden) */}
          <InputField
            className="account_popin_form_hidden_field"
            label="Pseudo"
            inputProps={{ autoComplete: "username" }}
            inputValue={user.prompt.passwordResetPseudo}
            disabled={true}
          />
          {/* New password */}
          <InputField
            label="Nouveau mot de passe"
            inputRef={passwordRef}
            inputType="password"
            fieldInfos={INFOS.PASSWORD}
            inputProps={{ autoComplete: "new-password" }}
            error={errors[USER_PASSWORD_RESET_PASSWORD]}
            onChangeInput={() => {
              removeUiError(USER_PASSWORD_RESET_PASSWORD);
              removeUiError(USER_PASSWORD_RESET);
            }}
          />
          {/* New password confirmation */}
          <InputField
            label="Confirmation"
            inputRef={passwordConfRef}
            inputType="password"
            fieldInfos={INFOS.PASSWORD_CONFIRMATION}
            inputProps={{ autoComplete: "new-password" }}
            error={errors[USER_PASSWORD_RESET_PASSWORD_CONF]}
            onChangeInput={() => {
              removeUiError(USER_PASSWORD_RESET_PASSWORD_CONF);
              removeUiError(USER_PASSWORD_RESET);
            }}
          />

          {/* Error reset password */}
          {errors[USER_PASSWORD_RESET] && (
            <span className="account_popin_form_error">{errors[USER_PASSWORD_RESET]}</span>
          )}
        </div>

        {/* Button validate new password */}
        <Button
          className="account_popin_form_button"
          img={user.prompt.isResettingPassword ? <Loader color="#fff" /> : undefined}
          disabled={user.prompt.isResettingPassword === true}
          title="Valider mon nouveau mot de passe"
          onClick={() => {
            removeUiError(USER_PASSWORD_RESET);
            validateNewPassword(passwordRef.current.value, passwordConfRef.current.value, user.prompt.passwordResetId);
          }}
        />
      </div>
    </ModalView>
  );
};

const mapStateToProps = ({ ui, user }) => ({
  user,
  errors: ui.errors,
});
const mapDispatchToProps = (dispatch) => ({
  validateNewPassword: (password, passwordConf, passwordResetId) =>
    dispatch(validateNewPassword(password, passwordConf, passwordResetId)),
  removeUiError: (path) => dispatch(removeUiError(path)),
  removeUiErrors: (path) => dispatch(removeUiErrors(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptResetPassword);
