// TODO: .info, .warn, .error
// export const LOGGER = (str, bgColor, color) => {
//   console.log(
//     `%c ${str} `,
//       `background-color: ${bgColor || 'white'};
//       color: ${color || 'black'};`
//   );
// };

// Firefox 1.0+
// export const isOnFirefox = typeof InstallTrigger !== "undefined";

// Internet Explorer 6-11
// export const isOnIE = /*@cc_on!@*/ false || !!document.documentMode;

// Edge 20+
// export const isOnEdge = !isOnIE && !!window.StyleMedia;

// Chrome 1 - 71
// export const isOnChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);

/**
 * Checks if the current device is a mobile device.
 *
 * @return {boolean} true if the device is a mobile device, false otherwise
 */
export const isOnMobile = (): boolean =>
  window.matchMedia("only screen and (max-width: 480px)").matches ||
  window.matchMedia("only screen and (max-height: 480px)").matches;

/**
 * Checks if the current device has touch capabilities.
 *
 * @return {boolean} true if the device has touch capabilitie, false otherwise
 */
export const canTouch = (): boolean => "ontouchstart" in window && navigator.maxTouchPoints > 0;

/**
 * Checks if the given value is a valid number.
 *
 * @param {unknown} val - The value to be checked.
 * @return {boolean} Returns true if the value is a valid number, otherwise returns false.
 */
export const isNum = (val: unknown): boolean => {
  if (val === null || val === undefined) {
    return false;
  } else if (typeof val === "number" && isFinite(val)) {
    return true;
  } else if (typeof val === "string") {
    return !isNaN(parseFloat(val)) && val.indexOf(".") === -1;
  }
  return false;
};

/**
 * Calculates a human-readable size from a given size in bytes.
 *
 * @param {number} size - The size in bytes.
 * @param {number} [decimals=0] - The number of decimals to include in the result.
 * @return {string} The human-readable size.
 */
export const getReadableSize = (size: number, decimals?: number): string => {
  const digits = decimals ?? 0;
  if (size < 1024) {
    return `${size} octets`;
  } else if (size < 1024 * 1024) {
    return `${(size / 1024).toFixed(digits)} Ko`;
  } else if (size < 1024 * 1024 * 1024) {
    return `${(size / 1024 / 1024).toFixed(digits)} Mo`;
  } else {
    return `${(size / 1024 / 1024 / 1024).toFixed(digits)} Go`;
  }
};

export const resetUrl = (newUrl?: string): void => {
  window.history.pushState({}, "", newUrl ?? "/");
};
