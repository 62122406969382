import axios from "./axiosConfig";
import { TopScore } from "../common/types";

interface PostGameDto {
  userId: string;
  game64: string;
  token: string;
}

//temp debug
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function debugTimeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

class GameService {
  /*** Profile ***/
  async saveGame(postGame: PostGameDto): Promise<boolean> {
    // await debugTimeout(800);
    const response = await axios.post("/api/game", postGame);
    return response.data.result;
  }

  // todo: move elsewhere ?
  async getTopScores(): Promise<TopScore[]> {
    // await debugTimeout(800);
    const response = await axios.get("/api/game/top-scores");
    return response.data;
  }
}

export default new GameService();
