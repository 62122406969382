import React from "react";
import { GAME_STATUS } from "../../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { preserveADice, throwDice } from "../../../../../redux/features/game/gameSlice";

import { ACTION_ITEM, MENU_ITEM, SETTING_KEY } from "../../../../../common/enums";

import { Button } from "../../../../../components/forms";
import { DiceBoard } from "../../../../../components/game";
import ActionsBar from "../actionsBar/ActionsBar";

import { ImgRollTheDice } from "../../../../../common/img/common";

import "./gameBoard.css";

const GameBoard = (): JSX.Element | null => {
  const { status, rule, gameBoard } = useAppSelector((state) => state.game);
  const settings = useAppSelector((state) => state.settings);
  if (!gameBoard) return null;

  const { throwIndex, maxNumberOfThrows, isThrowingDice } = gameBoard;
  const canThrowDice =
    gameBoard &&
    gameBoard.dice &&
    throwIndex < maxNumberOfThrows &&
    isThrowingDice === false &&
    status === GAME_STATUS.ONGOING &&
    !gameBoard.dice.every((d) => d.isPreserved === true);

  const dispatch = useAppDispatch();

  return (
    <div className={"mobile_gameBoard_container" + (isThrowingDice ? " animateThrow" : "")}>
      <div className="gameBoard_actions_and_dices">
        {/* Actions */}
        <ActionsBar
          className="gameBoard_actions"
          actionItems={[ACTION_ITEM.MENU, ACTION_ITEM.CANCEL_LAST_SCORE, ACTION_ITEM.UNLOCK_EXTRA_THROW]}
          menuItems={[MENU_ITEM.RULES, MENU_ITEM.SETTINGS, MENU_ITEM.REPLAY, MENU_ITEM.GIVE_UP]}
        />

        {/* Dice Board */}
        <DiceBoard
          className="gameBoard_dices"
          dices={gameBoard?.dice}
          isFrozen={throwIndex === maxNumberOfThrows}
          isThrowingDices={isThrowingDice}
          clickOnDiceToPreserve={settings[SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE]}
          showIndicator={settings[SETTING_KEY.SHOW_INDICATOR_ON_DICE]}
          onPreserveDice={(dice) => {
            // Vibration
            settings[SETTING_KEY.ENABLE_VIBRATION_ON_DICE] && navigator.vibrate && navigator.vibrate(100);
            dispatch(preserveADice(dice, !dice.isPreserved));
          }}
        />
      </div>

      {/* Throw dice Button */}
      <Button
        className={
          "gameBoard_throw_button" +
          (!canThrowDice ? " disabled" : "") +
          (maxNumberOfThrows > rule.maxNumberOfThrows && throwIndex < maxNumberOfThrows ? " extraThrow" : "")
        }
        orientation="vertical"
        img={<ImgRollTheDice />}
        title={`LANCER ${throwIndex}/${maxNumberOfThrows}`}
        disabled={!canThrowDice}
        onClick={() => {
          // Vibration
          settings[SETTING_KEY.ENABLE_VIBRATION_ON_DICE_THROW_BUTTON] && navigator.vibrate && navigator.vibrate(100);
          dispatch(throwDice());
        }}
      />
    </div>
  );
};

export default GameBoard;
