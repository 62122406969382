import { RESULT_KEY, ROW_TYPE } from "../enums";
import { Result } from "../types";

export const RESULT: Record<RESULT_KEY, Result> = {
  [RESULT_KEY.SUBTOTAL]: {
    key: RESULT_KEY.SUBTOTAL,
    type: ROW_TYPE.RESULT,
    name: "Sous-Total",
    shortName: "Sous-Total",
    longName: "Sous-Total",
    description: ["Sous total de la partie haute."],
  },
  [RESULT_KEY.TOTAL_1]: {
    key: RESULT_KEY.TOTAL_1,
    type: ROW_TYPE.RESULT,
    name: "Total haut",
    shortName: "Total haut",
    longName: "Total haut",
    description: ["Total de la partie haute."],
  },
  [RESULT_KEY.TOTAL_2]: {
    key: RESULT_KEY.TOTAL_2,
    type: ROW_TYPE.RESULT,
    name: "Total bas",
    shortName: "Total bas",
    longName: "Total bas",
    description: ["Total de la partie basse."],
  },
  [RESULT_KEY.TOTAL_COL]: {
    key: RESULT_KEY.TOTAL_COL,
    type: ROW_TYPE.RESULT,
    name: "Total colonne",
    shortName: "Total",
    longName: "Total",
    description: ["Total de la colonne."],
  },
  [RESULT_KEY.GRAND_TOTAL]: {
    key: RESULT_KEY.GRAND_TOTAL,
    type: ROW_TYPE.RESULT,
    name: "Total Général",
    shortName: "Total général",
    longName: "Total général",
    description: ["Total général."],
  },
};
