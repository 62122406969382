// User
export const USER = "user";
// User registration
export const USER_REGISTRATION = "user.registration";
export const USER_REGISTRATION_PSEUDO = "user.registration.pseudo";
export const USER_REGISTRATION_EMAIL = "user.registration.email";
export const USER_REGISTRATION_PASSWORD = "user.registration.password";
export const USER_REGISTRATION_PASSWORD_CONF = "user.registration.passwordConf";
// User activation
export const USER_ACTIVATION = "user.activation";
export const USER_ACTIVATION_PSEUDO = "user.activation.pseudo";
export const USER_ACTIVATION_PASSWORD = "user.activation.password";
// User login
export const USER_LOGIN = "user.login";
export const USER_LOGIN_PSEUDO = "user.login.pseudo";
export const USER_LOGIN_PASSWORD = "user.login.password";
// User request reset password
export const USER_REQUEST_RESET_PASSWORD = "user.requestPasswordReset";
export const USER_REQUEST_RESET_PASSWORD_EMAIL = "user.requestPasswordReset.email";
// User reset password
export const USER_PASSWORD_RESET = "user.passwordReset";
export const USER_PASSWORD_RESET_PASSWORD = "user.passwordReset.password";
export const USER_PASSWORD_RESET_PASSWORD_CONF = "user.passwordReset.passwordConf";
// User profile
export const USER_PROFILE = "user.profile";
export const USER_PROFILE_AVATAR = "user.profile.avatar";
export const USER_PROFILE_EMAIL = "user.profile.email";
export const USER_PROFILE_PASSWORD = "user.profile.password";
export const USER_PROFILE_NEW_PASSWORD = "user.profile.newPassword";
export const USER_PROFILE_NEW_PASSWORD_CONF = "user.profile.newPasswordConf";
