import { BONUS_KEY, ROW_TYPE } from "../enums";
import { Bonus } from "../types";

export const BONUS: Record<BONUS_KEY, Bonus> = {
  [BONUS_KEY.BONUS]: {
    key: BONUS_KEY.BONUS,
    type: ROW_TYPE.BONUS,
    name: "Bonus",
    shortName: "Bonus",
    longName: "Bonus partie haute",
    description: ["Un bonus de 35 points est attribué si le sous-total de la partie haute est d'au moins 63 points."],
    info: "35",
    bonusValue: 35,
    minSubtotalRequired: 63,
  },
  [BONUS_KEY.YAMS_BONUS]: {
    key: BONUS_KEY.YAMS_BONUS,
    type: ROW_TYPE.BONUS,
    name: "Bonus de Yams",
    shortName: "Bonus de Yams",
    longName: "Bonus de Yams",
    description: [
      "Un bonus de 100 points est attribué à chaque fois qu'un yams est effectué",
      "à condition que la case yams ait déjà été remplie positivement.",
    ],
    info: "100",
    bonusValue: 100,
  },
};
