import React, { useState } from "react";
import { GameRule } from "../../../../game-box/core/types";
import { GAME_STATUS } from "../../../../game-box/core/enums";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectGameRule } from "../../../../redux/features/game/gameSlice";

import GameRuleDetails from "../../../rulesOfTheGame";
import { RadioGroup, Radio, Button } from "../../../../components/forms";
import { ModalView } from "../../../../components/ui";

import "./gameRuleSelection.css";

const NB_RULES_TO_SHOW = 3;

const GameRuleSelection = (): JSX.Element => {
  const rules = useAppSelector((state) => state.gameRules);
  const selectedRule = useAppSelector((state) => state.game.rule);
  const gameStatus = useAppSelector((state) => state.game.status);

  const dispatch = useAppDispatch();

  const [nbRulesToShow, setNbRulesToShow] = useState<number>(NB_RULES_TO_SHOW);
  const [ruleInModal, setRuleInModal] = useState<GameRule>();

  return (
    <div className="gameRuleSelection_container">
      {/* Rules list */}
      <RadioGroup>
        {rules.slice(0, nbRulesToShow).map((rule) => (
          <Radio
            key={rule.id}
            label={rule.name}
            sublabel={rule.summary}
            disabled={gameStatus !== GAME_STATUS.INITIAL}
            selected={selectedRule && rule.id === selectedRule.id}
            onSelect={() => dispatch(selectGameRule(rule))}
          >
            {/* Detail button */}
            <div className="gameRuleSelection_detail_button" onClick={() => setRuleInModal(rule)}>
              Détails
            </div>
          </Radio>
        ))}
      </RadioGroup>

      {/* Show more/less rules */}
      {rules.length > nbRulesToShow && (
        <Button
          className="gameRuleSelection_show_more_less_button"
          title={`Afficher toutes les règles (${rules.length})`}
          onClick={() => setNbRulesToShow(rules.length)}
          type="link"
        />
      )}
      {nbRulesToShow > NB_RULES_TO_SHOW && (
        <Button
          className="gameRuleSelection_show_more_less_button"
          title="Afficher moins"
          onClick={() => setNbRulesToShow(NB_RULES_TO_SHOW)}
          type="link"
        />
      )}

      {/* Rule details shown in modal view */}
      {ruleInModal && (
        <ModalView
          modalStyle={{
            maxWidth: "1200px",
            width: "80%",
            height: "80%",
          }}
          title={`${ruleInModal.name} : Règle du jeu`}
          onClose={() => setRuleInModal(undefined)}
        >
          <GameRuleDetails rule={ruleInModal} />
        </ModalView>
      )}
    </div>
  );
};

export default GameRuleSelection;
