import { getReadableSize } from "../utils/appUtils";
import {
  AVATAR_MAX_SIZE,
  PSEUDO_MIN_CHARS,
  PSEUDO_MAX_CHARS,
  PSEUDO_ALLOWED_SPECIAL_CHARS,
  PASSWORD_MIN_CHARS,
  PASSWORD_MAX_CHARS,
} from "../constants/account";

export const INFOS = {
  PSEUDO: [
    "Votre pseudo doit contenir entre" +
      ` ${PSEUDO_MIN_CHARS} et ${PSEUDO_MAX_CHARS} ` +
      "caractères sans espace ni accent",
    "et peut contenir des lettres, chiffres et les caractères spéciaux " + PSEUDO_ALLOWED_SPECIAL_CHARS.join(" "),
    "Il vous servira d'identifiant de connexion et de nom public.",
    "Attention, une fois validé il ne pourra pas être modifié.",
  ],
  UPDATE_PSEUDO: ["Votre pseudo ne peut pas être modifié."],
  EMAIL: [
    "Votre adresse e-mail doit être valide.",
    "Un e-mail vous sera envoyé pour confirmer votre inscription",
    "afin d'activer votre compte.",
  ],
  UPDATE_EMAIL: ["Votre adresse e-mail doit être valide."],
  RESET_PASSWORD_EMAIL: ["Votre adresse e-mail doit être celle avec laquelle", "vous avez validé votre inscription."],
  PASSWORD: [
    "Votre mot de passe doit contenir entre" + ` ${PASSWORD_MIN_CHARS} et ${PASSWORD_MAX_CHARS} caractères.`,
    "Il peut contenir des caractères spéciaux.",
  ],
  PASSWORD_CONFIRMATION: ["Confirmation de votre mot de passe", "pour être sûr(e) de ne pas vous tromper ;-)"],
  AVATAR: [`Votre avatar ne doit pas dépasser ${getReadableSize(AVATAR_MAX_SIZE)}.`],
};

export const ERRORS = {
  // Pseudo
  PSEUDO: "Votre pseudo doit être renseigné",
  PSEUDO_LENGTH: "Votre pseudo doit contenir entre " + `${PSEUDO_MIN_CHARS} et ${PSEUDO_MAX_CHARS} caractères`,
  PSEUDO_FORMAT:
    "Votre pseudo ne doit pas contenir d'espace, d'accent" +
    " ni de caractères spéciaux autre que " +
    PSEUDO_ALLOWED_SPECIAL_CHARS.join(" "),
  // E-mail
  EMAIL_FORMAT: "Votre adresse e-mail n'est pas valide",
  // Password
  PASSWORD: "Votre mot de passe doit être renseigné",
  PASSWORD_LENGTH:
    "Votre mot de passe doit contenir entre" + ` ${PASSWORD_MIN_CHARS} et ${PASSWORD_MAX_CHARS} caractères`,
  PASSWORD_CONFIRMATION: "La confirmation de votre mot de passe est erronée",
  NEW_PASSWORD_LENGTH:
    "Votre nouveau mot de passe doit contenir entre" + ` ${PASSWORD_MIN_CHARS} et ${PASSWORD_MAX_CHARS} caractères`,
  NEW_PASSWORD_CONFIRMATION: "La confirmation de votre nouveau mot de passe est erronée",
  NEW_PASSWORD_SAME_AS_CURRENT: "Votre nouveau mot de passe" + " doit être différent de votre mot de passe actuel",
  // Avatar
  AVATAR_SIZE: `Votre avatar ne doit pas dépasser ${getReadableSize(AVATAR_MAX_SIZE)}`,
  AVATAR_FORMAT: "Votre avatar doit être une image",
  // Activation id
  ACTIVATION_ID: "Le code d'activation est erroné",
  // Password reset
  PASSWORD_RESET_ID: "Le code de reinitialisation de votre mot de passe est erroné",
};

export const MESSAGES = {
  // ACCOUNT_NOT_ACTIVATED: [
  //   "Pensez à activer votre compte en cliquant sur",
  //   "le lien de validation qui vous a été envoyé par e-mail",
  // ],
  ACCOUNT_NOT_ACTIVATED:
    "Pensez à activer votre compte en cliquant sur le lien de validation qui vous a été envoyé par e-mail",
  UPDATE_EMAIL: [
    "La modification de votre adresse e-mail" + " nécessite de réactiver votre compte.",
    "Un e-mail vous sera envoyé pour procéder à l'activation.",
  ],
  RESET_PASSWORD: [
    "Indiquez votre adresse e-mail afin de recevoir",
    "par e-mail la procédure de réinitialisation de",
    "votre mot de passe.",
  ],
  REMOVE_ACCOUNT: [
    "La suppression de votre compte myams entraînera la suppression" + " de vos données personnelles.",
    "Vos parties en lignes et vos scores seront conservés mais le pseudo" +
      " associé à ces informations sera changé pour 'Ancien utilisateur'.",
  ],
  REMOVE_ACCOUNT_CONFIRMATION: [
    "Êtes-vous sûr(e) de vouloir supprimer votre compte myams ?",
    "Cette action est irréversible.",
  ],
};
