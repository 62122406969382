import React from "react";

import { Button } from "../../../../components/forms";
import { ImgRemove, ImgTwoStars } from "../../../../common/img/common";

import "./releaseNote.css";

interface ReleaseNoteRowProps {
  version: string;
  type: "major" | "minor" | "patch" | "soon";
  content: string[];
}

const ReleaseNoteRow = ({ version, type, content }: ReleaseNoteRowProps): JSX.Element => {
  return (
    <div className={`releaseNote_row_container ${type}`}>
      <div className="releaseNote_version_label">
        <span>{version}</span>
        {type === "major" && <ImgTwoStars />}
      </div>
      <div className="releaseNote_version_content">
        {content.map((text, i) => (
          <div className="releaseNote_version_content_line" key={i}>
            <span>-</span>
            <div>{text}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

interface ReleaseNoteProps {
  onClickClose: () => void;
}

const ReleaseNote = ({ onClickClose }: ReleaseNoteProps): JSX.Element => {
  return (
    <div className="releaseNote_container">
      <Button className="releaseNote_close_button" img={<ImgRemove />} onClick={() => onClickClose()} />

      <div className="releaseNote_rows">
        <ReleaseNoteRow
          version="À VENIR"
          type="soon"
          content={[
            "Top Scores : Voir les 100 premiers",
            "Top Scores de la semaine, du mois, et de l'année : " +
              " Chaque lundi à 00h00, un nouveau classement de la semaine est lancé," +
              " et le dimanche à 23:59:59 le 1er du classement remporte un titre de champion de la semaine." +
              " Même principe chaque mois, et chaque année :)",
            ////////////////////////////////////////////////////////////
            "L'effet est rétroactif, c'est à dire que toutes les parties jouées depuis le début seront comptabilisées" +
              " pour décerner les titres qui auraient été acquis avant la sortie de la fonctionnalité ;-)",
            "Voir mes statistiques et mes titres",
            "Voir les statistiques et les titres des autres joueurs",
            "Voir la grille de jeu d'un Top Score",
            "Et tellement plein d'autres trucs..",
          ]}
        />

        {/* <ReleaseNoteRow
          version="v1.3.0"
          type="minor"
          content={[
            "Top Scores : Voir les 100 premiers du classement",
            "Top Scores de la semaine, du mois, de l'année, et de tous les temps : " +
              " Chaque lundi à 00h00, un nouveau classement de la semaine est lancé," +
              " et le dimanche à 23:59:59 le 1er du classement remporte un titre de champion de la semaine." +
              " Même principe chaque mois, et chaque année :)",
          ]}
        /> */}

        <ReleaseNoteRow
          version="v1.2.0"
          type="minor"
          content={[
            "Compatibilité iPhone",
            "Ajout des Notes de version, accessibles depuis le menu de la page d'accueil",
          ]}
        />
        <ReleaseNoteRow version="1.2.1" type="patch" content={["Correction de bugs d'affichage"]} />
        <ReleaseNoteRow version="1.2.2" type="patch" content={["Correction de bugs d'affichage"]} />
        <ReleaseNoteRow version="1.2.3" type="patch" content={["Ajout du teasing dans les notes de version"]} />

        <ReleaseNoteRow
          version="v1.1.0"
          type="minor"
          content={[
            "Pouvoir recommencer la partie en cours",
            "Règles du jeu accessibles depuis le menu de la page d'accueil",
            "Correction d'un bug de création de compte utilisateur",
          ]}
        />
        <ReleaseNoteRow version="1.1.1" type="patch" content={["Correction de bugs de chronomètre"]} />
        <ReleaseNoteRow version="1.1.2" type="patch" content={["Correction de bugs d'affichage"]} />

        <ReleaseNoteRow
          version="v1.0.0"
          type="major"
          content={[
            "Création et connexion à un compte utilisateur",
            "Enregistrement des parties terminées sur le compte utilisateur",
            "Top Scores sur la page d'accueil",
            "Corrections et améliorations de la version beta",
          ]}
        />

        <ReleaseNoteRow version="beta" type="major" content={["Création du myams sur mobile en version beta"]} />
      </div>
    </div>
  );
};

export default ReleaseNote;
