import { ROW_SECTION, ROW_TYPE, SCORE_KEY } from "../enums";
import { Score } from "../types";

export const SCORE: Record<SCORE_KEY, Score> = {
  [SCORE_KEY.ACES]: {
    key: SCORE_KEY.ACES,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.TOP,
    name: "Un",
    shortName: "Un",
    longName: "Les UN",
    description: ["Tous les dés de valeur UN."],
    calculation: "Le score est la somme des dés de valeur UN.",
    diceValue: 1,
    allowedScores: [0, 1, 2, 3, 4, 5],
    img: "img:dice_one",
  },
  [SCORE_KEY.TWOS]: {
    key: SCORE_KEY.TWOS,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.TOP,
    name: "Deux",
    shortName: "Deux",
    longName: "Les DEUX",
    description: ["Tous les dés de valeur DEUX."],
    calculation: "Le score est la somme des dés de valeur DEUX.",
    diceValue: 2,
    allowedScores: [0, 2, 4, 6, 8, 10],
    img: "img:dice_two",
  },
  [SCORE_KEY.THREES]: {
    key: SCORE_KEY.THREES,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.TOP,
    name: "Trois",
    shortName: "Trois",
    longName: "Les TROIS",
    description: ["Tous les dés de valeur TROIS."],
    calculation: "Le score est la somme des dés de valeur TROIS.",
    diceValue: 3,
    allowedScores: [0, 3, 6, 9, 12, 15],
    img: "img:dice_three",
  },
  [SCORE_KEY.FOURS]: {
    key: SCORE_KEY.FOURS,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.TOP,
    name: "Quatre",
    shortName: "Quatre",
    longName: "Les QUATRE",
    description: ["Tous les dés de valeur QUATRE."],
    calculation: "Le score est la somme des dés de valeur QUATRE.",
    diceValue: 4,
    allowedScores: [0, 4, 8, 12, 16, 20],
    img: "img:dice_four",
  },
  [SCORE_KEY.FIVES]: {
    key: SCORE_KEY.FIVES,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.TOP,
    name: "Cinq",
    shortName: "Cinq",
    longName: "LES CINQ",
    description: ["Tous les dés de valeur CINQ."],
    calculation: "Le score est la somme des dés de valeur CINQ.",
    diceValue: 5,
    allowedScores: [0, 5, 10, 15, 20, 25],
    img: "img:dice_five",
  },
  [SCORE_KEY.SIXES]: {
    key: SCORE_KEY.SIXES,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.TOP,
    name: "Six",
    shortName: "Six",
    longName: "Les SIX",
    description: ["Tous les dés de valeur SIX."],
    calculation: "Le score est la somme des dés de valeur SIX.",
    diceValue: 6,
    allowedScores: [0, 6, 12, 18, 24, 30],
    img: "img:dice_six",
  },
  [SCORE_KEY.THREE_OF_A_KIND]: {
    key: SCORE_KEY.THREE_OF_A_KIND,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Brelan",
    shortName: "Brelan",
    longName: "Le BRELAN",
    description: ["Au moins 3 dés similaires."],
    calculation: "Le score est la somme des 5 dés.",
  },
  [SCORE_KEY.FOUR_OF_A_KIND]: {
    key: SCORE_KEY.FOUR_OF_A_KIND,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Carré",
    shortName: "Carré",
    longName: "Le CARRÉ",
    description: ["Au moins 4 dés similaires."],
    calculation: "Le score est la somme des 5 dés.",
  },
  [SCORE_KEY.FULL_HOUSE]: {
    key: SCORE_KEY.FULL_HOUSE,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Full",
    shortName: "Full",
    longName: "Le FULL",
    description: ["3 dés similaires d'une sorte + 2 dés similaires d'une autre sorte."],
    calculation: "Le score est de 25 points.",
    allowedScores: [0, 25],
    value: 25,
  },
  [SCORE_KEY.SMALL_STRAIGHT]: {
    key: SCORE_KEY.SMALL_STRAIGHT,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Petite suite",
    shortName: "Petite suite",
    longName: "La PETITE SUITE",
    description: ["4 dés qui se suivent (1-2-3-4, 2-3-4-5 ou 3-4-5-6)."],
    calculation: "Le score est de 30 points.",
    allowedScores: [0, 30],
    value: 30,
  },
  [SCORE_KEY.LARGE_STRAIGHT]: {
    key: SCORE_KEY.LARGE_STRAIGHT,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Grande suite",
    shortName: "Grande suite",
    longName: "Le GRANDE SUITE",
    description: ["5 dés qui se suivent (1-2-3-4-5 ou 2-3-4-5-6)."],
    calculation: "Le score est de 40 points.",
    allowedScores: [0, 40],
    value: 40,
  },
  [SCORE_KEY.YAMS]: {
    key: SCORE_KEY.YAMS,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Yams",
    shortName: "Yams",
    longName: "Le YAMS",
    description: ["5 dés similaires."],
    calculation: "Le score est de 50 points.",
    allowedScores: [0, 50],
    value: 50,
  },
  [SCORE_KEY.LOW_CHANCE]: {
    key: SCORE_KEY.LOW_CHANCE,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Chance -",
    shortName: "Chance -",
    longName: "La CHANCE MOINS",
    description: ["La somme des 5 dés doit être inférieure à la chance plus."],
    calculation: "Le score est la somme des 5 dés.",
  },
  [SCORE_KEY.HIGH_CHANCE]: {
    key: SCORE_KEY.HIGH_CHANCE,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Chance +",
    shortName: "Chance +",
    longName: "La CHANCE PLUS",
    description: ["La somme des 5 dés doit être supérieure à la chance moins."],
    calculation: "Le score est la somme des 5 dés.",
  },
  [SCORE_KEY.CHANCE]: {
    key: SCORE_KEY.CHANCE,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Chance",
    shortName: "Chance",
    longName: "La CHANCE",
    description: ["N'importe quels dés."],
    calculation: "Le score est la somme des 5 dés.",
  },
  [SCORE_KEY.UNDER_ELEVEN]: {
    key: SCORE_KEY.UNDER_ELEVEN,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Moins de 11",
    shortName: "< 11",
    longName: "MOINS DE 11",
    description: ["La somme des 5 dés doit être inférieure à 11."],
    calculation: "Le score est de 25 points.",
    allowedScores: [0, 25],
    value: 25,
  },
  [SCORE_KEY.ONLY_PAIRS]: {
    key: SCORE_KEY.ONLY_PAIRS,
    type: ROW_TYPE.SCORE,
    section: ROW_SECTION.BOTTOM,
    name: "Que des pairs",
    shortName: "Que des pairs",
    longName: "QUE DES DÉS PAIRS",
    description: ["Les dés ne doivent être que des pairs (2, 4 ou 6)."],
    calculation: "Le score est de 20 points.",
    allowedScores: [0, 20],
    value: 20,
  },
};
