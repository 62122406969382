import React, { useEffect } from "react";

import { MENU_ITEM } from "../../../common/enums";
import {
  MENU_TITLE_GIVE_UP,
  MENU_TITLE_HELP,
  MENU_TITLE_INFOS,
  MENU_TITLE_REPLAY,
  MENU_TITLE_RULES,
  MENU_TITLE_SETTINGS,
} from "../../../common/constants/menus";

import { isEventTargetInsideElem } from "../../../common/utils/uiUtils";

import "./menu.css";
import { ImgRefreshCircle, ImgRemove, ImgRules, ImgSettings } from "../../../common/img/common";
import { canTouch } from "../../../common/utils/appUtils";

interface MenuProps {
  visible: boolean;
  menuItems: MENU_ITEM[];
  onTouchMenuItem: (menuItem: MENU_ITEM) => void;
  onBlur: () => void;
}

const Menu = ({ visible, menuItems, onTouchMenuItem, onBlur }: MenuProps): JSX.Element => {
  const onClickMenu = (menuItem: MENU_ITEM) => {
    onTouchMenuItem(menuItem);
    onBlur();
  };

  useEffect(() => {
    if (menuItems?.length > 0) {
      // blur menu if visible and click out of actionButton
      const onClickSomewhere = (e: Event) => {
        if (visible && !isEventTargetInsideElem(e.target, null, "actionButton")) {
          e.stopPropagation();
          onBlur();
        }
      };
      window.addEventListener("click", onClickSomewhere, true);
      return () => {
        window.removeEventListener("click", onClickSomewhere, true);
      };
    }
  }, [menuItems, onBlur]);

  return (
    <div className={`mobile_menus${visible ? " visible" : ""}`} onClick={() => onBlur()}>
      {menuItems.map((menuItem, i) => (
        <div
          key={i}
          className={`mobile_menu_item${menuItem === MENU_ITEM.REPLAY ? " withTopSeparation" : ""}`}
          onTouchStart={canTouch() ? () => onClickMenu(menuItem) : undefined}
          onClick={!canTouch() ? () => onClickMenu(menuItem) : undefined}
        >
          {menuItem === MENU_ITEM.GIVE_UP && (
            <>
              <ImgRemove style={{ stroke: "#fff" }} /> {MENU_TITLE_GIVE_UP}
            </>
          )}
          {menuItem === MENU_ITEM.REPLAY && (
            <>
              <ImgRefreshCircle style={{ stroke: "#000" }} /> {MENU_TITLE_REPLAY}
            </>
          )}
          {menuItem === MENU_ITEM.SETTINGS && (
            <>
              <ImgSettings style={{ stroke: "#000" }} /> {MENU_TITLE_SETTINGS}
            </>
          )}
          {menuItem === MENU_ITEM.RULES && (
            <>
              <ImgRules style={{ stroke: "#000" }} /> {MENU_TITLE_RULES}
            </>
          )}
          {menuItem === MENU_ITEM.HELP && MENU_TITLE_HELP}
          {menuItem === MENU_ITEM.INFOS && MENU_TITLE_INFOS}
        </div>
      ))}
    </div>
  );
};

export default Menu;
