import React, { useEffect, useState } from "react";

import { ImgNetwork, ImgNoNetwork } from "../../../common/img/common";

import "./networkPrompt.css";

const NetworkPrompt = (): JSX.Element | null => {
  const [isOffline, setIsOffline] = useState<boolean>();
  const [showPrompt, setShowPrompt] = useState<boolean>();

  const onOnline = () => {
    setIsOffline(false);
  };
  const onOffline = () => {
    setIsOffline(true);
  };
  const onReceiveMessage = (event: MessageEvent): void => {
    if (event.data.type === "offline") {
      setIsOffline(true);
    }
  };

  useEffect(() => {
    setShowPrompt(isOffline !== undefined ? true : undefined);
    setTimeout(() => {
      setShowPrompt(false);
    }, 5000);
  }, [isOffline]);

  useEffect(() => {
    window.addEventListener("online", onOnline);
    window.addEventListener("offline", onOffline);
    navigator.serviceWorker.addEventListener("message", onReceiveMessage);
    return () => {
      window.removeEventListener("online", onOnline);
      window.removeEventListener("offline", onOffline);
      navigator.serviceWorker.removeEventListener("message", onReceiveMessage);
    };
  }, []);

  const globalNoNetworkIcon = isOffline ? (
    <div className="globalNoNetworkIcon">
      <ImgNoNetwork />
    </div>
  ) : null;

  if (showPrompt !== true) {
    return globalNoNetworkIcon;
  }
  return (
    <>
      {globalNoNetworkIcon}
      <div className={`networkPrompt_container${showPrompt ? " show" : ""}`}>
        <div className="network_label_container">
          <div className="network_label_logo"></div>
          {isOffline ? (
            <>
              <ImgNoNetwork />
              <div>Le myams est actuellement en mode Hors-connexion</div>
            </>
          ) : (
            <>
              <ImgNetwork className="network_img_online" />
              <div>De retour en mode Connecté</div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NetworkPrompt;
