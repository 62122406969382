import React from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { updateSetting } from "../../../redux/features/settingsSlice";
import { cancelTutorial, resetTutorial } from "../../../redux/features/infoSlice";

import { SETTING_KEY } from "../../../common/enums";
import { ImgLockedCircle, ImgRefreshCircle } from "../../../common/img/common";

import { Checkbox, Radio } from "../../../components/forms";

import "./settings.css";

const Settings = (): JSX.Element => {
  const settings = useAppSelector((state) => state.settings);
  const canResetTutorial = useAppSelector((state) => Object.values(state.info.tutorial).every((v) => v === false));

  const dispatch = useAppDispatch();

  return (
    <div className="mobile_settings_container">
      {/*** DICE ***/}
      <div className="setting_section">DÉS</div>
      {/* CLICK_ON_DICE_TO_PRESERVE */}
      <div className="setting_line">
        <span className="setting_radio_title">Cliquer sur un dé pour :</span>
        <Radio
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Le conserver</span>
              <ImgLockedCircle style={{ fill: "#fff", marginLeft: "6px" }} />
            </div>
          }
          selected={settings[SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE, true))}
        >
          {}
        </Radio>
        <Radio
          label={
            <div style={{ display: "flex", alignItems: "center" }}>
              <span>Le relancer</span>
              <ImgRefreshCircle style={{ fill: "#fff", marginLeft: "6px" }} />
            </div>
          }
          selected={!settings[SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE, false))}
        >
          {}
        </Radio>
      </div>
      {/* SHOW_INDICATOR_ON_DICE */}
      <div className="setting_line">
        <Checkbox
          label={
            <>
              <span>{"Afficher l'état de sélection sur le dé"}</span>
              {settings[SETTING_KEY.CLICK_ON_DICE_TO_PRESERVE] ? (
                <ImgLockedCircle style={{ fill: "#fff", marginLeft: "6px" }} />
              ) : (
                <ImgRefreshCircle style={{ marginLeft: "6px" }} />
              )}
            </>
          }
          selected={settings[SETTING_KEY.SHOW_INDICATOR_ON_DICE]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.SHOW_INDICATOR_ON_DICE, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.SHOW_INDICATOR_ON_DICE, false))}
        >
          {}
        </Checkbox>
      </div>
      {/* ENABLE_VIBRATION_ON_DICE */}
      <div className="setting_line" style={{ borderBottom: "none" }}>
        <Checkbox
          label="Activer la vibration à la sélection d'un dé"
          selected={settings[SETTING_KEY.ENABLE_VIBRATION_ON_DICE]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.ENABLE_VIBRATION_ON_DICE, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.ENABLE_VIBRATION_ON_DICE, false))}
        >
          {}
        </Checkbox>
      </div>

      {/*** GAME TABLE ***/}
      <div className="setting_section">TABLE DE JEU</div>
      {/* SHOW_TIMER */}
      <div className="setting_line" style={{ borderBottom: "none" }}>
        <Checkbox
          label="Afficher le chronomètre"
          selected={settings[SETTING_KEY.SHOW_TIMER]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.SHOW_TIMER, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.SHOW_TIMER, false))}
        >
          {}
        </Checkbox>
      </div>
      {/* ENABLE_VIBRATION_ON_DICE_THROW_BUTTON */}
      {/* <div className="setting_line" style={{ borderBottom: "none" }}>
        <Checkbox
          label="Activer la vibration sur le bouton LANCER"
          selected={settings[SETTING_KEY.ENABLE_VIBRATION_ON_DICE_THROW_BUTTON]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.ENABLE_VIBRATION_ON_DICE_THROW_BUTTON, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.ENABLE_VIBRATION_ON_DICE_THROW_BUTTON, false))}
        >
          {}
        </Checkbox>
      </div> */}

      {/*** SCORE SHEET ***/}
      <div className="setting_section">FEUILLE DE SCORES</div>
      {/* SHOW_PENDING_SCORES */}
      <div className="setting_line">
        <Checkbox
          label="Afficher le score potentiel dans les cases de score libres après chaque lancer de dés"
          selected={settings[SETTING_KEY.SHOW_PENDING_SCORES]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.SHOW_PENDING_SCORES, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.SHOW_PENDING_SCORES, false))}
        >
          {}
        </Checkbox>
      </div>
      {/* SHOW_BONUS_INDICATOR */}
      <div className="setting_line">
        <Checkbox
          label="Afficher l'indicateur de bonus"
          selected={settings[SETTING_KEY.SHOW_BONUS_INDICATOR]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.SHOW_BONUS_INDICATOR, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.SHOW_BONUS_INDICATOR, false))}
        >
          {}
        </Checkbox>
      </div>
      {/* ENABLE_VIBRATION_ON_SCORESHEET */}
      {/* <div className="setting_line" style={{ borderBottom: "none" }}>
        <Checkbox
          label="Activer la vibration à la validation d'un score"
          selected={settings[SETTING_KEY.ENABLE_VIBRATION_ON_SCORESHEET]}
          onSelect={() => dispatch(updateSetting(SETTING_KEY.ENABLE_VIBRATION_ON_SCORESHEET, true))}
          onUnselect={() => dispatch(updateSetting(SETTING_KEY.ENABLE_VIBRATION_ON_SCORESHEET, false))}
        >
          {}
        </Checkbox>
      </div> */}

      {/* TODO: SCORE SHEET COLOR ? */}

      {/*** TUTORIEL ***/}
      <div className="setting_section">TUTORIEL</div>
      {/* Reset Tutorial */}
      <div className="setting_line">
        <Checkbox
          label="Revoir le tutoriel lors de la prochaine partie (se décochera automatiquement une fois le tutoriel revisionné)"
          selected={!canResetTutorial}
          onSelect={() => dispatch(resetTutorial())}
          onUnselect={() => dispatch(cancelTutorial())}
        >
          {}
        </Checkbox>
      </div>
    </div>
  );
};

export default Settings;
