import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ImgProps extends Record<string, any> {
  className?: string;
}

export const ImgInfo = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
  >
    {/* <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"></path> */}
    <path d="M11 17h2v-6h-2v6zm1-15C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zM11 9h2V7h-2v2z"></path>
  </svg>
);

export const ImgWarning = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
  >
    <path d="M1 21h22L12 2 1 21zm12-3h-2v-2h2v2zm0-4h-2v-4h2v4z"></path>
  </svg>
);

export const ImgError = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
  >
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"></path>
  </svg>
);

export const ImgSuccess = (props: ImgProps): JSX.Element => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    className={"img" + (props.className ? ` ${props.className}` : "")}
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    fill={props.color || "#424242"}
  >
    <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
  </svg>
);
