import React, { useEffect, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { clearAuthError, login, register, requestPasswordReset } from "../../../../redux/features/userSlice";

import { Button, InputField } from "../../../../components/forms";
import { Loader, ModalAccountView } from "../../../../components/ui";

import "../accountModals.css";
import "./accountLogin.css";

interface AccountLoginProps {
  visible: boolean;
  onClose: () => void;
  onLoggedIn: () => void;
  onRegistered: () => void;
}

const AccountLogin = ({ visible, onClose, onLoggedIn, onRegistered }: AccountLoginProps): JSX.Element => {
  const userCon = useAppSelector((state) => state.user.con);
  const userUpdt = useAppSelector((state) => state.user.updt);

  const dispatch = useAppDispatch();

  const [formType, setFormType] = useState<"login" | "register" | "forgotPassword">("login");
  const [inputPseudo, setInputPseudo] = useState<string>();
  const [inputEmail, setInputEmail] = useState<string>();
  const [inputPassword, setInputPassword] = useState<string>();
  // DISABLED:
  //const [inputPasswordConf, setInputPasswordConf] = useState<string>();
  const [didRequestPasswordReset, setDidRequestPasswordReset] = useState<boolean>(false);

  useEffect(() => {
    if (userCon.isLogged) {
      switch (formType) {
        case "login": {
          onLoggedIn();
          break;
        }
        case "register": {
          onRegistered();
          break;
        }
      }
    }
  }, [userCon.isLogged]);

  useEffect(() => {
    visible && setFormType("login");
    setInputPseudo(undefined);
    setInputEmail(undefined);
    setInputPassword(undefined);
    setDidRequestPasswordReset(false);
  }, [visible]);

  useEffect(() => {
    dispatch(clearAuthError());
  }, [formType, inputPseudo, inputPassword, inputEmail, inputPassword /* DISABLED: inputPasswordConf */]);

  return (
    <ModalAccountView visible={visible} onClose={() => onClose()}>
      {/*** Login form ***/}
      {formType === "login" && (
        <>
          <div className="account_modal_form">
            <InputField
              label="Pseudo"
              showOverlapLabel={true}
              inputValue={inputPseudo}
              inputProps={{ autoComplete: "username" }}
              disabled={userCon.isLogging}
              onChangeInput={(e) => setInputPseudo(e.target.value)}
            />
            <InputField
              label="Mot de passe"
              showOverlapLabel={true}
              inputValue={inputPassword}
              inputProps={{ type: "password" }}
              disabled={userCon.isLogging}
              onChangeInput={(e) => setInputPassword(e.target.value)}
            />

            {/* auth error */}
            {userCon.authError && <div className="account_modal_auth_error">{userCon.authError}</div>}

            {/* login button */}
            <Button
              className="login_button"
              title="Me connecter"
              img={userCon.isLogging ? <Loader color="#fff" /> : undefined}
              disabled={userCon.isLogging}
              onClick={() => dispatch(login(inputPseudo, inputPassword))}
            />

            {/* show forgotten password form */}
            <Button
              className="show_forgotten_password_form_button"
              title="Mot de passe oublié"
              type="link"
              disabled={userCon.isLogging}
              onClick={() => setFormType("forgotPassword")}
            />
          </div>

          <div className="account_modal_separation">
            <div>OU</div>
          </div>

          {/* show register form */}
          <Button
            className="show_regiter_form_button"
            title="Créer mon compte"
            disabled={userCon.isLogging}
            onClick={() => setFormType("register")}
          />
        </>
      )}

      {/*** Register form ***/}
      {formType === "register" && (
        <div className="account_modal_form">
          <InputField
            label="Pseudo"
            showOverlapLabel={true}
            inputValue={inputPseudo}
            disabled={userCon.isRegistering}
            inputProps={{ autoComplete: "username" }}
            onChangeInput={(e) => setInputPseudo(e.target.value)}
          />
          <InputField
            label="Email"
            showOverlapLabel={true}
            inputValue={inputEmail}
            disabled={userCon.isRegistering}
            onChangeInput={(e) => setInputEmail(e.target.value.toLowerCase())}
          />
          <InputField
            label="Mot de passe"
            showOverlapLabel={true}
            inputValue={inputPassword}
            inputProps={{ type: "password" }}
            disabled={userCon.isRegistering}
            onChangeInput={(e) => setInputPassword(e.target.value)}
          />
          {/* DISABLED: */}
          {/* <InputField
            label="Confirmer Mot de passe"
            showOverlapLabel={true}
            inputValue={inputPasswordConf}
            inputProps={{ type: "password" }}
            disabled={userCon.isRegistering}
            onChangeInput={(e) => setInputPasswordConf(e.target.value)}
          /> */}

          {/* auth error */}
          {userCon.authError && <div className="account_modal_auth_error">{userCon.authError}</div>}

          {/* register button */}
          <Button
            className="regiter_button"
            title="Créer mon compte"
            img={userCon.isRegistering ? <Loader color="#fff" /> : undefined}
            disabled={userCon.isRegistering}
            onClick={() =>
              dispatch(
                register(inputPseudo, inputEmail, inputPassword, /* DISABLED: inputPasswordConf */ inputPassword),
              )
            }
          />

          <div className="account_modal_separation"></div>

          {/* show login form */}
          <Button
            className="show_login_form_button"
            title="Retour au formulaire de connexion"
            type="link"
            disabled={userCon.isRegistering}
            onClick={() => setFormType("login")}
          />
        </div>
      )}

      {/*** Forgot password form ***/}
      {formType === "forgotPassword" && (
        <div className="account_modal_form">
          {!didRequestPasswordReset ? (
            <>
              <div className="account_modal_info_text">
                Indiquez votre adresse e-mail afin de recevoir par e-mail la procédure de réinitialisation de votre mot
                de passe.
              </div>
              <InputField
                placeholder="Email"
                disabled={userCon.isRegistering}
                onChangeInput={(e) => setInputEmail(e.target.value)}
              />

              {/* auth error */}
              {userCon.authError && <div className="account_modal_auth_error">{userCon.authError}</div>}

              {/* request password reset button */}
              <Button
                className="request_password_reset_button"
                title="Valider"
                img={userUpdt.isRequestingPasswordReset ? <Loader color="#fff" /> : undefined}
                disabled={userUpdt.isRequestingPasswordReset}
                onClick={() => dispatch(requestPasswordReset(inputEmail, () => setDidRequestPasswordReset(true)))}
              />

              <div className="account_modal_separation"></div>

              {/* show login form */}
              <Button
                className="show_login_form_button"
                title="Retour au formulaire de connexion"
                type="link"
                disabled={userCon.isRegistering}
                onClick={() => setFormType("login")}
              />
            </>
          ) : (
            // show did request password text
            <div className="did_request_password_text">
              <div>
                La procédure de réinitialisation de votre mot de passe vous a été envoyée par e-mail à l{"'"}adresse :
              </div>
              <div>{inputEmail}</div>
            </div>
          )}
        </div>
      )}
    </ModalAccountView>
  );
};

export default AccountLogin;
