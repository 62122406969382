import { createSlice } from "@reduxjs/toolkit";
import { GameRule } from "../../game-box/core/types";
import { getGameRules } from "../../game-box";

const initialState: GameRule[] = getGameRules();

const gameRulesSlice = createSlice({
  name: "gameRules",
  initialState,
  reducers: {},
});

export default gameRulesSlice.reducer;
