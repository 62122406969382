import React, { PropsWithChildren, useState } from "react";

import { ImgRemove } from "../../../common/img/common";

import { isEventTargetInsideElem } from "../../../common/utils/uiUtils";

import "./modalView.css";

interface ModalViewProps extends PropsWithChildren {
  img?: JSX.Element;
  title?: string | JSX.Element;
  modalStyle?: Record<string, unknown>;
  modalContentStyle?: Record<string, unknown>;
  canCloseByClickingOnCross?: boolean;
  canCloseByClickingOutside?: boolean;
  onClose?: () => void;
}
const ModalView = ({
  img,
  title,
  modalStyle,
  modalContentStyle,
  canCloseByClickingOnCross = true,
  canCloseByClickingOutside = true,
  onClose,
  children,
}: ModalViewProps): JSX.Element | null => {
  const [visible, setVisible] = useState(true);

  const closeModal = () => {
    onClose && onClose();
    setVisible(false);
  };

  return visible === false ? null : (
    <div
      className={`modal_container${visible ? " showWithAnim" : ""}`}
      onClick={
        canCloseByClickingOutside === true
          ? (e) => {
              if (!isEventTargetInsideElem(e.target, null, "modal_title_and_content")) {
                closeModal();
              }
            }
          : undefined
      }
    >
      <div style={modalStyle} className="modal_title_and_content">
        {title && (
          <div className="modal_title_container">
            {img && <div className="modal_img">{img}</div>}
            <span className="modal_title">{title}</span>
            {canCloseByClickingOnCross === true && (
              <div className={"modal_close_button" + (title ? "" : " no-title")} onClick={() => closeModal()}>
                <ImgRemove />
              </div>
            )}
          </div>
        )}
        <div style={modalContentStyle} className={"modal_content" + (title ? "" : " no-title")}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default ModalView;
