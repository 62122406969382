import React, { useEffect } from "react";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { newGame } from "../../../redux/features/game/gameSlice";

import ScoreSheetTemplate from "../../scoreSheets/scoreSheetTemplate";
import ScoreSheet from "../../scoreSheets/scoreSheet";
import GameTable from "./gameTable";

import "./gameView.css";

const GameView = (): JSX.Element => {
  const { rule, scoreSheets } = useAppSelector((state) => state.game);

  const dispatch = useAppDispatch();

  useEffect(() => {
    // init a new game if there is no scoreSheets
    if (scoreSheets.length === 0) {
      dispatch(newGame());
    }
  }, []);

  return (
    <div className="mobile_gameView">
      {/* Sheets */}
      <div className="grids_container">
        {/* Score sheet template */}
        {rule && <ScoreSheetTemplate />}
        {/* Score sheets */}
        {scoreSheets && scoreSheets.map((scoreSheet, index) => <ScoreSheet key={index} scoreSheet={scoreSheet} />)}
        {/* 
          TODO:
          Mobile_ScoreSheetTemplate => qui return ScoreSheetTemplate 
          Mobile_ScoreSheet         => qui return ScoreSheet
            -> avec surcharge CSS mobile_ScoreSheets.css
          ??????
        */}
      </div>

      {/* Game Table */}
      <GameTable />
    </div>
  );
};

export default GameView;
