import React from "react";

import { useAppSelector } from "../../../redux/hooks";

import packageInfo from "../../../../package.json";

import "./footer.css";

const Footer = (): JSX.Element => {
  const zoom = useAppSelector((state) => state.ui.zoom.computedLevel);

  return (
    <div className="app_footer" style={{ zoom }}>
      <div></div>
      <div></div>
      <div className="footer_right">
        <span className="app_copyright">©2019 myams</span>
        <span>-</span>
        <span className="app_version" onClick={() => console.log("todo : afficher les infos de versions")}>
          v {packageInfo.version}
        </span>
      </div>
    </div>
  );
};

export default Footer;
