import { combineReducers } from "redux";

import game from "./game/gameSlice";
import gameRules from "./gameRulesSlice";
import gameModes from "./gameModesSlice";
import settings from "./settingsSlice";
import info from "./infoSlice";
import ui from "./uiSlice";
import user from "./userSlice";

export default combineReducers({
  game,
  gameModes,
  gameRules,
  settings,
  info,
  ui,
  user,
});
