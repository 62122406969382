import React, { useRef } from "react";
import { connect } from "react-redux";

import { removeUiError, removeUiErrors } from "../../../../../redux/__actions/ui/uiAction";
import { activateUserAccount } from "../../../../../redux/__actions/user/userAction";

import { ModalView, Loader, Separation } from "../../../../../components/ui";
import { InputField, Button } from "../../../../../components/forms";

import {
  USER,
  USER_ACTIVATION,
  USER_ACTIVATION_PSEUDO,
  USER_ACTIVATION_PASSWORD,
} from "../../../../../common/constants/uiErrorPaths";
import { resetUrl } from "../../../../../common/utils/appUtils";

import "../accountPopin.css";

const PromptActivation = ({ user, errors, activateUserAccount, removeUiError, removeUiErrors }) => {
  const pseudoRef = useRef();
  const passwordRef = useRef();
  return (
    <ModalView
      modalStyle={{
        minWidth: "unset",
        minHeight: "unset",
      }}
      canCloseByClickingOutside={false}
      onClose={() => {
        removeUiErrors(USER);
        resetUrl();
      }}
    >
      <div className="account_modal_prompt_container">
        {/* Title */}
        <div className="account_popin_form_title">
          <span>Plus qu{"'"}une étape pour valider votre compte</span>
          <span>Par mesure de sécurité vous devez vous authentifier</span>
        </div>

        <Separation />

        {/* Form */}
        <div className="account_popin_form">
          {/* Pseudo */}
          <InputField
            label="Pseudo"
            inputRef={pseudoRef}
            inputProps={{ autoComplete: "username" }}
            error={errors[USER_ACTIVATION_PSEUDO]}
            onChangeInput={() => {
              removeUiError(USER_ACTIVATION);
              removeUiError(USER_ACTIVATION_PSEUDO);
            }}
          />
          {/* Password */}
          <InputField
            label="Mot de passe"
            inputRef={passwordRef}
            inputType="password"
            inputProps={{ autoComplete: "current-password" }}
            error={errors[USER_ACTIVATION_PASSWORD]}
            onChangeInput={() => {
              removeUiError(USER_ACTIVATION);
              removeUiError(USER_ACTIVATION_PASSWORD);
            }}
          />

          {/* Error activation */}
          {errors[USER_ACTIVATION] && <span className="account_popin_form_error">{errors[USER_ACTIVATION]}</span>}
        </div>
        {/* Button activate account */}
        <Button
          className="account_popin_form_button"
          img={user.prompt.isActivating ? <Loader color="#fff" /> : undefined}
          disabled={user.prompt.isActivating === true}
          title="Activer mon compte"
          onClick={() => {
            removeUiError(USER_ACTIVATION);
            activateUserAccount(pseudoRef.current.value, passwordRef.current.value, user.prompt.activationId);
          }}
        />
      </div>
    </ModalView>
  );
};

const mapStateToProps = ({ ui, user }) => ({
  user,
  errors: ui.errors,
});
const mapDispatchToProps = (dispatch) => ({
  activateUserAccount: (pseudo, password, activationId) =>
    dispatch(activateUserAccount(pseudo, password, activationId)),
  removeUiError: (path) => dispatch(removeUiError(path)),
  removeUiErrors: (path) => dispatch(removeUiErrors(path)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptActivation);
