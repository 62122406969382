import React, { useEffect, useRef, useState } from "react";
import Compressor from "compressorjs";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import {
  clearAuthError,
  deleteAccount,
  logout,
  removeAvatar,
  updateAvatar,
} from "../../../../redux/features/userSlice";

import { formatDate } from "../../../../common/utils/dateUtils";
import { AVATAR_ALLOWED_FORMATS } from "../../../../common/constants/account";
import { NOTIF_TYPE } from "../../../../common/enums";
import { MESSAGES } from "../../../../common/messages/userAccount";

import { Avatar, Button } from "../../../../components/forms";
import { Loader, ModalAccountView, Notification } from "../../../../components/ui";

import "./myAccount.css";

interface MyAccountProps {
  visible: boolean;
  onClose: () => void;
  onLogout: () => void;
}
const MyAccount = ({ visible, onClose, onLogout }: MyAccountProps): JSX.Element => {
  const { pseudo, email, avatar, isActivated, createdAt } = useAppSelector((state) => state.user);
  const userCon = useAppSelector((state) => state.user.con);
  const userUpdt = useAppSelector((state) => state.user.updt);

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);

  const avatarInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setShowDeleteConfirmation(false);
    dispatch(clearAuthError());
  }, [visible]);

  useEffect(() => {
    if (!userCon.isLogged) {
      onLogout();
    }
  }, [userCon.isLogged]);

  useEffect(() => {
    setIsLoading(userUpdt.isUpdatingAvatar);
  }, [userUpdt.isUpdatingAvatar]);

  return (
    <ModalAccountView visible={visible} isUserLoggedIn={true} onClose={() => onClose()}>
      {!isActivated && (
        <Notification
          className="myAccount_not_activated_info"
          type={NOTIF_TYPE.WARN}
          text={MESSAGES.ACCOUNT_NOT_ACTIVATED}
        />
      )}

      <div className="myAccount_profile_content">
        {/* Avatar */}
        {userUpdt.isUpdatingAvatar === false && (
          <input
            ref={avatarInputRef}
            type="file"
            accept={AVATAR_ALLOWED_FORMATS.join(",")}
            hidden
            onChange={(e) => {
              const file = e.target.files ? e.target.files[0] : null;
              if (avatarInputRef.current) {
                avatarInputRef.current.value = "";
              }
              if (!file) {
                return;
              }
              setIsLoading(true);
              if (file.size > 500 * 1024) {
                //https://github.com/fengyuanchen/compressorjs
                new Compressor(file, {
                  quality: 0.6,
                  success(avatar) {
                    setIsLoading(false);
                    avatar && dispatch(updateAvatar(avatar as File));
                    if (avatarInputRef.current) {
                      avatarInputRef.current.value = "";
                    }
                  },
                  error(err) {
                    console.log("Upload avatar error: ", err.message);
                    setIsLoading(false);
                  },
                });
              } else {
                dispatch(updateAvatar(file));
              }
            }}
          />
        )}
        <Avatar
          className="myAccount_profile_avatar"
          src={avatar}
          pseudo={pseudo}
          editable={true}
          removable={avatar !== null && avatar.length > 0}
          isLoading={isLoading}
          width={76}
          height={76}
          onClickEdit={() => avatarInputRef.current?.click()}
          onClickRemove={() => dispatch(removeAvatar())}
        />
        {/* pseudo */}
        <div className="myAccount_profile_row">
          <div className="myAccount_profile_row_field">Pseudo</div>
          <div className="myAccount_profile_row_value">{pseudo}</div>
        </div>
        {/* email */}
        <div className="myAccount_profile_row">
          <div className="myAccount_profile_row_field">Email</div>
          <div className="myAccount_profile_row_value">{email}</div>
        </div>
        {/* createdAt */}
        <div className="myAccount_profile_row">
          <div className="myAccount_profile_row_field">Inscrit le</div>
          <div className="myAccount_profile_row_value">{formatDate(createdAt)}</div>
        </div>
        {/* TODO: update password */}
        {/* <div className="myAccount_profile_row">
          <div className="myAccount_profile_row_field">Mot de passe</div>
          <div className="myAccount_profile_row_value">
            <Button
              title="Modifier"
              onClick={() => {
                console.log("todo");
              }}
            />
          </div>
        </div> */}

        {/* auth error */}
        {userCon.authError && <div className="account_modal_auth_error">{userCon.authError}</div>}

        <div className="myAccount_profile_buttons">
          {/* logout */}
          <Button
            img={userCon.isLoggingOut ? <Loader color="#fff" size="16px" /> : undefined}
            title="Me déconnecter"
            onClick={() => dispatch(logout())}
          />
          {/* delete account */}
          {!showDeleteConfirmation ? (
            <Button
              className="myAccount_profile_button_delete"
              title="Supprimer mon compte"
              onClick={() => setShowDeleteConfirmation(true)}
            />
          ) : (
            // delete avatar
            <div className="myAccount_delete_confirmation">
              <div>Êtes-vous sûr de vouloir supprimer votre compte ?</div>
              <div className="myAccount_delete_confirmation_buttons">
                <Button
                  className="myAccount_delete_confirmation_button_cancel"
                  title="Annuler"
                  onClick={() => setShowDeleteConfirmation(false)}
                />
                <Button
                  className="myAccount_delete_confirmation_button_yes"
                  img={userUpdt.isRemovingAccount ? <Loader color="#fff" /> : undefined}
                  disabled={userUpdt.isRemovingAccount}
                  title="OUI"
                  onClick={() => dispatch(deleteAccount())}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </ModalAccountView>
  );
};
export default MyAccount;
