import { GAME_MODE } from "../core/enums";
import { GameMode } from "../core/types";

export const GAME_MODES: GameMode[] = [
  {
    key: GAME_MODE.FREE,
    name: "Libre",
    descriptionLines: ["Inscrivez les scores dans la grille", "lors d'une partie réelle entre amis"],
    numberOfCancelableScores: 3,
  },
  {
    key: GAME_MODE.SIMULATION,
    name: "Simulation",
    descriptionLines: [
      "Jouez avec les dés virtuels",
      "et tentez de battre des records",
      //'seul ou contre l\'ordinateur'
    ],
    numberOfCancelableScores: 1,
  },
  // {
  //   key: GAME_MODE.ONLINE,
  //   name: "En ligne",
  //   descriptionLines: ["Jouez avec les dés virtuels contre", "un ou plusieurs joueurs en ligne"],
  //   numberOfCancelableScores: 0,
  // },
];
