/**
 * Check if an event target is inside an element.
 * @param {Element} target - The event target element.
 * @param {string|null} elemId - The ID of the element to check against.
 * @param {string|null} elemClass - The class name of the element to check against.
 * @returns {boolean} - True if the event target is inside the element, false otherwise.
 */
export const isEventTargetInsideElem = (
  target: EventTarget | null,
  elemId: string | null,
  elemClass: string | null,
): boolean => {
  let elem: Element | null = target instanceof Element ? target : null;
  let foundElem = false;

  while (!foundElem && elem) {
    foundElem = elemId
      ? elem.id === elemId
      : elem.className !== undefined &&
        elem.className !== null &&
        typeof elem.className === "string" &&
        elem.className.split(" ").find((cn) => cn === elemClass) !== undefined;

    elem = elem.parentNode as Element | null;
  }

  return foundElem;
};
