import React from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
interface ImgProps extends Record<string, any> {
  className?: string;
}

export const ImgLogo = ({ stroke = "#000", fill = "#fff", ...props }: ImgProps): JSX.Element => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48pt"
    height="48pt"
    viewBox="0 0 48 48"
    version="1.1"
    stroke={stroke}
    fill={fill}
    {...props}
  >
    <g id="surface1">
      <path
        style={{
          fillRule: "nonzero",
          fill: fill,
          fillOpacity: 1,
          strokeWidth: 8,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          stroke: stroke,
          strokeOpacity: 1,
          strokeMiterlimit: 4,
        }}
        d="M 26.497072 16.502517 L 101.503238 16.503685 C 107.020886 16.502058 111.495857 20.979423 111.497483 26.497072 L 111.496315 101.503238 C 111.497942 107.020886 107.020577 111.495857 101.502928 111.497483 L 26.496762 111.496315 C 20.979114 111.497942 16.504143 107.020577 16.502517 101.502928 L 16.503685 26.496762 C 16.502058 20.979114 20.979423 16.504143 26.497072 16.502517 Z M 26.497072 16.502517 "
        transform="matrix(0.339865,-0.158482,0.158482,0.339865,-7.894225,12.391451)"
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: stroke,
          fillOpacity: 1,
        }}
        d="M 34.457031 13.296875 C 34.457031 15.578125 32.609375 17.421875 30.332031 17.421875 C 28.054688 17.421875 26.207031 15.578125 26.207031 13.296875 C 26.207031 11.019531 28.054688 9.171875 30.332031 9.171875 C 32.609375 9.171875 34.457031 11.019531 34.457031 13.296875 Z M 34.457031 13.296875 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: stroke,
          fillOpacity: 1,
        }}
        d="M 15.957031 18.148438 C 15.957031 20.425781 14.109375 22.273438 11.832031 22.273438 C 9.550781 22.273438 7.707031 20.425781 7.707031 18.148438 C 7.707031 15.871094 9.550781 14.023438 11.832031 14.023438 C 14.109375 14.023438 15.957031 15.871094 15.957031 18.148438 Z M 15.957031 18.148438 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: stroke,
          fillOpacity: 1,
        }}
        d="M 27.316406 23.355469 C 27.316406 25.632812 25.46875 27.480469 23.191406 27.480469 C 20.914062 27.480469 19.066406 25.632812 19.066406 23.355469 C 19.066406 21.078125 20.914062 19.230469 23.191406 19.230469 C 25.46875 19.230469 27.316406 21.078125 27.316406 23.355469 Z M 27.316406 23.355469 "
      />
      <path
        style={{
          stroke: "none",
          fillRule: "nonzero",
          fill: stroke,
          fillOpacity: 1,
        }}
        d="M 28.324219 35.324219 C 28.324219 37.601562 26.476562 39.449219 24.199219 39.449219 C 21.921875 39.449219 20.074219 37.601562 20.074219 35.324219 C 20.074219 33.046875 21.921875 31.199219 24.199219 31.199219 C 26.476562 31.199219 28.324219 33.046875 28.324219 35.324219 Z M 28.324219 35.324219 "
      />
    </g>
  </svg>
);
