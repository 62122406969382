import React from "react";
import { useFullScreen } from "react-browser-hooks";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { zoomIn, zoomOut } from "../../../../redux/features/uiSlice";

import { Separation, InfoBubble } from "../../../../components/ui";

// import { isOnFirefox } from "../../../common/utils/appUtils";
import { ImgFullScreen, ImgFullScreenExit, ImgZoomIn, ImgZoomOut } from "../../../../common/img/common";

import "./headerActions.css";

const HeaderActions = (): JSX.Element => {
  const { level, minLevel, maxLevel } = useAppSelector((state) => state.ui.zoom);

  const dispatch = useAppDispatch();

  const fs = useFullScreen();

  return (
    <div className="headerActions">
      {/* toogle full screnn */}
      <div className="action toogle_fullscreen" onClick={() => fs.toggle()}>
        <InfoBubble position="bottom right" text={fs.fullScreen ? "Quitter le plein écran" : "Plein écran"}>
          {fs.fullScreen ? <ImgFullScreenExit className="icon" /> : <ImgFullScreen className="icon" />}
        </InfoBubble>
      </div>

      {/* {isOnFirefox === false && ( */}
      <>
        <Separation orientation="vertical" size="16px" margin="10px" color="#777" />

        {/* zoom in/out */}
        <div className="action zooms">
          <InfoBubble position="bottom right" text={`Zoom : ${level} / ${maxLevel}`}>
            <div className={"zoom_out" + (level <= minLevel ? " disabled" : "")} onClick={() => dispatch(zoomOut())}>
              <ImgZoomOut className="icon" />
            </div>
            <div className={"zoom_in" + (level >= maxLevel ? " disabled" : "")} onClick={() => dispatch(zoomIn())}>
              <ImgZoomIn className="icon" />
            </div>
          </InfoBubble>
        </div>
      </>
      {/* )} */}
    </div>
  );
};

export default HeaderActions;
