import * as React from "react";
import { useState, useEffect } from "react";
import { Dice } from "../../../game-box/core/types";

import DiceItem from "./dice/Dice";

import "./diceBoard.css";

interface DiceBoardProps {
  dices: Dice[];
  isFrozen?: boolean;
  isThrowingDices: boolean;
  clickOnDiceToPreserve: boolean;
  showIndicator: boolean;
  className?: string;
  onPreserveDice: (dice: Dice) => void;
}
const DiceBoard = ({
  dices,
  isFrozen,
  isThrowingDices,
  clickOnDiceToPreserve,
  showIndicator,
  className,
  onPreserveDice,
}: DiceBoardProps): JSX.Element => {
  const [isYamsAnim, setYamsAnim] = useState(dices.every((d) => d.value && d.value === dices[0]?.value));

  useEffect(() => {
    setYamsAnim(dices.every((d) => d.value && d.value === dices[0]?.value));
  }, [dices]);

  return (
    <div
      className={
        "diceBoard" +
        `${dices.every((d) => !d.value) ? " noDice" : ""}` +
        `${isFrozen ? " isFrozen" : ""}` +
        `${className ? " " + className : ""}`
      }
    >
      {dices?.map((dice: Dice, i: number) => (
        <DiceItem
          key={i}
          dice={dice}
          isFrozen={isFrozen}
          isThrowingDices={isThrowingDices}
          yamsAnim={isYamsAnim}
          clickOnDiceToPreserve={clickOnDiceToPreserve}
          showIndicator={showIndicator}
          onClick={() => onPreserveDice(dice)}
        />
      ))}
    </div>
  );
};

export default DiceBoard;
