import React, { useRef, useEffect } from "react";

import { NOTIF_TYPE } from "../../../common/enums";

import { ImgInfo, ImgWarning, ImgError, ImgSuccess } from "./images";
import "./notification.css";

const animationDuration = 300;

const showAnimated = (ref: React.RefObject<HTMLDivElement>) => {
  ref && ref.current && ref.current.classList.add("transition-show");
};
const hideAnimated = (ref: React.RefObject<HTMLDivElement>) => {
  ref && ref.current && ref.current.classList.remove("transition-show");
  ref && ref.current && ref.current.classList.add("transition-hide");
};

interface NotificationProps {
  className?: string;
  type?: NOTIF_TYPE;
  text: string | string[];
  isGlobal?: boolean;
  timeout?: number;
  onHide?: () => void;
}
const Notification = ({
  className,
  type = NOTIF_TYPE.INFO,
  text,
  isGlobal = false,
  timeout,
  onHide,
}: NotificationProps): JSX.Element => {
  const notifRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    // If notification is global
    if (isGlobal === true && timeout && onHide) {
      // show animated
      setTimeout(() => {
        showAnimated(notifRef);
      }, 10);
      // wait for timeout
      setTimeout(() => {
        // wait for hide animation over then call onHide()
        hideAnimated(notifRef);
        setTimeout(() => onHide(), animationDuration);
      }, timeout);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      ref={notifRef}
      className={
        "notification_container" +
        (isGlobal === true ? " isGlobal" : "") +
        ` ${type}` +
        (className ? ` ${className}` : "")
      }
    >
      <div className="notification_img">
        {type === NOTIF_TYPE.INFO && <ImgInfo />}
        {type === NOTIF_TYPE.WARN && <ImgWarning />}
        {type === NOTIF_TYPE.ERROR && <ImgError />}
        {type === NOTIF_TYPE.SUCCESS && <ImgSuccess />}
      </div>
      <div className="notification_text_lines">
        {Array.isArray(text) ? text.map((t, i) => <span key={i}>{t}</span>) : <span>{text}</span>}
      </div>
    </div>
  );
};

export default Notification;
