/**
 * Formats the timer values into a string representation.
 *
 * @param {number} hour - The hour value of the timer.
 * @param {number} min - The minute value of the timer.
 * @param {number} sec - The second value of the timer.
 * @param {boolean|undefined} showHours - Optional. Specifies whether to include hours in the formatted value. Defaults to true.
 * @return {string} The formatted timer value as a string.
 */
export const formatTimer = (hour: number, min: number, sec: number, showHours: boolean | undefined = true): string => {
  if (!showHours && hour > 0) return "> 1h";

  let formattedValue = "";

  formattedValue += hour < 10 ? "0" + hour : hour;
  formattedValue += ":";
  formattedValue += min < 10 ? "0" + min : min;
  formattedValue += ":";
  formattedValue += sec < 10 ? "0" + sec : sec;

  return showHours ? formattedValue : formattedValue.slice(3);
};

/**
 * Returns a formatted timer string based on the given timer value.
 *
 * @param {number} timer - The timer value in seconds.
 * @param {boolean|undefined} showHours - A optionnal flag indicating whether to show hours in the formatted timer string.
 * @return {string} - The formatted timer string.
 */
export const getFormattedTimer = (timer: number, showHours: boolean | undefined = true): string => {
  if (timer <= 0) {
    return formatTimer(0, 0, 0, showHours);
  } else if (timer < 60) {
    return formatTimer(0, 0, timer, showHours);
  } else if (timer < 60 * 60) {
    const sec = timer % 60;
    const min = (timer - sec) / 60;
    return formatTimer(0, min, sec, showHours);
  } else {
    const sec = timer % 60;
    const totalMin = (timer - sec) / 60;
    const min = totalMin % 60;
    const hour = (totalMin - min) / 60;
    return formatTimer(hour, min, sec, showHours);
  }
};

/**
 * Returns an array of formatted time values in minutes and seconds based on the given timer value.
 *
 * @param {number} timer - The timer value in seconds.
 * @return {string[]} - An array containing the formatted time values.
 */
export const getFormattedTimer_minSec = (timer: number): string[] => {
  if (timer >= 3600) {
    return ["> 1 heure"];
  } else if (timer >= 60) {
    const min = (timer - (timer % 60)) / 60;
    const sec = timer % 60;
    const formattedMin = `${min} min `;
    const formattedSec = `${sec >= 10 ? "" : "0"}${sec} sec`;
    return [formattedMin, formattedSec];
  }
  return [`${timer} sec`];
};

/**
 * Formats a given date into a string representing the date in the format "DD/MM/YYYY".
 *
 * @param {Date | null} dateToFormat - The date to be formatted. If null or undefined, an empty string will be returned.
 * @return {string} - The formatted date string in the format "DD/MM/YYYY".
 */
export const formatDate = (dateToFormat?: Date | null): string => {
  if (!dateToFormat) {
    return "";
  }
  const date = new Date(dateToFormat);
  const day = date.getDate().toString().padStart(2, "0"); // Jour (avec 0 en tête si nécessaire)
  const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Mois (de 0 à 11, donc +1)
  const year = date.getFullYear(); // Année

  return `${day}/${month}/${year}`;
};
