import React from "react";
import { ROW_SECTION, ROW_TYPE } from "../../game-box/core/enums";
import { GameRule } from "../../game-box/core/types";

import "./gameRuleDetails.css";

interface GameRuleDetailsProps {
  rule: GameRule;
}
const GameRuleDetails = ({ rule }: GameRuleDetailsProps): JSX.Element => {
  const columns = rule.template.cols;
  const topScores = rule.template.rows
    .filter((row) => row.type === ROW_TYPE.SCORE)
    .filter((row) => row.section === ROW_SECTION.TOP);
  const bottomScores = rule.template.rows
    .filter((row) => row.type === ROW_TYPE.SCORE)
    .filter((row) => row.section === ROW_SECTION.BOTTOM);
  const bonuses = rule.template.rows.filter((row) => row.type === ROW_TYPE.BONUS);

  return (
    <div className="gameRuleDetails_container">
      {/* Description */}
      <div className="gameRuleDetails_description" dangerouslySetInnerHTML={{ __html: rule.descriptionHTML }}></div>

      {/* Columns */}
      <div className="gameRuleDetails_columns">
        <div className="gameRuleDetails_table_title">LES COLONNES</div>
        <div className="gameRuleDetails_table_content">
          {columns.map((col, index) => (
            <div key={index} className="table_row">
              <div className="row_name">{col.longName}</div>
              <div className="row_description fullsize">{col.description}</div>
            </div>
          ))}
        </div>
      </div>

      {/* Combination */}
      <div className="gameRuleDetails_combinations">
        <div className="gameRuleDetails_table_title">LES COMBINAISONS</div>
        {/* Top section */}
        {topScores.length > 0 && (
          <>
            <div className="gameRuleDetails_table_subtitle">Partie haute :</div>
            <div className="gameRuleDetails_table_content">
              {topScores.map((row, index) => (
                <div key={index} className="table_row">
                  <div className="row_name">{row.longName}</div>
                  <div className="row_description">{row.description}</div>
                  <div className="row_calculation">{row.calculation}</div>
                </div>
              ))}
            </div>
          </>
        )}
        {/* Bottom section */}
        {bottomScores.length > 0 && (
          <>
            <div className="gameRuleDetails_table_subtitle">Partie basse :</div>
            <div className="gameRuleDetails_table_content">
              {bottomScores.map((row, index) => (
                <div key={index} className="table_row">
                  <div className="row_name">{row.longName}</div>
                  <div className="row_description">{row.description}</div>
                  <div className="row_calculation">{row.calculation}</div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {/* Bonuses */}
      {bonuses.length > 0 && (
        <>
          <div className="gameRuleDetails_bonuses">
            <div className="gameRuleDetails_table_title">LES BONUS</div>
            <div className="gameRuleDetails_table_content">
              {bonuses.map((row, index) => (
                <div key={index} className="table_row">
                  <div className="row_name">{row.longName}</div>
                  <div className="row_description fullsize">
                    {Array.isArray(row.description) ? row.description.join(" ") : row.description}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GameRuleDetails;
