import React, { useRef, useState, useEffect } from "react";

import { ImgRemove, ImgEdit, ImgValidate } from "../../../../common/img/common";

import "./nameRow.css";

interface NameRowProps {
  name: string;
  canEditName: boolean;
  canBeRemoved: boolean;
  colSpan: number;
  onRemove: () => void;
  onUpdateName: (name: string) => void;
}

const NameRow = ({ name, canEditName, canBeRemoved, colSpan, onRemove, onUpdateName }: NameRowProps): JSX.Element => {
  const [inputNameValue, setInputNameValue] = useState("");
  const [isEditingName, setEditingName] = useState(false);

  const inputNameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setInputNameValue(name);
  }, [name]);

  const _focusInputName = (selectAll = false) => {
    const input = inputNameRef.current;
    input?.focus();
    selectAll && input?.setSelectionRange(0, input.value.length);
  };

  const _validateInputName = () => {
    const inputValue = inputNameRef.current?.value;
    if (name !== inputValue) {
      onUpdateName(inputValue ?? "");
    }
  };

  return (
    <tr>
      <th colSpan={colSpan} className="name_row_cell">
        <div className={"cell name_row_cell" + (canEditName ? "" : " locked")}>
          {/* remove */}
          <div
            className={"icon_remove " + (canEditName && !isEditingName && canBeRemoved ? "" : "hidden")}
            onClick={() => onRemove()}
          >
            <ImgRemove />
          </div>
          {/* input */}
          {canEditName ? (
            <input
              ref={inputNameRef}
              className={`input_name colspan_${colSpan}`}
              type="text"
              value={inputNameValue}
              onChange={(e) => {
                setInputNameValue(e.target.value);
              }}
              onFocus={() => {
                if (canEditName === true) {
                  setEditingName(true);
                  _focusInputName(true);
                }
              }}
              onBlur={() => {
                setInputNameValue(name);
                setEditingName(false);
              }}
              onKeyDown={(e) => {
                // press Enter
                if (e.key === "Enter") {
                  _validateInputName();
                  inputNameRef.current?.blur();
                }
                // press Escape
                if (e.key === "Escape" || e.key === "Esc") {
                  inputNameRef.current?.blur();
                }
              }}
            />
          ) : (
            <div className="input_name noInput">{name}</div>
          )}
          {/* edit */}
          <div
            className={`icon_edit_name${canEditName ? " animate" : ""}${
              canEditName && !isEditingName ? "" : " hidden"
            }`}
            onClick={() => _focusInputName()}
          >
            <ImgEdit />
          </div>
          {/* validate */}
          <div
            className={"icon_validate_edit_name " + (isEditingName ? "" : "hidden")}
            onMouseDown={() => _validateInputName()}
          >
            <ImgValidate />
          </div>
        </div>
      </th>
    </tr>
  );
};

export default NameRow;
